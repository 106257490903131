import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Rating } from "@mui/material";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import apiService from "../../api/apiService";
import RadioGroup from "@mui/material/RadioGroup";
import FormHelperText from "@mui/material/FormHelperText";
import notifyService from "../../api/notifySerivce";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import Scsthubavpremarks from "../scsthubavp-module/scsthubavp-details/Scsthubavpremarks";
import StarIcon from "@mui/icons-material/Star";

const ScstScoreValidation = ({
  evaluationshow,
  evaluationGet,
  idNumber,
  path,
  detail,
  partnerOrInvestorId,
  viewStartupDetails,
  showStartupValue,
  startupCheckList,
}) => {
  const [formData, setFormData] = useState([]);
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    getValues,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({ values: formData });
  const { fields: questionsFields } = useFieldArray({
    control,
    name: "startupQuestions",
  });
  let index;
  const roles = localStorage.getItem("role");
  useDidMountEffect(() => {
    getApi(path);
    showStartupValue();
    if (roles === "JURY") {
      evaluationGet();
    }
  }, [detail]);
  const getApi = (paths) => {
    if (paths === "validation") {
      index = 0;
    } else if (paths === "jury/scst") {
      index = 1;
    }
    if (path === "jury/scst") {
      var req = {
        partnerOrInvestorId: partnerOrInvestorId,
        startupId: idNumber,
      };

      apiService(`jury/startup/rounds/get`, req, "post")
        .then((result) => {
          getResp(result, index);
        })
        .catch((err) => { });
    } else {
      apiService(`partner/startup/rounds/get?startupid=${idNumber}`, "", "get")
        .then((result) => {
          getResp(result, index);
        })
        .catch((err) => { });
    }
  };
  const getResp = (result, index) => {
    if (result) {
      if (result.data) {
        result.data.startupRounds[index].startupQuestions.forEach((element) => {
          if (element.score === 0) {
            element.score = "";
          } else {
            element.hover = -1;
          }
        });
        for (
          let i = 0;
          i < result.data.startupRounds[index].startupQuestions.length;
          i++
        ) {
          var element = result.data.startupRounds[index].startupQuestions[i];
          if (element.answer === "Yes") {
            setApproveStatus(false);
            break;
          }
        }
        for (
          let i = 0;
          i < result.data.startupRounds[index].startupQuestions.length;
          i++
        ) {
          var element = result.data.startupRounds[index].startupQuestions[i];
          //   element.scoreDescription = [
          //     {
          //       rating: 1,
          //       Description: "Test 1",
          //     },
          //     {
          //       rating: 2,
          //       Description: "Test 2",
          //     },
          //     {
          //       rating: 3,
          //       Description: "Test 3",
          //     },
          //     {
          //       rating: 4,
          //       Description: "Test 4",
          //     },
          //     {
          //       rating: 5,
          //       Description: "Test 5",
          //     },
          //   ];
        }
        setFormData(result.data.startupRounds[index]);
        if (result.data.startupRounds[index].remarks) {
          setComment(result.data.startupRounds[index].remarks);
        }
      }
    }
  };
  const [approveStatus, setApproveStatus] = useState(false);

  const checkApproveStatus = () => {
    const answers = [
      getValues(`startupQuestions.1.answer`),
      getValues(`startupQuestions.2.answer`),
      getValues(`startupQuestions.3.answer`),
      getValues(`startupQuestions.4.answer`),
    ];
    const showApproveButton = !answers.some((answer) => answer !== "Yes");
    setApproveStatus(showApproveButton);
  };

  const handleCheck = (index, value) => {
    setValue(`startupQuestions.${index}.answer`, value);
    clearErrors(`startupQuestions.${index}.answer`);

    checkApproveStatus();
  };

  useEffect(() => {
    checkApproveStatus();
  }, [getValues()]);
  const [comment, setComment] = useState("");
  const handleComment = (value) => {
    setValue("remarks", value);
    clearErrors("remarks");
    setComment(value);
  };
  const handleRatingScore = (index, value) => {
    setValue(`startupQuestions.${index}.score`, value);
    clearErrors(`startupQuestions.${index}.score`);
  };
  const handleHoverRatingScore = (index, value) => {
    setValue(`startupQuestions.${index}.hover`, value);
    clearErrors(`startupQuestions.${index}.hover`);
  };
  const [loading, setLoading] = useState(false);
  // let datas
  const onSubmit = (data) => {
    var val = {
      startupRounds: [data],
    };
    // datas = val
    debugger
    let url;
    if (path === "jury/scst") {
      url = "jury/startup/round/answers/save";
    } else {
      url = "partner/startup/round/answers/save";
      debugger
    }
    let remarkStatus;
    if (data.status === 2) {
      remarkStatus = true;
    } else {
      remarkStatus = false;
      clearErrors("remarks");
    }

    if (remarkStatus === true && data.remarks !== "") {
      debugger
      // if (roles !== 'JURY') {
      //   handleOpenModal()
      // } else {
      //   saveApi();
      // }
      handleOpenModal()

    } else if (remarkStatus === false) {
      debugger
      handleOpenModal()
      // if (roles !== 'JURY') {
      //   handleOpenModal()
      // } else {
      //   saveApi();
      // }
      // saveApi(url, val);
    } else if (remarkStatus === true) {
      setError("remarks", { message: "Remark is required" });
    }
  };
  const saveApi = () => {
    debugger
    
    var datas = getValues()
    var val = {
      startupRounds: [datas],
    };
    let url
    if (path === "jury/scst") {
      url = "jury/startup/round/answers/save";
    } else {
      url = "partner/startup/round/answers/save";
      debugger
    }
    debugger
    setLoading(true);
    apiService(url, val, "post")
      .then((res) => {
        setLoading(false);
        if (res) {
          if (res.data.responseStatus === "Success") {
            debugger
            notifyService("success", "Success", "Saved successfully !!");
            viewStartupDetails();
           
            debugger
            showStartupValue();
            getApi(path);
            toggleClose();
            localStorage.setItem('startupRoundStatus', 1);
            handleCloseModal()
          }
        }
      })
      .catch((err) => { });
  };
  const toggleClose = () => {
    reset();
    setComment("");
  };
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    debugger
    setOpenModal(false);
  };
  return (
    <Box sx={{ p: 3 }} role="presentation" className="overallTab">
      <div>
        {(
          roles === "JURY"
            ? evaluationshow.enableEvaluation === true
            : startupCheckList.enableValidation === true
        ) ? (
          <div className="full-div">
            {formData.status !== 0 && (
              <div className="d-flex align-items-center">
                {path === "jury/scst" ? (
                  <>
                    {formData.status === 1 && (
                      <h6 className="my-4">
                        Status : <b className="c-green">Recommend to BDD</b>
                      </h6>
                    )}
                    {formData.status === 2 && (
                      <h6 className="my-4">
                        Status :{" "}
                        <b className="c-green">
                          Recommend to Mentorship / Other Schemes
                        </b>
                      </h6>
                    )}
                  </>
                ) : (
                  <>
                    {formData.status === 1 && (
                      <h6 className="my-4">
                        Status : <b className="c-green">Approved</b>
                      </h6>
                    )}
                    {formData.status === 2 && (
                      <h6 className="my-4">
                        Status : <b className="c-red">Rejected</b>
                      </h6>
                    )}
                  </>
                )}
                {formData.remarks && (
                  <h6 className="ml-auto">
                    Remarks :{" "}
                    <Scsthubavpremarks
                      remarks={formData.remarks}
                      lblName="View"
                    />
                  </h6>
                )}
              </div>
            )}
            <form
              className="signin-form box-bg"
              onSubmit={handleSubmit(onSubmit)}
            >
              {questionsFields.length > 0 ? (
                <>
                  <div className="qus_div">
                    {questionsFields.map((value, index) => {
                      // const maxMarks = getMaxMarks(value.question);
                      const { questionType, question, scoreDescription } =
                        value;
                      return (
                        <>
                          <div key={value.id} className="form-group mb-5">
                            <div className="row">
                              <div
                                className={
                                  questionType === "yesno"
                                    ? "col-lg-9 col-md-9 col-12"
                                    : "col-lg-12 col-md-12 col-12"
                                }
                              >
                                <p className="prd-5">
                                  <b className="remark-font">
                                    {index + 1}. {question}
                                  </b>
                                </p>
                              </div>
                              <div
                                className={
                                  questionType === "yesno"
                                    ? "col-lg-3 col-md-3 col-12"
                                    : "col-lg-12 col-md-12 col-12"
                                }
                              >
                                {questionType === "yesno" ? (
                                  <div>
                                    <div className="d-flex align-items-center justify-content-center w-100">
                                      <Controller
                                        name={`startupQuestions.${index}.answer`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: "Answer is required",
                                        }}
                                        render={({ field }) => (
                                          <RadioGroup
                                            row
                                            aria-labelledby="demo-form-control-label-placement"
                                            name="position"
                                            {...field}
                                          >
                                            <div className="d-flex align-items-center justify-content-center w-100 main_toggle">
                                              <div
                                                className={
                                                  getValues(
                                                    `startupQuestions.${index}.answer`
                                                  ) === "Yes"
                                                    ? "customs_toggle toggleActive"
                                                    : "customs_toggle"
                                                }
                                                onClick={() => {
                                                  formData.status === 0 &&
                                                    handleCheck(index, "Yes");
                                                }}
                                              >
                                                Yes
                                              </div>
                                              <div
                                                className={
                                                  getValues(
                                                    `startupQuestions.${index}.answer`
                                                  ) === "No"
                                                    ? "customs_toggle toggleActive"
                                                    : "customs_toggle"
                                                }
                                                onClick={() => {
                                                  formData.status === 0 &&
                                                    handleCheck(index, "No");
                                                }}
                                              >
                                                No
                                              </div>
                                            </div>
                                          </RadioGroup>
                                        )}
                                      />
                                    </div>
                                    <FormHelperText className="text-danger text-center">
                                      {errors.startupQuestions?.[index]
                                        ?.answer &&
                                        errors.startupQuestions?.[index]?.answer
                                          .message}
                                    </FormHelperText>
                                  </div>
                                ) : (
                                  <div className="row">
                                    <div className="col-lg-7 col-md-7 col-12">
                                      <Controller
                                        name={`startupQuestions.${index}.score`}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                          required: "Score is required",
                                        }}
                                        render={({ field }) => (
                                          <Box>
                                            {/* <Rating
                                              {...field}
                                              disabled={
                                                formData.status === 0
                                                  ? false
                                                  : true
                                              }
                                              name="hover-feedback"
                                              value={field.value}
                                              precision={1}
                                              onChange={(event, newValue) => {
                                                handleRatingScore(
                                                  index,
                                                  newValue
                                                );
                                              }}
                                              onChangeActive={(
                                                event,
                                                newHover
                                              ) => {
                                                handleHoverRatingScore(
                                                  index,
                                                  newHover
                                                );
                                              }}
                                              emptyIcon={
                                                <StarIcon
                                                  style={{ opacity: 0.55 }}
                                                  fontSize="inherit"
                                                />
                                              }
                                            /> */}
                                            {/* <div
                                              {...field}
                                              className="round_point"
                                            >
                                              <span
                                                className={
                                                  field.value === 1
                                                    ? "span_round spanActive"
                                                    : "span_round"
                                                }
                                                onClick={() => {
                                                  formData.status === 0 &&
                                                    handleRatingScore(index, 1);
                                                }}
                                              >
                                                1
                                              </span>
                                              <span
                                                className={
                                                  field.value === 2
                                                    ? "span_round spanActive"
                                                    : "span_round"
                                                }
                                                onClick={() => {
                                                  formData.status === 0 &&
                                                    handleRatingScore(index, 2);
                                                }}
                                              >
                                                2
                                              </span>
                                              <span
                                                className={
                                                  field.value === 3
                                                    ? "span_round spanActive"
                                                    : "span_round"
                                                }
                                                onClick={() => {
                                                  formData.status === 0 &&
                                                    handleRatingScore(index, 3);
                                                }}
                                              >
                                                3
                                              </span>
                                              <span
                                                className={
                                                  field.value === 4
                                                    ? "span_round spanActive"
                                                    : "span_round"
                                                }
                                                onClick={() => {
                                                  formData.status === 0 &&
                                                    handleRatingScore(index, 4);
                                                }}
                                              >
                                                4
                                              </span>
                                              <span
                                                className={
                                                  field.value === 5
                                                    ? "span_round spanActive"
                                                    : "span_round"
                                                }
                                                onClick={() => {
                                                  formData.status === 0 &&
                                                    handleRatingScore(index, 5);
                                                }}
                                              >
                                                5
                                              </span>
                                            </div> */}
                                            <Box>
                                              {/* {scoreDescription && (
                                                <>
                                                  {getValues(
                                                    `startupQuestions.${index}.score`
                                                  ) &&
                                                    getValues(
                                                      `startupQuestions.${index}.hover`
                                                    ) === -1 &&
                                                    scoreDescription[
                                                      getValues(
                                                        `startupQuestions.${index}.score`
                                                      ) - 1
                                                    ].description}
                                                </>
                                              )} */}
                                              {/* <ol className="ol_div">
                                                {scoreDescription.map(
                                                  (list, index) => (
                                                    <li key={index}>
                                                      {list.description}
                                                    </li>
                                                  )
                                                )}
                                              </ol> */}
                                              {/* <div
                                                className={
                                                  field.value === 5
                                                    ? "span_border span_borderActive"
                                                    : "span_border"
                                                }
                                                onClick={() => {
                                                  formData.status === 0 &&
                                                    handleRatingScore(index, 5);
                                                }}
                                              >   
                                                <div className="eva-des">
                                                    
                                                      {scoreDescription.map(
                                                  (list, index) => (
                                                    <>
                                                    
                                                    <div className="eva-circle eva-color font-weight-bold">{index + 1}</div>
                                                    <p className="ml-4" key={index}>
                                                      {list.description}
                                                    </p>
                                                    </>
                                                  )
                                                )}
                                                </div>
                                              </div> */}
                                              {/* index 5 */}
                                              <div
                                                className={
                                                  field.value === 5 ? "span_border span-bg-5 span_borderActive" : "span_border span-bg-5"
                                                }
                                                onClick={() => {
                                                  formData.status === 0 && handleRatingScore(index, 5);
                                                }}
                                              >
                                                <div className="eva-des">
                                                  {scoreDescription && scoreDescription.filter((_, idx) => idx === 4)
                                                    .map((list, index) => (
                                                      <div key={index} className="description-item" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className={
                                                          field.value === 5
                                                            ? "eva eva-circle eva-colors5"
                                                            : "eva eva-circle eva-color"
                                                        } style={{ marginRight: '10px' }}>5</div>
                                                        <p className="ml-4" >{list.description}</p>
                                                      </div>
                                                    ))}
                                                </div>
                                              </div>
                                              {/* index 4 */}
                                              <div
                                                className={
                                                  field.value === 4 ? "span_border span-bg-4 span_borderActive-blue" : "span_border span-bg-4"
                                                }
                                                onClick={() => {
                                                  formData.status === 0 && handleRatingScore(index, 4);
                                                }}
                                              >
                                                <div className="eva-des">
                                                  {scoreDescription
                                                    .filter((_, idx) => idx === 3)
                                                    .map((list, index) => (
                                                      <div key={index} className="description-item" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className={
                                                          field.value === 4
                                                            ? "eva eva-circle-blue eva-colors4"
                                                            : "eva eva-circle-blue eva-color-blue"
                                                        } style={{ marginRight: '10px' }}>4</div>
                                                        <p className="ml-4">{list.description}</p>
                                                      </div>
                                                    ))}
                                                </div>
                                              </div>
                                              {/* index 3 */}
                                              <div
                                                className={
                                                  field.value === 3 ? "span_border span-bg-3 span_borderActive-orange" : "span_border span-bg-3"
                                                }
                                                onClick={() => {
                                                  formData.status === 0 && handleRatingScore(index, 3);
                                                }}
                                              >
                                                <div className="eva-des">
                                                  {scoreDescription
                                                    .filter((_, idx) => idx === 2)
                                                    .map((list, index) => (
                                                      <div key={index} className="description-item" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className={
                                                          field.value === 3
                                                            ? "eva eva-circle-orange eva-colors3"
                                                            : "eva eva-circle-orange eva-color-orange"
                                                        } style={{ marginRight: '10px' }}>3</div>
                                                        <p className="ml-4">{list.description}</p>
                                                      </div>
                                                    ))}
                                                </div>
                                              </div>
                                              {/* index 2 */}
                                              <div
                                                className={
                                                  field.value === 2 ? "span_border span-bg-2 span_borderActive-pink" : "span_border span-bg-2"
                                                }
                                                onClick={() => {
                                                  formData.status === 0 && handleRatingScore(index, 2);
                                                }}
                                              >
                                                <div className="eva-des">
                                                  {scoreDescription
                                                    .filter((_, idx) => idx === 1)
                                                    .map((list, index) => (
                                                      <div key={index} className="description-item" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className={
                                                          field.value === 2
                                                            ? "eva eva-circle-pink eva-colors2"
                                                            : "eva eva-circle-pink eva-color-pink"
                                                        } style={{ marginRight: '10px' }}>2</div>
                                                        <p className="ml-4">{list.description}</p>
                                                      </div>
                                                    ))}
                                                </div>
                                              </div>
                                              {/* index 1 */}
                                              <div
                                                className={
                                                  field.value === 1 ? "span_border span-bg-1 span_borderActive-red" : "span_border span-bg-1"
                                                }
                                                onClick={() => {
                                                  formData.status === 0 && handleRatingScore(index, 1);
                                                }}
                                              >
                                                <div className="eva-des">
                                                  {scoreDescription
                                                    .filter((_, idx) => idx === 0)
                                                    .map((list, index) => (
                                                      <div key={index} className="description-item" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className={
                                                          field.value === 1
                                                            ? "eva eva-circle-red eva-colors1"
                                                            : "eva eva-circle-red eva-color-red"
                                                        } style={{ marginRight: '10px' }}>1</div>
                                                        <p className="ml-4">{list.description}</p>
                                                      </div>
                                                    ))}
                                                </div>
                                              </div>



                                            </Box>
                                            <Box>
                                              {scoreDescription && (
                                                <>
                                                  {getValues(
                                                    `startupQuestions.${index}.hover`
                                                  ) &&
                                                    getValues(
                                                      `startupQuestions.${index}.hover`
                                                    ) !== -1 &&
                                                    scoreDescription[
                                                      getValues(
                                                        `startupQuestions.${index}.hover`
                                                      ) - 1
                                                    ].description}
                                                </>
                                              )}
                                            </Box>
                                          </Box>
                                        )}
                                      />
                                      <FormHelperText className="text-danger">
                                        {errors.startupQuestions?.[index]
                                          ?.score &&
                                          errors.startupQuestions?.[index]
                                            ?.score.message}
                                      </FormHelperText>
                                    </div>
                                    {path === "jury/scst" && (
                                      <div className="col-lg-5 col-md-5 col-12">
                                        <Controller
                                          name={`startupQuestions.${index}.remarks`}
                                          control={control}
                                          defaultValue=""
                                          rules={{
                                            required: "Remarks is required",
                                          }}
                                          render={({ field }) => (
                                            <TextField
                                              id="filled-textarea"
                                              label="Remarks *"
                                              placeholder="Add a Remarks"
                                              multiline
                                              variant="filled"
                                              {...field}
                                              fullWidth
                                              rows={7}
                                              inputProps={{
                                                readOnly:
                                                  formData.status !== 0 && true,
                                              }}
                                              sx={{ borderRadius: '10px' }}
                                            // value={comment}
                                            // onInput={(e) => handleComment(e.target.value)}
                                            // inputProps={{ readOnly: status !== 0 && true }}
                                            />
                                            // <Button variant="outlined" onClick={() => handleOpenRemark(index)}>Remarks</Button>
                                          )}
                                        />
                                        <FormHelperText className="text-danger">
                                          {errors.startupQuestions?.[index]
                                            ?.remarks &&
                                            errors.startupQuestions?.[index]
                                              ?.remarks.message}
                                        </FormHelperText>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  {path !== "jury/scst" && (
                    <>
                      {formData.status === 0 && (
                        <div>
                          <Controller
                            name={`remarks`}
                            control={control}
                            defaultValue=""
                            rules={{ required: false }}
                            render={({ field }) => (
                              <TextField
                                id="filled-textarea"
                                label="Comment"
                                placeholder="Add a comment"
                                multiline
                                variant="filled"
                                {...field}
                                fullWidth
                                rows={4}
                                value={comment}
                                onInput={(e) => handleComment(e.target.value)}
                                inputProps={{
                                  readOnly: formData.status !== 0 && true,
                                }}
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.remarks && "Remark is required"}
                          </FormHelperText>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="text-center py-4">
                    <h5>Rounds Completed</h5>
                  </div>
                </>
              )}
              {formData.status === 0 ? (
                <>
                  {path === "jury/scst" && startupCheckList.status <= 2 ? (
                    <div className="form-group text-center my-3">
                      <div className="score-actions">
                        <LoadingButton
                          type="submit"
                          loading={loading}
                          loadingPosition="start"
                          startIcon={<SaveIcon />}
                          variant="contained"
                          onClick={() => setValue("status", 1)}
                          sx={{
                            padding: "10px 20px !important",
                            margin: "3px",
                            borderRadius: "25px",
                            "&:hover": {
                              backgroundColor: "green",
                            },
                          }}
                        >
                          <span>Recommend to BDD</span>
                        </LoadingButton>
                        <LoadingButton
                          type="submit"
                          loading={loading}
                          loadingPosition="start"
                          startIcon={<SaveIcon />}
                          variant="contained"
                          onClick={() => setValue("status", 2)}
                          sx={{
                            padding: "10px 20px !important",
                            margin: "3px",
                            borderRadius: "25px",
                            "&:hover": {
                              backgroundColor: "green",
                            },
                          }}
                        >
                          <span>Recommend to Mentorship / Other Schemes</span>
                        </LoadingButton>
                        <Button
                          variant="contained"
                          onClick={toggleClose}
                          sx={{
                            padding: "10px 23px !important",
                            margin: "3px",
                            borderRadius: "25px",
                            "&:hover": {
                              backgroundColor: "#253b80",
                            },
                          }}
                        >
                          {questionsFields.length > 0 ? "Cancel" : "OK"}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {
                    roles === 'SCSTHUB' && (
                      <>
                        <div className="form-group text-center my-3">
                          {approveStatus && (
                            <LoadingButton
                              type="submit"
                              loading={loading}
                              loadingPosition="start"
                              startIcon={<SaveIcon />}
                              variant="contained"
                              onClick={() => setValue("status", 1)}
                              sx={{
                                padding: "10px 20px !important",
                                margin: "3px",
                                borderRadius: "25px",
                                "&:hover": {
                                  backgroundColor: "green",
                                },
                              }}
                            >
                              <span>Approve</span>
                            </LoadingButton>
                          )}
                          <LoadingButton
                            type="submit"
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<CloseIcon />}
                            variant="contained"
                            sx={{
                              padding: "10px 20px !important",
                              margin: "3px",
                              borderRadius: "25px",
                              "&:hover": {
                                backgroundColor: "red",
                              },
                            }}
                            onClick={() => setValue("status", 2)}
                          >
                            <span>Reject</span>
                          </LoadingButton>

                          {/* <Button variant="contained" onClick={toggleClose} className="btn btn-cancel submit px-3 ml-3">Reject</Button> */}
                          <Button
                            variant="contained"
                            onClick={toggleClose}
                            sx={{
                              padding: "10px 23px !important",
                              margin: "3px",
                              borderRadius: "25px",
                              "&:hover": {
                                backgroundColor: "#253b80 ",
                              },
                            }}
                          >
                            {questionsFields.length > 0 ? "Cancel" : "OK"}
                          </Button>
                        </div>
                      </>
                    )
                  }
                </>
              ) : (
                <>
                  {/* <div className="text-center mt-3">
                                    <Button
                                        variant="contained"
                                        onClick={toggleClose}
                                        sx={{
                                            padding: "10px 23px !important", marginRight: '3px', borderRadius: "25px", "&:hover": {
                                                backgroundColor: "#253b80 ",
                                            }
                                        }}
                                    >
                                        {
                                            questionsFields.length > 0 ? ('Cancel') : ('OK')
                                        }
                                    </Button>
                                </div> */}
                </>
              )}
            </form>
          </div>
        ) : (
          <form className="signin-form box-bg">
            <div className="text-center py-4">
              <h5 className="mb-3">
                Some Additional Information are not verified.
              </h5>
              <h6>
                Validation Phase will be enabled once all pending and submitted
                information are verified.
              </h6>
            </div>
          </form>
        )}
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          maxWidth={"sm"}
          fullWidth
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <center>
              {roles === 'JURY' ? (
                <>
                  {getValues('status') === 1 ? (
                    <h3>Recommend to BDD</h3>
                  ) : (
                    <h3>Recommend to Mentorship / Other Schemes</h3>
                  )}
                </>
              ) : (
                <>
                  {getValues('status') === 1 ? (
                    <h3>Approve</h3>
                  ) : (
                    <h3>Reject</h3>
                  )}
                </>
              )}

            </center>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <h4 className="text-center p-3">Are you sure ?</h4>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="d-flex justify-content-center mb-2">
            <Button
              className="remove-api-modal-cancel"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button className="remove-api-modal-confirm" onClick={saveApi}>
              {roles === 'JURY' ? (
                <>
                  {/* {getValues('status') === 1 ? (
                    <span>Approve</span>
                  ) : (
                    <span>Reject</span>
                  )} */}
                   <span>Submit</span>
                </>

              ) : (
                <>
                  {getValues('status') === 1 ? (
                    <span>Approve</span>
                  ) : (
                    <span>Reject</span>
                  )}
                </>

              )}

            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/* <Modal
        open={openRemark}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle} className="form_tag">
          <Remarksmodal
            index={remarkIndex}
            remarkValue={remarkValue}
            handleCloseRemark={handleCloseRemark}
            status={formData.status}
          />
        </Box>
      </Modal> */}
    </Box>

  );
};

export default ScstScoreValidation;
