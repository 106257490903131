import React, { useContext, useState, useEffect } from "react";
import DataContext from "../../../context/DataContext";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import CustomLink from "../../common-page/CustomLink";
import { IconButton } from "@mui/material";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import ApprovalPedningFilter from "./ApprovalPedningFilter";

const ApprovalPending = ({ role }) => {


  const { setPageLoading, tableIndexValue, notificationChange } =
    useContext(DataContext);
  // useEffect(() => {
  //   debugger
  //   var statusValueScst = Number(localStorage.getItem("statusValueScst"));
  //   setStatusValue(statusValueScst ? statusValueScst : 100);
  // }, [notificationChange])
  const [count, setCount] = useState(0);
  const [scstHubList, setScstHubList] = useState([]);

  var pageNumberPartners = Number(localStorage.getItem("pageScst"));
  const [page, setPage] = useState(pageNumberPartners ? pageNumberPartners : 0);

  var listSizePartners = Number(localStorage.getItem("rowsPerPageScst"));
  const [rowsPerPage, setRowsPerPage] = useState(
    listSizePartners ? listSizePartners : 10
  );

  var districtNameScst = localStorage.getItem("districtNameScst");
  const [districtName, setDistrictName] = useState(
    districtNameScst ? districtNameScst : "All"
  );

  var hubScst = localStorage.getItem("hubScst");
  const [scsthub, setScsthub] = useState(hubScst ? hubScst : "All");

  var searchInputValueScst = localStorage.getItem("searchInputValueScst");
  const [searchInputValue, setSearchInputValue] = useState(
    searchInputValueScst ? searchInputValueScst : ""
  );

  var statusValueScst = Number(localStorage.getItem("statusValueScst"));
  const [statusValue, setStatusValue] = useState(
    statusValueScst ? statusValueScst : 28
  );
  const [searchName, setSearchName] = useState("");
  useDidMountEffect(() => {
    if (role === "scstadmin") {
      debugger
      var statusValueScst = Number(localStorage.getItem("statusValueScst"));
      setStatusValue(statusValueScst ? statusValueScst : 100);
    }

    debugger
  }, [notificationChange, statusValue]);
  useDidMountEffect(() => {
    (async () => await scstHublist())();
    // eslint-disable-next-line
  }, [page, rowsPerPage, districtName, scsthub, statusValue, searchInputValue]);

  const scstHublist = () => {
    setPageLoading(true);
    setScstHubList([]);
    var req = {
      listSize: rowsPerPage,
      pageNumber: page + 1,
      district: districtName,
      hub: scsthub,
      status: statusValue,
      searchString: searchName !== "" ? searchInputValue : "",
    };
    let url;
    if (role === "jury/scst") {
      url = `partner/scst/jury/startups/pending/list`;
    } else {
      url = `partner/scst/admin/startups/pending/list`;
    }
    apiService(url, req, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        setCount(res.data.count);
        if (res.data.startups) {
          setScstHubList(res.data.startups);
        } else {
          setScstHubList([]);
        }
      }
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem("pageScst", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPageScst", parseInt(event.target.value, 10));
    localStorage.setItem("pageScst", 0);
    setPage(0);
  };
// useEffect(() => {
//      localStorage.removeItem('districtNameScst')
//      localStorage.removeItem('hubScst')
//      localStorage.removeItem('statusValueScst')
//      localStorage.removeItem('manualNameScst')
//    }, [])
  return (
    <div className={role !== "jury/scst" ? "scsthub-div form_div chart" : ""}>
      <ApprovalPedningFilter
        setPage={setPage}
        searchName={searchName}
        setSearchName={setSearchName}
        districtName={districtName}
        setDistrictName={setDistrictName}
        scsthub={scsthub}
        setScsthub={setScsthub}
        statusValue={statusValue}
        setStatusValue={setStatusValue}
        searchInputValue={searchInputValue}
        setSearchInputValue={setSearchInputValue}
        lblName="Startups waiting for Approval"
        path={role}
      />

      <div className="table_tag">
        {scstHubList.length ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Startup Name</TableCell>
                  <TableCell>District</TableCell>
                  <TableCell>Hub</TableCell>
                  <TableCell>Entity Type</TableCell>
                  <TableCell>Onboarded Type</TableCell>
                  <TableCell>Onboarded Date</TableCell>
                  <TableCell align="center">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scstHubList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {tableIndexValue(rowsPerPage, page, index)}
                    </TableCell>
                    <TableCell>
                      <CustomLink
                        to={`/${role}/approval-pending/${btoa(
                          row.startupId
                        )}/${btoa(row.partnerOrInvestorId)}`}
                      >
                        {row.startupName}
                      </CustomLink>
                    </TableCell>
                    <TableCell>{row.district}</TableCell>
                    <TableCell>{row.investorName}</TableCell>
                    <TableCell>{row.entityType}</TableCell>
                    <TableCell>
                      {row.onboardType === 1 ? "Manual" : "Portal"}
                    </TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell align="center">{row.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ float: "left" }}
              ActionsComponent={(paginationProps) => (
                <CustomTablePaginationActions {...paginationProps} />
              )}
            />
          </TableContainer>
        ) : (
          <div className="nodata_tag">
            <img
              src="/images/Tanfundlogo-black-1.png"
              className="no_data_logo"
              alt=""
            />
            <h4>You're all caught up!</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApprovalPending;

function CustomTablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ flexShrink: 0, marginLeft: "1rem" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPage />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPage />
      </IconButton>
    </div>
  );
}
