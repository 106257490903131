import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { Dialog } from "@mui/material";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import notifyService from "../../../api/notifySerivce";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Termsconditionscst = () => {
  const { navigator } = useContext(DataContext);
  const [formDate, setFormData] = useState({});
  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getApi = async () => {
    await apiService(`startup/startupdeclaration/get`, "", "get").then(
      (res) => {
        if (res) {
          var response = res.data;
          if (response.editForm === false) {
            setReadyOnlyValue(true);
          }
          setFormData(response);
        }
      }
    );
  };

  const [loading, setLoading] = useState(false);
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const onSubmit = () => {
    if (readyOnlyValue === false) {
      setLoading(true);
      var getform = formDate;
      getform.initialSubmit = true;
      getform.acceptscstTerms = true;
      getform.acceptNDATerms = true;
      debugger;
      apiService("startup/startupdeclaration/save", getform, "post").then(
        (res) => {
          setLoading(false);
          if (res) {
            if (res.data.responseStatus === "Success") {
              notifyService("success", "Success", "Saved successfully !!");
              handleClickOpen();
            }
          }
        }
      );
    }
  };

  const handlePreviousscst = () => {
    navigator("/startup/dashboard/scst");
  };
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    navigator("startup/all-dashboard/scst");
  };
  return (
    <>
      <section className="">
        <div className="container">
          <div className="form_div chart">
            <form className="signin-form">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="terms-head">
                    <div className="text-center">
                      <h5 className="font-weight-bold mb-3">
                        Terms And Conditions
                      </h5>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="mt-2">
                      <p>
                        <ol>
                          <li>
                            I declare that all the statements made in this
                            application are true, complete and correct to the
                            best of my knowledge and belief. In the event of any
                            information found false or incorrect, my candidature
                            will stand cancelled and all my claims will be
                            forfeited.
                          </li>
                          <br />
                          <li>
                            I declare that we read the Eligibility Criteria to
                            apply for the Tamil Nadu SC/ST Startup Fund in the
                            StartupTN website (https://startuptn.in/sc-st-fund/)
                            and confirm that I fulfill all the eligibilities.
                          </li>
                          <br />
                          <li>
                            I also understand that StartupTN reserves all the
                            rights towards the selection of applications based
                            on the defined criteria and that the decision of the
                            committee and jury is final.
                          </li>
                          <br />
                          <li>
                            I declare that the Startup is registered with TANSIM
                            & Startup India and is headquartered in Tamil Nadu
                            or will relocate the registered office to Tamil
                            Nadu.
                          </li>
                          <br />
                          <li>
                            {" "}
                            I declare that the Startup is registered as a
                            Private Limited Company (under Companies Act, 2013)
                            or as a Limited Liability Partnership (under The
                            Limited Liability Partnership Act, 2008) or a
                            Registered Partnership Firm (under The Partnership
                            Act, 1932) or Udyam Registration for proprietorship
                          </li>
                        </ol>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-12">
                  <div className="terms-head">
                    <div className="text-center">
                      <h5 className="font-weight-bold mb-3">
                        Non-Disclosure Agreement
                      </h5>
                    </div>
                  </div>
                  <div className="pl-4">
                    <div className="mt-2">
                      <p>
                        By clicking 'Agree,' I confirm that all information and
                        documents provided are accurate, correct, and true to
                        the best of my knowledge. I acknowledge the accuracy and
                        authenticity of the submitted materials and understand
                        that I shall be liable for any inaccuracies or mistakes
                        in the information provided by me.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-12">
                  <div className="terms-head">
                    <div className="text-center">
                      <h5 className="font-weight-bold mb-3">Disclaimer</h5>
                    </div>
                  </div>
                  <div className="pl-4">
                    <div className="mt-2">
                      <p>
                        The information you share will be treated as
                        confidential and used solely for processing and
                        evaluating your application for the TN SC/ST Startup
                        Fund of StartupTN. It is not to be disclosed, shared, or
                        used beyond its intended purpose. In the event of any
                        misuse or unauthorized use of your information by our
                        Due Diligence partners, the Due Diligence partner will
                        be held accountable and may be required to address any
                        resulting consequences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group text-center">
                <Button
                  onClick={handlePreviousscst}
                  className="decline-btn px-3 mr-3"
                >
                  {readyOnlyValue === false ? `I DECLINE` : `Close`}
                </Button>
                {readyOnlyValue === false ? (
                  <LoadingButton
                    onClick={() => onSubmit()}
                    loading={loading}
                    loadingPosition="start"
                    className="accept-btn px-3"
                  >
                    I ACCEPT
                  </LoadingButton>
                ) : (
                  <></>
                )}
              </div>
            </form>
          </div>
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div className="text-center">
              <img src="/images/verified.png" className="ver-img" />
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <h3 className="black-cl text-center">
                Application Submitted Successfully
              </h3>
              <h6 className="text-center my-2">
                Congratulations on submitting your application
              </h6>
              <h6 className="text-center my-2">We will get back to you soon</h6>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="btn-cen">
            <Button onClick={handleClose} autoFocus variant="outlined">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </section>
    </>
  );
};

export default Termsconditionscst;
