import React from 'react'
import { Outlet } from 'react-router-dom'
import Scsthubavpnavbar from './Scsthubavpnavbar'
import useDidMountEffect from '../../hooks/useDidMountEffect'
import ScstadminNavbar from './ScstadminNavbar'

const Scsthubavplayout = ({ role }) => {
  
  // window.location.href = "scsthubavp/startups/list";
  const url = window.location.pathname
  // console.log(url);
  // console.log(role);

  localStorage.setItem('regRoleId', 8)
  useDidMountEffect(() => {
    document.getElementById('theme').setAttribute('href', '/css/admin.css');
  }, [])
  return (
    <div className='admin_main_div'>
      {
        (role === 'scsthubavp') && (
          <Scsthubavpnavbar role={role} />
        )
      }
      {
        role === 'scstadmin' && (
          <ScstadminNavbar role={role} />
        )
      }
      <Outlet></Outlet>
    </div>
  )
}

export default Scsthubavplayout