import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Viewimage from "../../../../../api/Viewimage";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import CustomLink from "../../../../common-page/CustomLink";
const Productmarketfit = ({ details }) => {
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    setViewImage(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <main>
      <section>
        <div className="row">
          <div className="col-lg-12 col-12">
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="d-flex">
                  <label className="mr-3">About the Startup :</label>
                  <p>
                    <b>{details.about ? details.about : `-`}</b>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="d-flex">
                  <label className="mr-3">
                    Do you own patents (Intellectual Properties) :
                  </label>
                  <p className="ml-auto">
                    <b>{details.intellectualProps ? <>Yes</> : <>No</>}</b>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="d-flex">
                  <label className="mr-3">Technology Stack :</label>
                  <p className="ml-auto">
                    <b>{details.technologyStack}</b>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="d-flex">
                  <label className="mr-3">Market Analysis :</label>
                  <p className="ml-auto">
                    <b>{details.marketAnalysis}</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {details.products && (
            <div className="col-lg-12 col-12 my-3">
              <div className="div_header">
                <h6>Products Details</h6>
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>No</TableCell>
                      <TableCell>Product Name </TableCell>
                      <TableCell>Product Link </TableCell>
                      <TableCell>Product Road Map </TableCell>
                      <TableCell>Product Image </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {details.products.map((list, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{list.name}</TableCell>
                        <TableCell>
                          {list.link ? (
                            <Link
                              className="view-img-btn"
                              to={`${list.link}`}
                              target="_blank"
                            >
                              View Product
                            </Link>
                          ) : (
                            `-`
                          )}
                        </TableCell>
                        <TableCell>{list.productRoadMap}</TableCell>
                        <TableCell>
                          {list.productImageName ? (
                            <Button
                              onClick={() =>
                                handleClickOpen(list.productImageName)
                              }
                            >
                              View Image
                            </Button>
                          ) : (
                            `-`
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </section>
      <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
    </main>
  );
};

export default Productmarketfit;
