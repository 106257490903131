import React from 'react'
import { Outlet } from 'react-router-dom'
import MentorshipNavbar from './MentorshipNavbar'

const Scstmentorshiproute = () => {
  return (
    <div>
         <MentorshipNavbar />
      <Outlet></Outlet>
    </div>
  )
}

export default Scstmentorshiproute