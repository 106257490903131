import React, { useState } from 'react'
import { Tab } from "@mui/material";
import Box from "@mui/material/Box";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ScstCurrentMentor from './ScstCurrentMentor';
import ScstPreviousMentor from './ScstPreviousMentor';
const ScstAdminMentorTab = ({role}) => {
    const [value, setValue] = useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
  return (
    <div className="scsthub-div form_div chart">
    
    <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                >
                    <Tab label="Current Mentor" value="1" />
                    <Tab
                        label="Previous Mentor" value="2"/>
                </TabList>
            </Box>
            <TabPanel value="1">
                <ScstCurrentMentor role={role}/>
            </TabPanel>
            <TabPanel value="2" >
                <ScstPreviousMentor  role={role}/>
            </TabPanel>
        </TabContext>
    </Box>
</div>
  )
}

export default ScstAdminMentorTab