import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Autocomplete, Button, FormHelperText, Grid, IconButton, TextField } from '@mui/material';
import apiService from '../../../api/apiService';
import DataContext from '../../../context/DataContext';
import cryptoJS from '../../../hooks/cryptoJS';
import notifyService from '../../../api/notifySerivce';
const AdminResetPassword = () => {
    const { handleSubmit, control, clearErrors, setValue, getValues, reset, formState: { errors }, } = useForm({ values: {}, mode: 'onChange' });
    const { setPageLoading } = useContext(DataContext)
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [checkSamePassword, setCheckSamePassword] = useState(false);
    const [showRePassword, setShowRePassword] = useState(false);
    const [emailSuggestions, setEmailSuggestions] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState('');
    const fetchEmailSuggestions = async (email) => {
        if (email.length < 3) {
            setEmailSuggestions([]);
            return;
        }
        try {
            const response = await apiService("email/list", { email }, "post");
            if (response && response.data) {
                setEmailSuggestions(response.data);
            }
        } catch (error) {
            console.error("Error fetching email suggestions:", error);
        }
    };
    const handleEmailChange = (event, value) => {
        setSelectedEmail(value);
        setValue('email', value);
        if (value && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
            clearErrors('email');
        } else {
            clearErrors('email');
        }
    };
    const onSubmit = (data) => {
        console.log(data);
        if (!data.email || !data.email.trim()) {
            notifyService('error', 'Error', 'Email is required');
            return;
        }
        if (data.newPassword === data.reenterPassword) {
            setPageLoading(true);
            setCheckSamePassword(false);
            var getform = data;
            getform.email = cryptoJS(data.email.toLowerCase());
            getform.reenterPassword = cryptoJS(data.reenterPassword);
            getform.newPassword = cryptoJS(data.newPassword);
            apiService("admin/update/password", getform, "post").then((res) => {
                setPageLoading(false);
                if (res && res.data && res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Password Reset Successfully');
                    reset();
                }
            });
        } else {
            setCheckSamePassword(true);
        }
    }
    const onError = (event) => {
        console.log('error Data:', event);
    }
    const handlePasswordChange = (event) => {
        var val = event.target.value.trim();
        if (val.length !== 0) {
            setValue('newPassword', val);
            clearErrors('newPassword');
            setPassword(val);
        }
    };
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => { event.preventDefault(); };
    const handleClickShowRePassword = () => setShowRePassword((show) => !show);
    return (
        <div className="scsthub-div form_div chart">
            <div className='header_tag'>
                <h5>Reset Password</h5>
            </div>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={1}>
                    <Grid item lg={4} md={4} sm={12} xs={12} className='mb-3'>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Email is required",
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: "Please enter a valid email address",
                                },
                            }}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    freeSolo
                                    options={emailSuggestions}
                                    onInputChange={(event, value) => {
                                        setValue('email', value); // Manually update email value on input change
                                        fetchEmailSuggestions(value); // Fetch suggestions based on typed value
                                        // Check for email validation and clear errors if valid
                                        if (value && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
                                            clearErrors('email');
                                        } else {
                                            clearErrors('email');
                                        }
                                    }}
                                    onChange={(event, value) => handleEmailChange(event, value)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Email *"
                                            placeholder="Type to search..."
                                            variant="outlined"
                                            error={Boolean(errors.email)}
                                        />
                                    )}
                                />
                            )}
                        />
                        <FormHelperText className="text-danger">
                            {errors.email && errors.email.message}
                        </FormHelperText>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} className='mb-3'>
                        <Controller
                            name="newPassword"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Password is required",
                                pattern: {
                                    value: /^\S*$/,
                                    message: "Password cannot contain whitespace"
                                }
                            }}
                            render={({ field }) => (
                                <TextField
                                    variant="outlined"
                                    label="Password *"
                                    placeholder="Enter Password"
                                    fullWidth
                                    {...field}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        handlePasswordChange(e);
                                    }}
                                    type={showPassword ? "text" : "password"}
                                    inputProps={{ maxLength: 20 }}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        )
                                    }}
                                    error={Boolean(errors.newPassword && errors.newPassword)}
                                />
                            )}
                        />
                        <FormHelperText className="text-danger">
                            {errors.newPassword && errors.newPassword.message}
                        </FormHelperText>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12} className='mb-3'>
                        <Controller
                            name="reenterPassword"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Enter Confirm Password",
                                validate: (value) =>
                                    value === getValues("newPassword") || "Passwords do not match",
                            }}
                            render={({ field }) => (
                                <TextField
                                    variant="outlined"
                                    label="Confirm Password *"
                                    placeholder="Confirm Password"
                                    fullWidth
                                    {...field}
                                    type={showRePassword ? "text" : "password"}
                                    inputProps={{ maxLength: 20, }}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={handleClickShowRePassword}>
                                                {showRePassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        )
                                    }}
                                    error={Boolean(errors.reenterPassword && errors.reenterPassword)}
                                />
                            )}
                        />
                        <FormHelperText className="text-danger">
                            {errors.reenterPassword && errors.reenterPassword.message}
                        </FormHelperText>
                        <FormHelperText className="text-danger">
                            {checkSamePassword === true ? "Password Mismatch" : <></>}
                        </FormHelperText>
                    </Grid>
                </Grid>
                <div className='text-center mb-3'>
                    <Button
                        className='ml-3'
                        variant="contained"
                        type="submit"
                    >
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    )
}
export default AdminResetPassword;