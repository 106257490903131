import React, { useContext, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DataContext from "../../../context/DataContext";
// import useDidMountEffect from '../../hooks/useDidMountEffect';
// import apiService from '../../api/apiService';
import { useParams } from "react-router-dom";
import Scsthubavpremarks from "./Scsthubavpremarks";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
// import Scsthubavpremarks from '../scsthubavp-module/scsthubavp-details/Scsthubavpremarks';

const Scsthubavpevaluation = ({ listData, listApi }) => {
  // console.log(listApi());

  const { navigator, Accordion, AccordionSummary, AccordionDetails } =
    useContext(DataContext);
  // const [viewQuestionData, setViewQuestionData] = useState([])
  // const [startupData, setStartupData] = useState({})

  const [juryData, setJuryData] = useState([]);
  const roles = localStorage.getItem("role");
  useDidMountEffect(() => {
    if (roles === "SCSTHUB") {
      listApi();
    }
  }, []);

  useDidMountEffect(() => {
    if (listData) {
      // setListData(listData.startupRounds[1])
      setJuryData(listData.jury);
    }
  }, [listData]);

  const [expanded, setExpanded] = useState("");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const getstatusText = (value) => {
    if (value === 0) {
      return "N/A";
    } else if (value === 1) {
      return "Recommend to BDD First Connect";
    } else if (value === 2) {
      return "Recommend to Mentorship";
    } else {
      return "";
    }
  };
  return (
    <>
      {listData && (
        <div className="">
          <div className="table_header_tag d-flex justify-content-between">
            <div>
              Status : <b className="c-green">{getstatusText(listData.status)}</b>
            </div>
            {listData && juryData && (
              <div className="ml-auto">
                Overall Average Score : <b>{listData.avgScore}</b>
              </div>
            )}
          </div>
          {juryData ? (
            juryData.map((data, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id={`panel${index}d-header`}
                >
                  <div className="row w-100">
                    <div className="col-lg-4 col-md-6 col-12">
                      <b>{data.name}</b>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      Average Score : <b>{data.score}</b>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      Status :
                      <b>
                        {data.status === 0 && <span> Pending</span>}
                        {data.status === 1 && (
                          <span className="c-green">
                            {" "}
                            Recommend to BDD First Connect
                          </span>
                        )}
                        {data.status === 2 && (
                          <span className="c-green">
                            {" "}
                            Recommend to Mentorship
                          </span>
                        )}
                        {data.status === 3 && (
                          <span className="c-red"> Rejected</span>
                        )}
                      </b>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="table_tag">
                    {data.startupQuestions ? (
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="caption table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>No</TableCell>
                              <TableCell>Question</TableCell>
                              <TableCell align="center">Rating</TableCell>
                              {/* {list.questionType !== "yesno" && ( */}
                              <TableCell align="center">Description</TableCell>
                              {/* )} */}
                              <TableCell align="center">Remarks</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.startupQuestions.map((list, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{list.question}</TableCell>
                                <TableCell align="center">
                                  {list.questionType === "yesno"
                                    ? list.answer
                                    : list.score}
                                </TableCell>
                                {/* {list.questionType !== "yesno" && ( */}
                                {/* <TableCell align="center">
                                  {list.scoreDescription
                                    ? list.scoreDescription[list.score - 1]
                                        .description
                                    : "-"}
                                </TableCell> */}
                                {/* <TableCell align="center">
                                  {list.scoreDescription && list.score > 0 && list.score <= list.scoreDescription.length
                                    ? list.scoreDescription[list.score - 1].description
                                    : "-"}
                                </TableCell> */}
                              <TableCell align="center">
  {list.scoreDescription && list.scoreDescription.length > 0
    ? list.scoreDescription.map((desc, index) => (
        <div key={index}>
          {desc.rating === list.score ? desc.description : null}
        </div>
      ))
    : "-"}
</TableCell>

                                {/* )} */}
                                <TableCell align="center">
                                  <Scsthubavpremarks
                                    remarks={list.remarks}
                                    lblName="View"
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <div className="nodata_tag">
                        <img
                          src="/images/Tanfundlogo-black-1.png"
                          className="no_data_logo"
                          alt=""
                        />
                        <p>No Data Available</p>
                      </div>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <div className="nodata_tag">
              <img
                src="/images/Tanfundlogo-black-1.png"
                className="no_data_logo"
                alt=""
              />
              <p>No Data Available</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Scsthubavpevaluation;
