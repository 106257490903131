import React, { useContext, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import apiService from "../../../api/apiService";
import notifyService from "../../../api/notifySerivce";
import {
  Button,
  Fab,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import DataContext from "../../../context/DataContext";
import DeleteIcon from "@mui/icons-material/Delete";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import { MuiFileInput } from "mui-file-input";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PreviewIcon from "@mui/icons-material/Preview";
import { Link } from "react-router-dom";
import Viewimage from "../../../api/Viewimage";
import { FileUploader } from "react-drag-drop-files";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const fileTypes = ["pdf"];
const fileTypesimg = ["jpg, png, jpeg"];
const Incubationaccprogramscst = ({ index }) => {
  const showAddInfo = localStorage.getItem("showInfo");
  const [formData, setFormData] = useState({});
  const {
    navigator,
    handleBackapp,
    VisuallyHiddenInput,
    Paper,
    setPageLoading,
  } = useContext(DataContext);
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [readyOnlyBDDValue, setReadyOnlyBDDValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    fields: patentsFields,
    append: patentsAppend,
    remove: patentsRemove,
  } = useFieldArray({
    control,
    name: "patents",
  });
  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getApi = async () => {
    setPageLoading(true);
    await apiService(`startup/incubationaccprogram/get`, "", "get").then(
      (res) => {
        if (res) {
          setPageLoading(false);
          setFormData({});
          let response = res.data;
          if (response.id !== 0) {
            setFormData(response);
            // if (response.editForm === false) {
            //   setReadyOnlyValue(true);
            //   setReadyOnlyBDDValue(true)
            // } else {
            //   if (showAddInfo === 'true') {
            //     setReadyOnlyValue(true);
            //   } else {
            //     setReadyOnlyValue(false);
            //   }
            //   setReadyOnlyBDDValue(false)
            // }
            // if (response.editForm === false && response.superEdit === false) {
            //   debugger;
            //   setReadyOnlyValue(true);
            //   setReadyOnlyBDDValue(true);
            // } else {
            //   debugger;
            //   if (showAddInfo === "true") {
            //     setReadyOnlyValue(true);
            //   }
            //   if (response.editForm === false && response.superEdit === true) {
            //     setReadyOnlyValue(false);
            //   }
            //   if (response.editForm === true && response.superEdit === false) {
            //     setReadyOnlyValue(true);
            //   }
            //   if (response.editForm === true && response.superEdit === true) {
            //     setReadyOnlyValue(false);
            //   }
            //   debugger;
            //   setReadyOnlyBDDValue(false);
            // }
            // if (response.superEdit === true) {
            //   debugger
            //   setReadyOnlyValue(false);
            //   setReadyOnlyBDDValue(false);
            //   // if (showAddInfo === "true") {
            //   //   debugger
            //   //   setReadyOnlyValue(true);
            //   // }
            // } else {
            //   debugger
            //   if (response.editForm == true) {
            //     setReadyOnlyValue(false);
            //     // if (showAddInfo === "true") {
            //     //   setReadyOnlyValue(true);
            //     // }
            //   } else if(response.editForm == false && response.superEdit == false) {
            //     debugger
            //     setReadyOnlyValue(true);
            //     setReadyOnlyBDDValue(true);
            //     // if (showAddInfo === "true") {
            //     //   setReadyOnlyValue(true);
            //     // }
            //   } else if(response.editForm == false){
            //     setReadyOnlyValue(false);
            //     setReadyOnlyBDDValue(false);
            //     // if (showAddInfo === "true") {
            //     //   setReadyOnlyValue(true);
            //     // }
            //   }
            // }
            if (response.patents) {
              response.patents.forEach((element, index) => {
                if (element.docname) {
                  element.docUrl = element.docname;
                }
                setUploadedPatents((prev) => ({
                  ...prev,
                  [index]: true,
                }));
              });
            }
            response.patented = response.patented === true ? "Yes" : "No";
            if (response.patentDocumentName) {
              response.patentDocumentUrl = response.patentDocumentName;
            }
            if (response.incubationAgreementName) {
              setIsUploaded(true);
              response.incubationURL = response.incubationAgreementName;
            }
            if (response.currentlyIncubated) {
              setCurrentlyIncubatedValue(response.currentlyIncubated);
            }
            // setCurrentlyIncubatedValue(response.currentlyIncubated)
          } else {
            setFormData(response);
          }
          if (response.superEdit === true) {
            debugger
            setReadyOnlyValue(false);
            setReadyOnlyBDDValue(false);
            // if (showAddInfo === "true") {
            //   debugger
            //   setReadyOnlyValue(true);
            // }
          } else {
            debugger
            if ( showAddInfo === "true" && response.editForm == true) {
              setReadyOnlyValue(true);
              // if (showAddInfo === "true") {
              //   setReadyOnlyValue(true);
              // }
              debugger
            } else if(response.editForm == true){
              debugger
              setReadyOnlyValue(false);
            }
             else if(response.editForm == false && response.superEdit == false) {
              debugger
              setReadyOnlyValue(true);
              setReadyOnlyBDDValue(true);
              // if (showAddInfo === "true") {
              //   setReadyOnlyValue(true);
              // }
            } else if(response.editForm == false){
              setReadyOnlyValue(false);
              setReadyOnlyBDDValue(false);
              // if (showAddInfo === "true") {
              //   setReadyOnlyValue(true);
              // }
            }
          }
        }
      }
    );
  };
  const handlePatented = (value) => {
    setValue("patented", value);
    clearErrors("patented");
    if (value === "Yes") {
      addPatentsDetailsBtn();
    } else {
      patentsRemove();
      setUploadedPatents({});
    }
  };
  const addPatentsDetailsBtn = () => {
    patentsAppend({
      id: 0,
      name: "",
      stage: "",
      number: "",
      doc: "",
      docname: "",
    });
  };

  // delete Founders
  const deletePatentsDetails = async (index) => {
    const id = getValues(`patents.${index}.id`);
    debugger;
    if (id !== 0 && id !== undefined) {
      debugger;
      apiService(`startup/patent/remove?id=${id}`, "", "delete").then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            patentsRemove(index);
            var val = getValues();
            setFormData(val);
          }
        }
      });
    } else {
      debugger;
      patentsRemove(index);
      var val = getValues();
      setFormData(val);
    }
  };
  const [currentlyIncubatedValue, setCurrentlyIncubatedValue] = useState();
  const inputRef = useRef(null);
  const handleFileUpload = (event, filekey, fileName, fileUrl) => {
    if (event !== null) {
      let logoselectedFile;
      if (event.target === undefined) {
        logoselectedFile = event;
      } else {
        logoselectedFile = event.target.files[0];
      }
      if (logoselectedFile) {
        const fileSizeLimit = 5 * 1024 * 1024; // 5 MB limit
        if (logoselectedFile.size > fileSizeLimit) {
          // File size exceeds limit, show error message
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return; // Exit function
        }
        var reader = new FileReader();
        var imagetype = logoselectedFile.type;
        var imagedatatype = imagetype.split("/");
        var img_crt_type = imagedatatype[1];
        if (
          img_crt_type === "jpeg" ||
          img_crt_type === "jpg" ||
          img_crt_type === "png" ||
          img_crt_type === "pdf"
        ) {
          setValue(filekey, "");
          setValue(fileName, "");
          setValue(fileUrl, "");
          // setName("");
          // setUrl("");
          // if (event.target === undefined) {
          //   setdata(event);
          // } else {
          //   setdata(event.target.files[0]);
          // }
          var fileValue = logoselectedFile;
          reader.readAsDataURL(logoselectedFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(",");
            var ImageValue = spl[1];
            var img_name = fileValue.name;
            // setUrl(logourl1);
            setValue(filekey, ImageValue);
            setValue(fileName, img_name);
            setValue(fileUrl, logourl1);
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileUrl);
            // if (filekey === "logo") {
            //   setLogoInvalid(false);
            // }
          };
        } else {
          notifyService(
            "danger",
            "File Format Invalid",
            "Please check your file format."
          );
        }
      }
    } else {
      // Handle case when event is null (optional)
    }
  };
  const onSubmit = (data) => {
    if (!readyOnlyBDDValue) {
      setPageLoading(true);
      data.patented = data.patented === "Yes" ? true : false;
      apiService("startup/incubationaccprogram/save", data, "post").then(
        (res) => {
          setPageLoading(false);
          if (res && res.data.responseStatus === "Success") {
            notifyService("success", "Success", "Saved successfully !!");
            if (showAddInfo === "true") {
              navigator("/startup/product-market/scst");
            } else {
              navigator("/startup/dashboard/scst");
            }
          }
        }
      );
    }
  };
  const onError = (error) => {
    console.log(error);
    notifyService("danger", "Error", "Please check mandatory fields");
  };
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    setViewImage(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [isUploaded, setIsUploaded] = useState(false);
  const handleFileChange = (e) => {
    handleFileUpload(
      e,
      "incubationAgreement",
      "incubationAgreementName",
      "incubationURL"
    );
    setIsUploaded(true);
  };

  // const [isUploadedPatent, setIsUploadedPatent] = useState(false);
  // const handleFileChangePatent = (e) => {
  //   handleFileUpload(
  //     e,
  //     `patents.${index}.doc`,
  //     `patents.${index}.docname`,
  //     `patents.${index}.docUrl`
  //   )
  //   setIsUploadedPatent(true);
  // }

  const [uploadedPatents, setUploadedPatents] = useState({});
  const handleFileChangePatent = (index) => (e) => {
    handleFileUpload(
      e,
      `patents.${index}.doc`,
      `patents.${index}.docname`,
      `patents.${index}.docUrl`
    );

    setUploadedPatents((prev) => ({
      ...prev,
      [index]: true,
    }));
  };

  // const delIncu = () => {
  //   setValue('incubationAgreementName', '')
  //   setIsUploaded(false)
  // }

  const [selectedType, setSelectedType] = useState("");

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedType(value);
  };

  const getDocumentLabel = () => {
    switch (selectedType) {
      case "Patent":
        return "Patents Documents";
      case "Trademark":
        return "Trademark Certificate";
      case "Copyright":
        return "Copyright Certificate";
      default:
        return "Select Document";
    }
  };

  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
              <img src="/images/Fig-back.png" className="img-fluid" alt="" />
            </span>{" "}
            <h6 className="ml-2 mb-0">Incubation / Acceleration details</h6>
          </div>
          <hr />
          <form
            className="signin-form"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <div className="row">
              <div className="d-none">
                <div className="form-group">
                  <Controller
                    name="id"
                    control={control}
                    defaultValue="0"
                    render={({ field }) => <TextField {...field} />}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group">
                  <FormLabel>
                    Have you been or are you currently incubated at any Startup
                    Incubation Center *
                  </FormLabel>
                  <Controller
                    name="currentlyIncubated"
                    control={control}
                    defaultValue="false"
                    rules={{ required: false }}
                    render={({ field }) => (
                      <RadioGroup row name="position" {...field}>
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            setCurrentlyIncubatedValue(e.target.value);
                          }}
                          defaultValue="null"
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          onChange={(e) => {
                            setCurrentlyIncubatedValue(e.target.value);
                            setValue("incubationCenterName", "");
                          }}
                          defaultValue="null"
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                        {/* <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setCurrentlyIncubatedValue(e.target.value); setValue('incubationCenterName', '') }} defaultValue='null' value='Granted' control={<Radio />} label="Granted" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setCurrentlyIncubatedValue(e.target.value); setValue('incubationCenterName', '') }} defaultValue='null' value='Applied' control={<Radio />} label="Applied" />
                        <FormControlLabel disabled={readyOnlyValue} onChange={(e) => { setCurrentlyIncubatedValue(e.target.value); setValue('incubationCenterName', '') }} defaultValue='null' value='Process of Applying' control={<Radio />} label="Process of Applying" /> */}
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.currentlyIncubated &&
                      errors.currentlyIncubated.message}
                  </FormHelperText>
                </div>
              </div>
              {(currentlyIncubatedValue === "true" ||
                currentlyIncubatedValue === true) && (
                  <>
                    <div className="col-lg-4 col-12">
                      <div className="form-group mt-2">
                        <Controller
                          name="incubationCenterName"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Incubation Center  Name is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              label="Incubation Center Name *"
                              placeholder="Enter Incubation Center  Name"
                              fullWidth
                              inputProps={{ readOnly: readyOnlyValue }}
                              {...field}
                            />
                          )}
                        />
                        <FormHelperText className="text-danger">
                          {errors.incubationCenterName &&
                            errors.incubationCenterName.message}
                        </FormHelperText>
                      </div>
                    </div>
                    {showAddInfo === "true" && (
                      <>
                        <div className="col-lg-4 col-12">
                          {isUploaded && (
                            <span>
                              Incubation Agreement
                              <span className="text-danger">*</span>
                            </span>
                          )}
                          <div className="d-flex align-items-center">
                            <div className="form-group mb-1">
                              <Controller
                                name="incubationAgreement"
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: "Incubation Agreement is required",
                                }}
                                render={({ field }) => (
                                  <FileUploader
                                    {...field}
                                    handleChange={handleFileChange}
                                    value={getValues("incubationAgreementName")}
                                    name="file"
                                    types={fileTypes}
                                    children={
                                      <div
                                        className={
                                          isUploaded
                                            ? "fileupload-product-bg-active"
                                            : "fileupload-product-bg"
                                        }
                                      >
                                        {/* "fileupload-pdf-dpiit-bg" */}
                                        <center>
                                          <p
                                            className={
                                              isUploaded
                                                ? "fileupload-pdf-active"
                                                : "fileupload-pdf"
                                            }
                                          >
                                            <span>
                                              <AttachFileIcon className="file-icon mr-2" />
                                              {isUploaded
                                                ? "Uploaded (Click to Change) "
                                                : "Incubation Agreement *"}
                                            </span>
                                          </p>
                                        </center>
                                      </div>
                                    }
                                    disabled={readyOnlyBDDValue}
                                  />
                                )}
                              />
                              <FormHelperText className="ml-2">
                                Maximum size: 5 MB. File format .pdf
                              </FormHelperText>
                              <FormHelperText className="text-danger">
                                {errors.incubationAgreement &&
                                  errors.incubationAgreement.message}
                              </FormHelperText>
                            </div>
                            <span
                              className="pre-btn-dpiit"
                              onClick={() =>
                                handleClickOpen(getValues("incubationURL"))
                              }
                            >
                              <VisibilityIcon className="icon-size mr-1" />
                              <Link style={{ color: "#80809e" }}> Preview</Link>
                            </span>
                          </div>

                          {/* {getValues('incubationURL') ? (
                              <div>
                                <p className="mb-0">
                                  Incubation Agreement
                                </p>
                                <div className="row">
                                  <div className="col-lg-7 col-12 my-3">
                                    <Controller
                                      name="incubationAgreement"
                                      control={control}
                                      defaultValue=""
                                      rules={{
                                        required:
                                          "Incubation Agreement is required",
                                      }}
                                      render={({ field }) => (
                                        <Button
                                          component="label"
                                          variant="contained"
                                          {...field}
                                          fullWidth
                                          onChange={(e) =>
                                            handleFileUpload(
                                              e,
                                              "incubationAgreement",
                                              "incubationAgreementName",
                                              "incubationURL"
                                            )
                                          }
                                          startIcon={<CloudUploadIcon />}
                                          href="#file-upload"
                                          disabled={readyOnlyValue}
                                        >
                                          Change
                                          {readyOnlyValue === false ? (
                                            <VisuallyHiddenInput type="file" />
                                          ) : (
                                            <></>
                                          )}
                                        </Button>
                                      )}
                                    />
                                    <div>
                                      <span className="fn-12">
                                        Maximum 5 mb allowed doc (png, jpg, jpeg, pdf){" "}
                                        <span className="text-danger">*</span>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-lg-5 col-12 my-3">
                                    <Fab variant="extended">
                                      <Link
                                        color="black"
                                        onClick={() => handleClickOpen(getValues('incubationURL'))}
                                      >
                                        <PreviewIcon sx={{ mr: 1 }} />
                                        View Document
                                      </Link>
                                    </Fab>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="form-group ">
                                <Controller
                                  name="incubationAgreement"
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required:
                                      'Proof Of Incubation is required',
                                  }}
                                  render={({ field }) => (
                                    <MuiFileInput
                                      {...field}
                                      onChange={(e) =>
                                        handleFileUpload(
                                          e,
                                          "incubationAgreement",
                                          "incubationAgreementName",
                                          "incubationURL"
                                        )
                                      }
                                      value={getValues('incubationAgreementName')}
                                      variant="outlined"
                                      inputRef={inputRef}
                                      fullWidth
                                      label="Proof Of Incubation *"
                                      placeholder="Choose File"
                                    />
                                  )}
                                />
                                <FormHelperText className="text-danger">
                                  {errors.incubationAgreement && errors.incubationAgreement.message}
                                </FormHelperText>
                                <div>
                                  <span className="fn-12">
                                    Maximum 5 mb allowed doc (png, jpg, jpeg, pdf){" "}
                                    <span className="text-danger">*</span>
                                  </span>
                                </div>
                              </div>
                            )} */}
                        </div>
                      </>
                    )}
                  </>
                )}
              {showAddInfo === "true" && (
                <>
                  {/* <div className="col-lg-6 col-12">
                      {getValues('patentDocumentUrl') ? (
                        <div>
                          <p className="mb-0">
                            If so, upload your IP related document / Application.
                          </p>
                          <div className="row">
                            <div className="col-lg-7 col-12 my-3">
                              <Controller
                                name="patentDocument"
                                control={control}
                                defaultValue=""
                                rules={{
                                  required:
                                    "IP related document / Application is required",
                                }}
                                render={({ field }) => (
                                  <Button
                                    component="label"
                                    variant="contained"
                                    {...field}
                                    fullWidth
                                    onChange={(e) =>
                                      handleFileUpload(
                                        e,
                                        "patentDocument",
                                        "patentDocumentName",
                                        "patentDocumentUrl"
                                      )
                                    }
                                    startIcon={<CloudUploadIcon />}
                                    href="#file-upload"
                                    disabled={readyOnlyValue}
                                  >
                                    Change
                                    {readyOnlyValue === false ? (
                                      <VisuallyHiddenInput type="file" />
                                    ) : (
                                      <></>
                                    )}
                                  </Button>
                                )}
                              />
                              <div>
                                <span className="fn-12">
                                  Maximum 5 mb allowed doc (png, jpg, jpeg, pdf){" "}
                                  <span className="text-danger">*</span>
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-5 col-12 my-3">
                              <Fab variant="extended">
                                <Link
                                  color="black"
                                  onClick={() => handleClickOpen(getValues('patentDocumentUrl'))}
                                >
                                  <PreviewIcon sx={{ mr: 1 }} />
                                  View Document
                                </Link>
                              </Fab>
                            </div>
                          </div>
                        </div>

                      ) : (
                        <div className="form-group ">
                          <Controller
                            name="patentDocument"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: getValues('patented') === 'Yes' || getValues('patented') === 'Granted' || getValues('patented') === 'Applied' ? true : false
                            }}
                            render={({ field }) => (
                              <MuiFileInput
                                {...field}
                                onChange={(e) =>
                                  handleFileUpload(
                                    e,
                                    "patentDocument",
                                    "patentDocumentName",
                                    "patentDocumentUrl"
                                  )
                                }
                                value={getValues('patentDocumentName')}
                                variant="outlined"
                                fullWidth
                                label="Upload your IP related document / Application."
                                placeholder="Choose File"
                              />
                            )}
                          />
                          <FormHelperText className="text-danger">
                            {errors.patentDocument && "IP related document / Application is required"}
                          </FormHelperText>
                          <div>
                            <span className="fn-12">
                              Maximum 5 mb allowed doc (png, jpg, jpeg, pdf){" "}
                              <span className="text-danger">*</span>
                            </span>
                          </div>
                        </div>
                      )}
                    </div> */}

                  {/* <div className="col-lg-6 col-12"></div> */}
                  <div className="col-lg-6 col-12">
                    <div className="form-group">
                      <FormLabel>
                        Do you hold any Patent Design / trademark / copyright? *
                      </FormLabel>
                      <Controller
                        name="patented"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Required" }}
                        render={({ field }) => (
                          <RadioGroup
                            row
                            name="position"
                            {...field}
                            onChange={(e) => handlePatented(e.target.value)}
                          >
                            <FormControlLabel
                              disabled={readyOnlyBDDValue}
                              value={"Yes"}
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              disabled={readyOnlyBDDValue}
                              value={"No"}
                              control={<Radio />}
                              label="No"
                            />
                            {/* <FormControlLabel disabled={readyOnlyValue} value={'Granted'} control={<Radio />} label="Granted" />
                              <FormControlLabel disabled={readyOnlyValue} value={'Applied'} control={<Radio />} label="Applied" />
                              <FormControlLabel disabled={readyOnlyValue} value={'Process of Applying'} control={<Radio />} label="Process of Applying" /> */}
                          </RadioGroup>
                        )}
                      />
                      <FormHelperText className="text-danger">
                        {errors.patented && errors.patented.message}
                      </FormHelperText>
                    </div>
                  </div>

                  {/* {
                      (getValues('patented') === 'Yes') && (
                        <>
                          <div className='col-lg-6 col-12'>
                            <div className="d-flex align-items-center">
                              <div className='form-group mt-4'>
                                <Controller name={`patents.${index}.docname`} control={control} defaultValue="" rules={{ required: 'Patents Documents is required' }}
                                  render={({ field }) =>
                                    <FileUploader
                                      {...field}
                                      handleChange={handleFileChangePatent(index)}
                                      value={getValues(`patents.${index}.docUrl`)}
                                      name="file"
                                      types={fileTypes}
                                      children={
                                        <div className={uploadedPatents[index] ? 'fileupload-pdf-dpiit-bg-active' : 'fileupload-pdf-dpiit-bg'}>
                                          <center>
                                            <p className={uploadedPatents[index] ? 'fileupload-pdf-active' : 'fileupload-pdf'}>
                                              <span>
                                                <AttachFileIcon className="file-icon mr-2" />
                                                {uploadedPatents[index] ? 'Uploaded (Click to Change) ' : 'Patents Documents'}
                                              </span>
                                            </p>
                                          </center>
                                        </div>
                                      }
                                    />
                                  } />
                                <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.pitchDeck && errors.pitchDeck.message}</FormHelperText>
                              </div>
                              <span className="pre-btn-dpiit mt-4"><VisibilityIcon className='icon-size mr-1' />
                                <Link
                                  onClick={() => handleClickOpen(getValues(`patents.${index}.docUrl`))}
                                  style={{ color: "#80809e" }}
                                > Preview
                                </Link>
                              </span>
                            </div>
                            <FormHelperText className="text-danger">
                              {errors.patents?.[index]?.docname && errors.patents?.[index]?.docname.message}
                            </FormHelperText>
                          </div>
                        </>
                      )
                    } */}

                  {getValues("patented") === "Yes" && (
                    <>
                      <Accordion className="Addfundacc-bor">
                        <AccordionSummary
                          aria-controls="panel1-content"
                          id="panel1-header"
                          className="Addfundacc"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              margin: 0,
                            }}
                          >
                            <AddCircleIcon className="add-icon mr-3" /> Add your
                            Patent Design / trademark / copyright
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className="col-lg-12 col-12">
                              {/* <div className='div_header'>
                              <div className='d-flex'>
                                <h6>Add your patent / trademark / copyright</h6>
                                <Button variant="outlined" disabled={readyOnlyValue} className='ml-auto' onClick={() => addPatentsDetailsBtn('')}>Add</Button>
                              </div>
                            </div> */}
                              <TableContainer
                                component={Paper}
                                className="table-back"
                                sx={{ overflowX: "auto", maxWidth: "100%" }}
                              >
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="caption table"
                                >
                                  <TableBody>
                                    {patentsFields.map((field, index, type) => (
                                      <TableRow key={index}>
                                        <TableCell
                                          sx={{ borderBottom: "none" }}
                                        >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <InputLabel id="dropdown-label">
                                              Choose *
                                            </InputLabel>
                                            <Controller
                                              name={`patents.${index}.name`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required: "Name is required",
                                              }}
                                              render={({ field }) => (
                                                <Select
                                                  {...field}
                                                  variant="outlined"
                                                  disabled={readyOnlyBDDValue}
                                                  onChange={(e) => {
                                                    handleSelectChange(e);
                                                    field.onChange(e);
                                                  }}
                                                >
                                                  <MenuItem value={"Patent"}>
                                                    Patent
                                                  </MenuItem>
                                                  <MenuItem value={"Trademark"}>
                                                    Trademark
                                                  </MenuItem>
                                                  <MenuItem value={"Copyright"}>
                                                    Copyright
                                                  </MenuItem>
                                                </Select>
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.patents?.[index]?.name &&
                                                errors.patents?.[index]?.name
                                                  .message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "20%",
                                            borderBottom: "none",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <InputLabel id="dropdown-label">
                                              Stage *
                                            </InputLabel>
                                            <Controller
                                              name={`patents.${index}.stage`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required: "Name is required",
                                              }}
                                              render={({ field }) => (
                                                <Select
                                                  {...field}
                                                  variant="outlined"
                                                  disabled={readyOnlyBDDValue}
                                                >
                                                  {/* <MenuItem value={'0'}>No</MenuItem> */}
                                                  <MenuItem value={"0"}>
                                                    Process of Applying
                                                  </MenuItem>
                                                  <MenuItem value={"1"}>
                                                    Applied
                                                  </MenuItem>
                                                  <MenuItem value={"2"}>
                                                    Granted
                                                  </MenuItem>
                                                  <MenuItem value={"3"}>
                                                    Yes
                                                  </MenuItem>
                                                </Select>
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.patents?.[index]?.stage &&
                                                errors.patents?.[index]?.stage
                                                  .message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "25%",
                                            borderBottom: "none",
                                          }}
                                        >
                                          <FormControl sx={{ width: "100%" }}>
                                            <Controller
                                              name={`patents.${index}.number`}
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required:
                                                  "Description is required",
                                              }}
                                              render={({ field }) => (
                                                <TextField
                                                  id="outlined-multiline-static"
                                                  label="Description *"
                                                  placeholder="Enter Description"
                                                  fullwidth
                                                  {...field}
                                                  inputProps={{
                                                    readOnly: readyOnlyBDDValue,
                                                  }}
                                                />
                                              )}
                                            />
                                            <FormHelperText className="text-danger">
                                              {errors.patents?.[index]
                                                ?.number &&
                                                errors.patents?.[index]?.number
                                                  .message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            width: "25%",
                                            borderBottom: "none",
                                          }}
                                        >
                                          <FormControl>
                                            {/* {
                                                  uploadedPatents[index] && (<span>Document <span className='text-danger'>*</span></span>)
                                                } */}
                                            <div className="patents-docst">
                                              <div className="form-group mb-1">
                                                <Controller
                                                  name={`patents.${index}.doc`}
                                                  control={control}
                                                  defaultValue=""
                                                  rules={{
                                                    required:
                                                      "Patents Documents is required",
                                                  }}
                                                  render={({ field }) => (
                                                    <FileUploader
                                                      {...field}
                                                      handleChange={handleFileChangePatent(
                                                        index
                                                      )}
                                                      value={getValues(
                                                        `patents.${index}.doc`
                                                      )}
                                                      name="file"
                                                      types={fileTypes}
                                                      children={
                                                        <div
                                                          className={
                                                            getValues(
                                                              `patents.${index}.doc`
                                                            ) !== ""
                                                              ? "fileupload-patent-active"
                                                              : "fileupload-pdf-dpiit-bg-table"
                                                          }
                                                        >
                                                          <center>
                                                            <p
                                                              className={
                                                                getValues(
                                                                  `patents.${index}.doc`
                                                                ) !== ""
                                                                  ? "fileupload-pdf-active"
                                                                  : "fileupload-pdf"
                                                              }
                                                            >
                                                              <span>
                                                                <AttachFileIcon className="file-icon mr-2" />
                                                                {getValues(
                                                                  `patents.${index}.doc`
                                                                ) !== ""
                                                                  ? "Uploaded (Click to Change) "
                                                                  : "Document *"}
                                                              </span>
                                                            </p>
                                                          </center>
                                                        </div>
                                                      }
                                                      disabled={
                                                        readyOnlyBDDValue
                                                      }
                                                    />
                                                  )}
                                                />
                                                <FormHelperText className="ml-2">
                                                  Maximum size: 5 MB. File
                                                  format .pdf
                                                </FormHelperText>
                                                <FormHelperText className="text-danger">
                                                  {errors.patents?.[index]
                                                    ?.doc &&
                                                    errors.patents?.[index]?.doc
                                                      .message}
                                                </FormHelperText>
                                                {/* <FormHelperText sx={{ marginLeft: '5px' }} className='text-danger'>{errors.pitchDeck && errors.pitchDeck.message}</FormHelperText> */}
                                              </div>
                                              <span
                                                className="pre-btn-dpiit"
                                                onClick={() =>
                                                  handleClickOpen(
                                                    getValues(
                                                      `patents.${index}.docUrl`
                                                    )
                                                  )
                                                }
                                              >
                                                <VisibilityIcon className="icon-size mr-1" />
                                                <Link
                                                  style={{ color: "#80809e" }}
                                                >
                                                  {" "}
                                                  Preview
                                                </Link>
                                              </span>
                                            </div>
                                            <FormHelperText className="text-danger">
                                              {errors.patents?.[index]
                                                ?.docname &&
                                                errors.patents?.[index]?.docname
                                                  .message}
                                            </FormHelperText>
                                          </FormControl>
                                        </TableCell>
                                        <TableCell
                                          sx={{ border: "none" }}
                                          align="center"
                                        >
                                          <DeleteIcon
                                            style={{
                                              fill: "#253b80",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              readyOnlyBDDValue === false ? (
                                                deletePatentsDetails(index)
                                              ) : (
                                                <></>
                                              );
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                                <center>
                                  <Button
                                    className="add-more-btn"
                                    disabled={readyOnlyBDDValue}
                                    onClick={() => addPatentsDetailsBtn("")}
                                  >
                                    <AddCircleIcon className="mr-2" />
                                    Add More
                                  </Button>
                                </center>
                              </TableContainer>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="form-group text-right mt-3">
              <Button
                variant="contained"
                onClick={handleBackapp}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyBDDValue === false ? (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </LoadingButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
      </section>
      <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
    </>
  );
};
export default Incubationaccprogramscst;
