import React, { useContext, useState } from 'react';
import { Autocomplete, Checkbox, Container, FormHelperText, TextField } from '@mui/material'
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
// import SaveIcon from '@mui/icons-material/Save';
import { Controller, useForm } from 'react-hook-form';
import apiService from '../../../../api/apiService';
import notifyService from '../../../../api/notifySerivce';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DataContext from '../../../../context/DataContext';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const Startround = ({ handleStartRoundClose, checkedList }) => {
    const { setPageLoading } = useContext(DataContext)
    const { handleSubmit, control, setValue, getValues, clearErrors, setError, reset, formState: { errors } } = useForm({});
    const [loading, setLoading] = useState(false);
    // const [associate, setAssociate] = useState(true)

    useDidMountEffect(() => {
        dropdownValueApi()
    }, [])
    const [sectorData, setSectorData] = useState([])
    const dropdownValueApi = async () => {
        await apiService(`admin/dropdownvalues/get?key=investor`, '', 'get').then((res) => {
            var val = res.data.dropDownValues
            setSectorData(val.sectors)
            // setjuryList(val.tractionsTypes)
        })
    }
    const [preferredSectors, setPreferredSectors] = useState([]);
    const handleSelectSector = (value) => {
        setPreferredSectors([])
        // if (value.length === 0) {
        //     setError('sectors', { message: 'Sector is required' });
        //     return;
        // }
        if (value[value.length - 1] === 'Select All') {
            var dataa = sectorData.filter((item) => item !== 'Remove All' && item !== 'Select All')
            setPreferredSectors(dataa);
            setValue('sectors', dataa)
            clearErrors('sectors');
        } else if (value[value.length - 1] === 'Remove All') {
            setPreferredSectors([]);
            setValue('sectors', [])
        } else {
            setPreferredSectors(value);
            setValue('sectors', value)
            clearErrors('sectors');
        }
    };

    const [juryIdValue, setJuryIdValue] = useState([])


    const handleSelectJury = (value) => {

        setJuryIdValue(value)
        // const juryname = juryList.filter((item) => item.name === value)
        // 
        setValue('juryId', value)
        clearErrors('juryId');
    }
    const [showJury, setShowJury] = useState(false)
    const [juryList, setjuryList] = useState()
    const handleFatchJury = () => {
        setPageLoading(true)
        setjuryList([])
        var data = getValues()
        let val
        debugger
        if (data.sectors !== '') {
            val = data.sectors
        } else {
            val = []
        }
        apiService(`sector/jury/name/list`, val, 'post').then((res) => {
            setPageLoading(false);
            if (res) {
                if (res.data) {
                    if (res.data.responseModelList) {
                        // setSectorData([])
                        // reset()
                        setShowJury(true)
                        setjuryList(res.data.responseModelList)
                    }
                }

            }
        })

    }
    const onSubmit = (data) => {
        setPageLoading(true)
        let ids = []
        data.juryId.forEach((name, k) => {
            const juryname = juryList.filter((item) => item.name === name)
            ids.push(juryname[0].id)
        });
        data.juryId = ids
        var req = {
            "startupId": checkedList,
            "juryId": data.juryId
        }
        // debugger
        apiService(`partner/startups/startround`, req, 'post').then((res) => {
            setPageLoading(false);
            if (res) {
                if (res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', '')
                    handleCancel()
                }
            }
        })
    }
    const handleCancel = () => {
        reset({})
        handleStartRoundClose()
    }
    return (
        <section className='p-3'>
            <Container>
                <div className='header_tag text-center'>
                    <h5 className='c-black'>Start Round</h5>
                </div>
                <div className=''>
                    <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-lg-7 col-12 mb-3'>
                                        <div className="form-group">
                                            <Controller name="sectors" control={control} defaultValue="" rules={{ required: false }}
                                                render={({ field }) =>
                                                    <Autocomplete
                                                        disableClearable
                                                        disablePortal
                                                        multiple
                                                        {...field}
                                                        limitTags={2}
                                                        value={preferredSectors}
                                                        options={sectorData}
                                                        onChange={(e, selectedOptions) => handleSelectSector(selectedOptions)}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li {...props}>
                                                                <Checkbox
                                                                    icon={icon}
                                                                    checkedIcon={checkedIcon}
                                                                    style={{ marginRight: 8 }}
                                                                    checked={selected}
                                                                />
                                                                {option}
                                                            </li>
                                                        )}
                                                        renderInput={(params) => <TextField {...params} label="Sectors" />}
                                                    />
                                                }
                                            />
                                            <FormHelperText className='text-danger'>{errors.sectors && errors.sectors.message}</FormHelperText>
                                        </div>
                                    </div>
                                    <div className='col-lg-5 col-12 mb-3 text-center'>
                                        <LoadingButton
                                            variant="contained"
                                            className="btn btn-primary submit px-3"
                                            onClick={() => handleFatchJury()}
                                        >
                                            <span>Fetch Jury</span>
                                        </LoadingButton>
                                    </div>
                                </div>
                            </div>

                            {
                                showJury === true && (
                                    <div className='col-lg-12 col-12 mb-3'>
                                        <div className="form-group mb-3">
                                            <Controller name="juryId" control={control} defaultValue="" rules={{ required: 'Jury Name is required' }}
                                                render={({ field }) =>
                                                    <Autocomplete
                                                        disableClearable
                                                        disablePortal
                                                        multiple
                                                        {...field}
                                                        limitTags={2}
                                                        value={juryIdValue}
                                                        // options={juryList}
                                                        options={juryList.map(
                                                            (list) => list.name
                                                        )}
                                                        onChange={(e, selectedOptions) => handleSelectJury(selectedOptions)}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li {...props}>
                                                                <Checkbox
                                                                    icon={icon}
                                                                    checkedIcon={checkedIcon}
                                                                    style={{ marginRight: 8 }}
                                                                    checked={selected}
                                                                />
                                                                {option}
                                                            </li>
                                                        )}
                                                        renderInput={(params) => <TextField {...params} label="Jurys *" />}
                                                    />
                                                }
                                            />
                                            <FormHelperText className='text-danger'>{errors.juryId && errors.juryId.message}</FormHelperText>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="form-group text-center">
                            <LoadingButton
                                type="submit"
                                loading={loading}
                                loadingPosition="start"
                                // disabled={juryIdValue}
                                variant="contained"
                                className="btn btn-primary submit px-3"
                            >
                                <span>Start Round</span>
                            </LoadingButton>
                            <Button variant="contained" onClick={handleCancel} className="btn btn-cancel submit px-3 ml-3">Cancel</Button>

                        </div>
                    </form>
                </div>
            </Container>
        </section>
    )
}

export default Startround