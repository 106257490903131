import React, { useContext, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  Dialog,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import apiService from "../../../api/apiService";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Link, useParams } from "react-router-dom";
import CustomLink from "../../common-page/CustomLink";
import PanalRoundJuryList from "./PanalRoundJuryList";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import downloadExcel from "../../../hooks/downloadExcel";
import CancelIcon from "@mui/icons-material/Cancel";

import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
const SearchinputDiv = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  color: "black",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "17ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
const Partnerslist = () => {
  const { id } = useParams();
  let ids = atob(id);
  const {
    navigator,
    tableIndexValue,
    setPageLoading,
    icon,
    checkedIcon,
    tanseedPartnerRemoveFilter,
  } = useContext(DataContext);
  const [count, setCount] = useState(0);

  var pageNumberPartners = Number(localStorage.getItem("pageNumberPartners"));
  const [page, setPage] = useState(pageNumberPartners ? pageNumberPartners : 0);

  var listSizePartners = Number(localStorage.getItem("listSizePartners"));
  const [rowsPerPage, setRowsPerPage] = useState(
    listSizePartners ? listSizePartners : 10
  );

  var roundPartners = Number(localStorage.getItem("roundPartners"));
  const [round, setRound] = useState(roundPartners ? roundPartners : 1);

  var sectors = JSON.parse(localStorage.getItem("sectorsPartners"));
  const [preferredSectors, setPreferredSectors] = useState(
    sectors ? sectors : []
  );

  var startupCategory = localStorage.getItem("startupCategoryPartners");
  const [startupCategoryValue, setStartupCategoryValue] = useState(
    startupCategory ? startupCategory : ""
  );

  var searchInputValuePartners = localStorage.getItem(
    "searchInputValuePartners"
  );
  const [searchInputValue, setSearchInputValue] = useState(
    searchInputValuePartners ? searchInputValuePartners : ""
  );

  const [listData, setListData] = useState([]);
  const [startupCategoryData, setStartupCategoryData] = useState([]);
  const [sectorData, setSectorData] = useState([]);
  const [rounds, setRounds] = useState("");
  const [panelId, setPanelId] = useState();
  var sortRankPartners = localStorage.getItem("sortRankPartners");
  const [sortRank, setSortRank] = useState(
    sortRankPartners ? sortRankPartners : ""
  );
  useDidMountEffect(() => {
    listApi();
  }, [
    page,
    rowsPerPage,
    round,
    startupCategoryValue,
    preferredSectors,
    searchInputValue,
    sortRank,
  ]);
  useDidMountEffect(() => {
    dropdownValueApi();
  }, []);
  const handleSearch = (searchValue) => {
    setSearchInputValue(searchValue);
    localStorage.setItem("searchInputValuePartners", searchValue);
  };
  const listApi = () => {
    setPageLoading(true);
    setPanelId();
    setListData([]);
    setRounds("");
    var req = {
      round: round,
      listSize: rowsPerPage,
      pageNumber: page + 1,
      investorId: ids,
      startupCategory: startupCategoryValue,
      sectors: preferredSectors,
      searchString: searchInputValue,
      sort: sortRank,
    };
    apiService("admin/tanseed/startups/round/list", req, "post")
      .then((result) => {
        setPageLoading(false);
        setListData([]);
        if (result) {
          if (result.data.startups) {
            setPanelId(result.data.panelRoundId);
            setListData(result.data.startups);
            setCount(result.data.count);
            setRounds(result.data.round);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChangeround = (event) => {
    setRound(event.target.value);
    localStorage.setItem("roundPartners", event.target.value);
    localStorage.setItem("pageNumberPartners", 0);
    setPage(0);
  };
  const handleClear = () => {
    setRound(1);
    setPreferredSectors([]);
    setStartupCategoryValue("");
    setPage(0);
    tanseedPartnerRemoveFilter();
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem("pageNumberPartners", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("listSizePartners", parseInt(event.target.value, 10));
    localStorage.setItem("pageNumberPartners", 0);
    setPage(0);
  };
  const handleBackapp = () => {
    navigator(`tanseed/partner`);
    tanseedPartnerRemoveFilter();
  };
  const [openView, setOpenView] = useState(false);
  const [juryList, setJuryList] = useState([]);
  const handleViewOpen = (value) => {
    setOpenView(true);
    if (value) {
      setJuryList(value);
    } else {
      setJuryList([]);
    }
  };
  const handleViewClose = () => {
    setOpenView(false);
  };
  const handleSelectStartupCategory = (value) => {
    setStartupCategoryValue(value);
    localStorage.setItem("startupCategoryPartners", value);
    localStorage.setItem("pageNumberPartners", 0);
    setPage(0);
  };

  const handleSelectSector = (value) => {
    setPreferredSectors(value);
    localStorage.setItem("sectorsPartners", JSON.stringify(value));
    localStorage.setItem("pageNumberPartners", 0);
    setPage(0);
  };
  const dropdownValueApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=startup_min`, "", "get")
      .then((res) => {
        var val = res.data.dropDownValues;
        setSectorData(val.sectors);

        setStartupCategoryData(val.startupCategory);
      })
      .catch((error) => {
        console.error("Error fetching dropdown values:", error);
        // Handle error if needed
      });
  };
  const exportExcel = () => {
    setPageLoading(true);
    var req = {
      download: "Yes",
      round: round,
      listSize: rowsPerPage,
      pageNumber: page + 1,
      investorId: ids,
      startupCategory: startupCategoryValue,
      sectors: preferredSectors,
      sort: sortRank,
    };
    apiService("admin/tanseed/startups/round/list", req, "downloadpost")
      .then((res) => {
        setPageLoading(false);
        if (res) {
          if (res.data) {
            downloadExcel(res.data, "Tanseed_Startup_");
          }
        }
      })
      .catch((err) => {});
  };

  const [category, setCategory] = useState([]);
  const [openWeightedScore, setOpenWeightedScore] = React.useState(false);
  const handleWeightedscore = (id) => {
    console.log(id, "ids");
    setCategory([])
    setOpenWeightedScore(true);
    setPageLoading(true);
    var req = {
      panelroundId: panelId,
      startupId: id,
    };
    apiService("partner/jury/weightedscore", req, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data.category) {
          setCategory(res.data.category);
        }
      }
    });
  };
  const handleWeightedscoreClose = () => {
    setOpenWeightedScore(false);
  };
  const handleSortRank = (value) => {
    // console.log(value, "rank")
    setSortRank(value);
    localStorage.setItem("sortRankPartners", value);
  };
  return (
    <section className="">
      <div className="container-fluid">
        <div className="form_div chart">
          <div className="header_tag ">
            <h5 className="d-flex align-item-center">
              {" "}
              <span className="back_span mr-3" onClick={handleBackapp}>
                <i className="fas fa-arrow-alt-circle-left"></i>
              </span>
              TANSEED Startups
            </h5>
            <div className="ml-3 my-2">
              <Button
                variant="outlined"
                type="button"
                data-toggle="collapse"
                data-target="#collapseWidthExample"
                className="mr-3"
              >
                {" "}
                <FilterAltIcon />
                Filters
              </Button>
              {/* <Button
                startIcon={<CloudDownloadIcon />}
                variant="outlined"
                onClick={exportExcel}
              >
                <span>EXPORT EXCEL</span>
              </Button> */}
            </div>
          </div>
          <div className="collapse show mb-4" id="collapseWidthExample">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-3">
                <div className="form-group mt-2">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">Round</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={round}
                      label="Round"
                      onChange={handleChangeround}
                    >
                      <MenuItem value={1}>Round 1 Completed</MenuItem>
                      <MenuItem value={2}>Round 2 Completed</MenuItem>
                      <MenuItem value={3}>Round 3 Completed</MenuItem>
                      <MenuItem value={4}>Round 1 Not Promoted</MenuItem>
                      <MenuItem value={5}>Round 2 Not Promoted</MenuItem>
                      <MenuItem value={6}>Round 3 Not Promoted</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mt-2">
                  <FormControl sx={{ width: "100%" }}>
                    {/* <InputLabel id="demo-simple-select-label">Category</InputLabel> */}
                    <Autocomplete
                      disableClearable
                      disablePortal
                      options={startupCategoryData}
                      value={startupCategoryValue}
                      onChange={(e, selectedOptions) =>
                        handleSelectStartupCategory(selectedOptions)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Category" />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mt-2">
                  <FormControl sx={{ width: "100%" }}>
                    {/* <InputLabel id="demo-simple-select-label">Sectors</InputLabel> */}
                    <Autocomplete
                      disableClearable
                      disablePortal
                      multiple
                      limitTags={2}
                      value={preferredSectors}
                      options={sectorData}
                      onChange={(e, selectedOptions) =>
                        handleSelectSector(selectedOptions)
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Sectors" />
                      )}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                    />
                    <FormHelperText className="fnt-sm c-blue">
                      Select one or more
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
              {round !== 1 && round !== 4 && (
                <div className="col-lg-3">
                  <div className="form-group mt-2">
                    <FormControl>
                      <InputLabel id="demo-simple-select-filled-label">
                        Sort
                      </InputLabel>
                      <Select
                        sx={{ width: "280px" }}
                        onChange={(e) => handleSortRank(e.target.value)}
                        value={sortRank}
                      >
                        <MenuItem value={0}>Sort by Weighted Rank</MenuItem>
                        <MenuItem value={1}>Sort by Absolute Rank</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
            </div>
            <center>
              <Button
                variant="contained"
                sx={{ marginRight: "10px" }}
                onClick={() => handleClear()}
                // data-toggle="collapse"
                // data-target="#collapseWidthExample"
              >
                Clear
              </Button>
              <Button
                startIcon={<CloudDownloadIcon />}
                variant="outlined"
                onClick={exportExcel}
              >
                <span>EXPORT EXCEL</span>
              </Button>
            </center>
          </div>
          <div className="d-flex mb-3">
            <SearchinputDiv
              sx={{ display: { xs: "none", sm: "block" } }}
              onChange={(e) => handleSearch(e.target.value)}
            >
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                value={searchInputValue}
                type="search"
                placeholder="Search with Name"
                inputProps={{ "aria-label": "search" }}
              />
            </SearchinputDiv>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <div className="table_tag">
              {listData.length ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell>No.</TableCell>
                        <TableCell>Startup Name</TableCell>
                        {/* <TableCell className="text-center">Assigned Juries</TableCell> */}

                        {/* <TableCell>Current Round</TableCell> */}
                        <TableCell>Category</TableCell>
                        <TableCell>Sector</TableCell>
                        <TableCell className="text-center">
                          No. of Juries Reviewed
                        </TableCell>
                        {/* {
                          rounds !== 1 && rounds !== 4 && (
                            <TableCell align='center'>Weighted Mean</TableCell>
                          )
                        } */}

                        {rounds !== 1 && rounds !== 4 && (
                          <>
                            <TableCell className="text-center">
                              Weighted Average
                            </TableCell>
                            <TableCell className="text-center">
                              Weighted Rank
                            </TableCell>
                            <TableCell align="center">
                              Absolute Average
                            </TableCell>
                            <TableCell align="center">Absolute Rank</TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listData.map((list, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {tableIndexValue(rowsPerPage, page, index)}
                          </TableCell>
                          <TableCell>
                            <CustomLink
                              to={`/tanseed/partner/details/${btoa(
                                list.startupId
                              )}/${btoa(ids)}/${btoa(
                                list.partnerOrInvestorId
                              )}`}
                            >
                              {list.startupName}
                            </CustomLink>
                            {/* {list.startupName} */}
                          </TableCell>
                          {/* <TableCell className="text-center">
                                {list.totalJury}
                              </TableCell> */}

                          {/* <TableCell >
                                {list.currentRound}
                              </TableCell> */}
                          <TableCell>{list.category}</TableCell>
                          <TableCell>{list.sector}</TableCell>
                          <TableCell className="text-center c-pointer">
                            {list.reviewed}
                            {/* <span className="c-blue" onClick={() => handleViewOpen(list.jury)}> <b>{list.reviewed}</b></span> */}
                          </TableCell>
                          {/* {
                            rounds !== 1 && rounds !== 4 && (
                              <TableCell align='center'>{list.weightedMean}</TableCell>
                            )
                          } */}

                          {rounds !== 1 && rounds !== 4 && (
                            <>
                              <TableCell className="text-center c-pointer">
                                <span
                                  className="c-blue"
                                  onClick={() => handleViewOpen(list.jury)}
                                >
                                  {" "}
                                  <b>{list.score}</b>
                                </span>
                              </TableCell>
                              <TableCell className="text-center">
                                {list.rank}
                              </TableCell>
                              <TableCell
                                align="center"
                                onClick={() =>
                                  handleWeightedscore(list.startupId)
                                }
                              >
                                <Link className="c-blue">
                                  <b>{list.weightedScore}</b>
                                </Link>{" "}
                                <br />
                                <span
                                  style={{
                                    color:
                                      list.categoryJuries === 6
                                        ? "#388e3c"
                                        : "#F55A00",
                                  }}
                                >
                                  {list.categoryJuries > 0 && (
                                    <>
                                      {" "}
                                      (<strong>
                                        {list.categoryJuries}
                                      </strong>{" "}
                                      Category Jury Filled)
                                    </>
                                  )}
                                </span>
                              </TableCell>
                              <TableCell align="center">
                                {list.weightedRank}
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                    <caption>
                      <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        style={{ float: "left" }}
                      />
                    </caption>
                  </Table>
                </TableContainer>
              ) : (
                <div className="nodata_tag">
                  <img
                    src="/images/Tanfundlogo-black-1.png"
                    className="no_data_logo"
                    alt=""
                  />
                  <h4>
                    A Startups will be assigned as soon as the criteria matches
                  </h4>
                </div>
              )}
            </div>
          </Box>
        </div>
      </div>
      <Dialog open={openView} maxWidth="md">
        <PanalRoundJuryList
          juryList={juryList}
          handleViewClose={handleViewClose}
        />
      </Dialog>

      <Dialog
        open={openWeightedScore}
        onClose={handleWeightedscoreClose}
        minWidth={"lg"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {category.length && category !== null ? (
          <>
            <div className="mt-3 close-icon">
              <span onClick={handleWeightedscoreClose}>
                <CancelIcon />
              </span>
            </div>
            <TableContainer component={Paper} className="p-3">
              <Table aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell>Category</TableCell>
                    <TableCell>Juries</TableCell>
                    <TableCell>Absolute Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {category && category !== null &&
                    category.map((list, index) => (
                      <TableRow key={index}>
                        <TableCell>{list.name}</TableCell>
                        <TableCell>{list.juries}</TableCell>
                        <TableCell>{list.weightedScore}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <>
            <div className="my-3 close-icon">
              <span onClick={handleWeightedscoreClose}>
                <CancelIcon />
              </span>
            </div>
            <div className="nodata_tag">
              <img
                src="/images/Tanfundlogo-black-1.png"
                className="no_data_logo"
                alt=""
              />
              <h4>No Data Found</h4>
            </div>
          </>
        )}
      </Dialog>
    </section>
  );
};
export default Partnerslist;
