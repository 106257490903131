import React, { useContext, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import apiService from "../../../api/apiService";
import TablePagination from "@mui/material/TablePagination";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import CustomLink from "../../common-page/CustomLink";
import OnBoradFilter from "../../scsthubavp-module/onboraded-details/OnBoradFilter";
const SearchinputDiv = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  color: "black",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "17ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
const Juryassociatedlist = ({ path, value }) => {
  const { tableIndexValue, setPageLoading } = useContext(DataContext);

  const [count, setCount] = useState(0);

  var pageNumberPartners = Number(localStorage.getItem("pageScst"));
  const [page, setPage] = useState(pageNumberPartners ? pageNumberPartners : 0);

  var listSizePartners = Number(localStorage.getItem("rowsPerPageScst"));
  const [rowsPerPage, setRowsPerPage] = useState(
    listSizePartners ? listSizePartners : 10
  );
  const juryID = localStorage.getItem("userId");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem("pageScst", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPageScst", parseInt(event.target.value, 10));
    localStorage.setItem("pageScst", 0);
    setPage(0);
  };

  var districtNameScst = localStorage.getItem("districtNameScst");
  const [districtName, setDistrictName] = useState(
    districtNameScst ? districtNameScst : "All"
  );

  var hubScst = localStorage.getItem("hubScst");
  const [scsthub, setScsthub] = useState(hubScst ? hubScst : "All");

  var yearNameScst = Number(localStorage.getItem("yearNameScst"));
  const [yearName, setYearName] = useState(yearNameScst ? yearNameScst : "All");

  var toYearNameScst = Number(localStorage.getItem("toYearNameScst"));
  const [toYearName, setToYearName] = useState(
    toYearNameScst ? toYearNameScst : "All"
  );

  var monthNameScst = Number(localStorage.getItem("monthNameScst"));
  const [monthName, setMonthName] = useState(monthNameScst ? monthNameScst : 0);

  var toMonthNameScst = Number(localStorage.getItem("toMonthNameScst"));
  const [toMonthName, setToMonthName] = useState(
    toMonthNameScst ? toMonthNameScst : 0
  );

  var manualNameScst = Number(localStorage.getItem("manualNameScst"));
  const [manualName, setManualName] = useState(
    manualNameScst ? manualNameScst : 0
  );

  var searchInputValueScst = localStorage.getItem("searchInputValueScst");
  const [searchInputValue, setSearchInputValue] = useState(
    searchInputValueScst ? searchInputValueScst : ""
  );

  var phaseValueScst = Number(localStorage.getItem("phaseValueScst"));
  const [phaseValue, setPhaseValue] = useState(
    phaseValueScst ? phaseValueScst : "Evaluation"
  );

  var statusValueScst = Number(localStorage.getItem("statusValueScst"));
  const [statusValue, setStatusValue] = useState(
    statusValueScst ? statusValueScst : 52
  );
  useDidMountEffect(() => {
    setSearchInputValue("");
  }, []);
  useDidMountEffect(() => {
    listApi();
    // eslint-disable-next-line
  }, [
    page,
    rowsPerPage,
    districtName,
    searchInputValue,
    yearName,
    toYearName,
    monthName,
    toMonthName,
    manualName,
    phaseValue,
    statusValue,
  ]);

  const handleSearch = (searchValue) => {
    setSearchInputValue(searchValue);
  };
  const [startupListData, setStartupListData] = useState([]);
  const listApi = async () => {
    setStartupListData([]);
    setPageLoading(true);
    var req;
    let url;
    if (value === "scst") {
      url = "partner/scst/admin/startups/list";
      req = {
        juryID: juryID,
        listSize: rowsPerPage,
        pageNumber: page + 1,
        fund: value,
        district: districtName,
        searchString: searchInputValue,
        onboardType: manualName,
        status: statusValue,
      };
    } else {
      url = "jury/startup/list";
      req = {
        listSize: rowsPerPage,
        pageNumber: page + 1,
        fund: value,
        district: districtName,
        searchString: searchInputValue,
        fromYear: yearName === "All" ? 0 : yearName,
        toYear: toYearName === "All" ? 0 : toYearName,
        fromMonth: monthName,
        toMonthName: toMonthName,
        onboardType: manualName,
        status: statusValue,
      };
    }

    apiService(url, req, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        setCount(res.data.count);
        if (res.data.startups) {
          setStartupListData(res.data.startups);
        }
      }
    });
  };

  return (
    <section className="">
      <div className={value !== "scst" ? "container-fluid" : ""}>
        <div className={value !== "scst" ? "form_div chart" : ""}>
          <div>
            {value === "scst" ? (
              <OnBoradFilter
                setPage={setPage}
                districtName={districtName}
                setDistrictName={setDistrictName}
                scsthub={scsthub}
                setScsthub={setScsthub}
                yearName={yearName}
                setYearName={setYearName}
                toYearName={toYearName}
                setToYearName={setToYearName}
                monthName={monthName}
                setMonthName={setMonthName}
                toMonthName={toMonthName}
                setToMonthName={setToMonthName}
                manualName={manualName}
                setManualName={setManualName}
                path={path}
                searchInputValue={searchInputValue}
                setSearchInputValue={setSearchInputValue}
                phaseValue={phaseValue}
                setPhaseValue={setPhaseValue}
                statusValue={statusValue}
                setStatusValue={setStatusValue}
                lblName="Assigned Startups"
              />
            ) : (
              <>
                <div className="header_tag">
                  <h5>Associated Startups</h5>
                  <div className="ml-auto d-flex align-items-center">
                    <SearchinputDiv
                      sx={{ display: { xs: "none", sm: "block" } }}
                      onChange={(e) => handleSearch(e.target.value)}
                    >
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search by Name"
                        type="search"
                        inputProps={{ "aria-label": "Search by Name" }}
                      />
                    </SearchinputDiv>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="table_tag">
            {startupListData.length ? (
              <TableContainer component={Paper}>
                <TablePagination
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  style={{ float: "left" }}
                />
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">No</TableCell>
                      <TableCell>Startup Name</TableCell>
                      <TableCell>
                        {value === "tanseed" ? "Partner Name" : "Hub Name"}
                      </TableCell>
                      {value === "tanseed" ? (
                        <></>
                      ) : (
                        <>
                          <TableCell>District</TableCell>
                          <TableCell>Entity</TableCell>
                          <TableCell>Onboarded Type</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell align="center">Status</TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {startupListData.map((list, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {tableIndexValue(rowsPerPage, page, index)}
                        </TableCell>

                        <TableCell>
                          <CustomLink
                            to={
                              path === "jury/scst"
                                ? `/jury/scst/startup/${btoa(
                                    list.startupId
                                  )}/${btoa(list.partnerOrInvestorId)}`
                                : `/${path}/details/${btoa(
                                    list.startupId
                                  )}/${btoa(list.partnerOrInvestorId)}`
                            }
                          >
                            {list.startupName}
                          </CustomLink>
                        </TableCell>
                        <TableCell>
                          {list.partnerName ? list.partnerName : "-"}
                        </TableCell>
                        {value === "tanseed" ? (
                          <></>
                        ) : (
                          <>
                            <TableCell>{list.district}</TableCell>
                            <TableCell>{list.entityType}</TableCell>
                            <TableCell>
                              {list.onboardType === 1 ? "Manual" : "Portal"}
                            </TableCell>
                            <TableCell>{list.date}</TableCell>
                            <TableCell align="center">{list.status}</TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                  <caption>
                    <TablePagination
                      component="div"
                      count={count}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      style={{ float: "left" }}
                    />
                  </caption>
                </Table>
              </TableContainer>
            ) : (
              <div className="nodata_tag">
                <img
                  src="/images/Tanfundlogo-black-1.png"
                  className="no_data_logo"
                  alt=""
                />
                <h4>No Records</h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Juryassociatedlist;
