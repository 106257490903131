import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import LanguageIcon from "@mui/icons-material/Language";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Viewimage from "../../../../../api/Viewimage";
import Paper from "@mui/material/Paper";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
const Appicationview = ({ details, detail }) => {
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    setViewImage(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <main>
        {/* <section>
                    <div className='row'>
                        <div className='col-lg-9 col-12'>
                            <div className='row'>
                                <div className='col-lg-6 col-12'>
                                    <div className='d-flex'>
                                        <label className='mr-3'>Company Incorporation Date :</label>
                                        <p className='ml-auto'><b>{details.incorporatedDate}</b></p>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className='d-flex'>
                                        <label className='mr-3'>Legal Entity :</label>
                                        <p className='ml-auto'><b>{details.entityType}</b></p>
                                    </div>
                                </div>
                                {
                                    details.entityTypeOther && (
                                        <div className='col-lg-6 col-12'>
                                            <div className='d-flex'>
                                                <label className='mr-3'>Type of Entity :</label>
                                                <p className='ml-auto'><b>{details.entityTypeOther}</b></p>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className='col-lg-6 col-12'>
                                    <div className='d-flex'>
                                        <label className='mr-3'>Current Growth Stage of Your Startup :</label>
                                        <p className='ml-auto'><b>{details.startupStage}</b></p>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className='d-flex'>
                                        <label className='mr-3'>Sector :</label>
                                        <p className='ml-auto'><b>{details.sector}</b></p>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className='d-flex'>
                                        <label className='mr-3'>Incorporation Certificate :</label>
                                        <div className='ml-auto'>
                                            <span className='viewdetails' onClick={() => handleClickOpen(details.inCorpCertificateName)}>View File</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className='d-flex'>
                                        <label className='mr-3'>City :</label>
                                        <p className='ml-auto'><b>{details.city}</b> </p>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className='d-flex'>
                                        <label className='mr-3'>State :</label>
                                        <p className='ml-auto'><b>{details.state}</b></p>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12'>
                                    <div className='d-flex'>
                                        <label className='mr-3'>Country :</label>
                                        <p className='ml-auto'><b>{details.country}</b></p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-3 col-12'>
                            <div className='text-center'>
                                <img src={details.startupLogoName} className='img-fluid user_logo' alt='' />
                            </div>
                            <div className='social-datas'>
                                <Stack className='social-Stack' direction="row" spacing={1}>
                                    {(details.website) && (<><Link to={`${details.website}`} target="_blank"><LanguageIcon className='websiteicon' /></Link></>)}
                                    {(details.linkedIn) && (<><Link to={`${details.linkedIn}`} target="_blank"><LinkedInIcon className='linkedinicon' /></Link></>)}
                                </Stack>
                            </div>
                        </div>
                        <div className='col-lg-12 col-12'>
                            <div className='d-flex'>
                                <label className='mr-3'>Brief Description of your Startup :</label>
                                <p className='ml-auto'><b>{details.briefStartupDescription}</b></p>
                            </div>
                        </div>
                        <div className='col-lg-12 col-12'>
                            <div className='d-flex'>
                                <label className='mr-3'>Brief about your problem statement :</label>
                                <p className='ml-auto'><b>{details.briefProblemStatement}</b></p>
                            </div>
                        </div>
                        <div className='col-lg-12 col-12'>
                            <div className='d-flex'>
                                <label className='mr-3'>Brief about your proposed solution :</label>
                                <p className='ml-auto'><b>{details.briefProposedSolution}</b></p>
                            </div>
                        </div>                        
                    </div>
                </section> */}
        <section>
          {/* <div className='rec-info1 mt-4'>
                        <span className='d-flex'><img src='/images/purple-icon.png' alt='' className='purple-star mr-3' /><b><span className='grey-clr'>Incubated from </span><span className='purple-clr'>TAMILNADU AGRICULTURE COLLEGE</span></b></span>
                    </div>
                    <div className='rec-info2 mt-3'>
                        <span className='d-flex'><img src='/images/blue-icon.png' alt='' className='purple-star mr-3' /><b><span className='blue-clr'>IAF award, Recognized by TNAU</span></b></span>
                    </div> */}
          <div className="">
            {/* <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="Application Overview" value="1" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1" className='application '>
                                    
                                </TabPanel>
                            </TabContext>
                        </Box> */}
            <h4
              className="font-weight-bold my-4"
              style={{ fontFamily: "Inter, sans-serif" }}
            >
              Personal Information
            </h4>
            <div className="row">
              <div className="col-lg-4">
                <p>
                  <b>Company Incorporation Date :</b>
                </p>
                <p>{details.incorporatedDate}</p>
              </div>
              <div className="col-lg-4">
                <p>
                  <b>Legal Entity :</b>
                </p>
                <p>{details.entityType}</p>
              </div>
              <div className="col-lg-4">
                <p className="mt-4">
                  <b>CGS of Your Startup :</b>
                </p>
                <p>{details.startupStage}</p>
              </div>
              <div className="col-lg-4">
                <p className="mt-4">
                  <b>Sector :</b>
                </p>
                <p>{details.sector}</p>
              </div>
              <div className="col-lg-4">
                <p className="mt-4">
                  <b>Incorporation Certificate :</b>
                </p>
                {details.inCorpCertificateName && (
                  <div className="ml-auto">
                    <span
                      className="viewdoc"
                      onClick={() =>
                        handleClickOpen(details.inCorpCertificateName)
                      }
                    >
                      View Document
                    </span>
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <p className="mt-4">
                  <b>City :</b>
                </p>
                <p>{details.city}</p>
              </div>
              <div className="col-lg-4">
                <p className="mt-4">
                  <b>State :</b>
                </p>
                <p>{details.state}</p>
              </div>
              <div className="col-lg-4">
                <p className="mt-4">
                  <b>Country :</b>
                </p>
                <p>{details.country}</p>
              </div>
            </div>
            <h4
              className="font-weight-bold my-4"
              style={{ fontFamily: "Inter, sans-serif" }}
            >
              Brief Description of your Startup :
            </h4>
            <p className="mt-4">{details.briefStartupDescription}</p>
            <h4
              className="font-weight-bold my-4"
              style={{ fontFamily: "Inter, sans-serif" }}
            >
              Brief about your problem statement :
            </h4>
            <p className="mt-4">{details.briefProblemStatement}</p>
            <h4
              className="font-weight-bold my-4"
              style={{ fontFamily: "Inter, sans-serif" }}
            >
              Brief about your proposed solution :
            </h4>
            <p className="mt-4">{details.briefProposedSolution}</p>
          </div>
        </section>
      </main>
      <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
    </>
  );
};

export default Appicationview;
