import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
// import DataContext from '../../../context/DataContext';
import { Dialog } from '@mui/material';

const CommentsModal = ({ remarks, lblName, openModal, setOpenModal }) => {
    debugger

    return (
        <>
            {/* {
                remarks ? (
                    <Button onClick={() => setOpenModal(true)} className='text-cap'>{lblName}</Button>
                ) : (``)
            } */}
           {(remarks.mentorRemarks || remarks.adminRemarks)&&(
            <Dialog
                open={openModal}
                onClose={() => setOpenModal(true)}
                maxWidth="md"
            >
                 {remarks.mentorRemarks && (
                <Box className="form_tag">
                    <div className='p-5'>
                        {/* <p>{remarks.mentorRemarks}</p> */}
                        {remarks && remarks.mentorRemarks.map((remark, index) => (
                            <p key={index}>{index + 1}. {remark}</p>
                        ))}
                        <div className='text-center'>
                            <Button onClick={() => setOpenModal(false)} variant="outlined">Ok</Button>
                        </div>
                    </div>
                </Box>
                )}
                  {remarks.adminRemarks && (
                <Box className="form_tag">
                    <div className='p-5'>
                        {/* <p>{remarks.mentorRemarks}</p> */}
                        {remarks && remarks.adminRemarks.map((remark, index) => (
                            <p key={index}>{index + 1}. {remark}</p>
                        ))}
                        <div className='text-center'>
                            <Button onClick={() => setOpenModal(false)} variant="outlined">Ok</Button>
                        </div>
                    </div>
                </Box>
                )}
            </Dialog>
           )}
          
            
            

        </>
    )
}

export default CommentsModal