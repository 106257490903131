import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import './scst-page/scst.css'
import { NavLink } from 'react-router-dom';
import DataContext from '../../context/DataContext';

const ScstadminNavbar = ({ role }) => {
    const { scstRemoveFiters } = useContext(DataContext);
    const handleClick = (event) => {
        if (event.ctrlKey || event.metaKey) {
            event.preventDefault();
        }
    };
    return (
        <nav>
            <div className='submenu_div'>
                <div className='container-fluid'>
                    <Box sx={{ display: 'flex', paddingTop: '5px', textAlign: 'center', marginLeft: '25px', marginRight: '25px' }}>
                        <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/${role}/dashboard`} onClick={(e) => {
                                handleClick(e);
                                scstRemoveFiters();
                            }}>Dashboard</NavLink>
                        </Typography>
                        <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/${role}/startups`} onClick={(e) => {
                                handleClick(e);
                                scstRemoveFiters();
                            }}>Startups</NavLink>
                        </Typography>
                        {/* <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/${role}/report`} onClick={handleClick}>Report</NavLink>
                        </Typography> */}
                        <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/${role}/report`} onClick={(e) => {
                                handleClick(e);
                                scstRemoveFiters();
                            }}>Report</NavLink>
                        </Typography>
                        <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/${role}/import-page`} onClick={(e) => {
                                handleClick(e);
                                scstRemoveFiters();
                            }}>Import</NavLink>
                        </Typography>
                        <Typography sx={{ minWidth: 100 }}>
                            <NavLink
                                className="nav-link"
                                to={`/${role}/approval-pending`}
                                onClick={(e) => {
                                    handleClick(e);
                                    scstRemoveFiters();
                                }}
                            >
                                Approval Pending
                            </NavLink>
                        </Typography>
                        <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/scstadmin/mentortab`} onClick={handleClick}>Mentorship Startup</NavLink>
                        </Typography>
                        {/* <Typography sx={{ minWidth: 100 }}>
                            <NavLink className="nav-link" to={`/${role}/previousmentor`} onClick={handleClick}>Previous Startup</NavLink>
                        </Typography> */}
                    </Box>
                </div>
            </div>
        </nav>
    )
}


export default ScstadminNavbar