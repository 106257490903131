import React, { useState, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import apiService from "../../api/apiService";
import { Container, Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import DataContext from "../../context/DataContext";
const tomorrow = dayjs().add(0, "day");
const ScstBddDashboard = ({ role }) => {
  const { setPageLoading } = useContext(DataContext);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [chartData, setChartData] = useState("");

  useDidMountEffect(() => {
    bdddashApi();
    // localStorage.removeItem("statusValueBDDStartup");
    // localStorage.removeItem("phaseValueBDDStartup");
    // firstChartApi()
  }, [startDate, endDate]);

  //Deepdive chart
  const [deepDive, setDeepDive] = useState({
    series: [],
    chart: {
      type: "line",
      height: 350,
      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    xaxis: {
      categories: [],
      title: {
        text: "Days Taken to Validate the Startups",
      },
    },
    yaxis: {
      title: {
        text: "Startup Count",
      },
    },
    // legend: {
    //   position: "top",
    //   offsetY: 20,
    // },
    legend: {
      position: "right",
      offsetY: 0,
      offsetX: 10,
    },
    fill: {
      opacity: 1,
    },
    // colors: ['#F44336', '#9C27B0','#E91E63']
  });

  const bdddashApi = () => {
    setPageLoading(true);
    const req = {
      fromDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
      toDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : "",
    };

    apiService("dd/dashboard", req, "post").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        setChartData(res.data);

        // Update categories dynamically
        // if (res.data.categories) {
        //   setDeepDiveData(res.data.categories);
        // }

        // Update series dynamically
        if (res.data.series) {
          setDeepDive((prevDeepDive) => ({
            ...prevDeepDive,
            series: res.data.series.map((item) => ({
              name: item.name,
              data: item.data,
            })),
            xaxis: {
              categories: res.data.categories || [],
            },
          }));
        }
      }
    });
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };
  return (
    <div className="scsthub-div form_div chart">
      <Container>
        <div className="collapse show mt-3 mb-3" id="collapseWidthExample">
          {role !== "scstbdd" ? (
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-3">
                <div className="d-flex align-items-center">
                  <img
                    src="/images/handshake.png"
                    alt=""
                    className="star_logo"
                  />
                  <h6 className="font-weight-bold ml-lg-3 ml-md-3">
                    Total Validated Startups
                  </h6>
                  <div className="star_details ml-auto text-center">
                    <h5 className="font-weight-bold">
                      {chartData.totalStartups ? chartData.totalStartups : 0}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    maxDate={endDate ? endDate : tomorrow}
                    label="Start Date"
                    format="DD/MM/YYYY"
                    inputFormat="DD/MM/YYYY"
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              {startDate && (
                <div className="col-lg-3">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      minDate={startDate && startDate}
                      maxDate={tomorrow}
                      label="End Date"
                      format="DD/MM/YYYY"
                      inputFormat="DD/MM/YYYY"
                      value={endDate}
                      onChange={handleEndDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              )}
              <div className="col-lg-3">
                <button className="down-pdf-btn" onClick={handleClear}>
                  Clear
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-lg-6">
                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      src="/images/handshake.png"
                      alt=""
                      className="star_logo"
                    />
                    <h6 className="font-weight-bold ml-lg-3 ml-md-3">
                      Total Validated Startups under First Connect -
                    </h6>
                    <div className="star_details ml-2 text-center">
                      <h5 className="font-weight-bold">
                        {chartData.firstConnectTotalStartups
                          ? chartData.firstConnectTotalStartups
                          : 0}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      src="/images/handshake.png"
                      alt=""
                      className="star_logo"
                    />
                    <h6 className="font-weight-bold ml-lg-3 ml-md-3">
                      Total Validated Startups under Deep Dive -
                    </h6>
                    <div className="star_details ml-2 text-center">
                      <h5 className="font-weight-bold">
                        {chartData.deepDiveTotalStartups
                          ? chartData.deepDiveTotalStartups
                          : 0}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-3 text-center">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        maxDate={endDate ? endDate : tomorrow}
                        label="Start Date"
                        format="DD/MM/YYYY"
                        inputFormat="DD/MM/YYYY"
                        value={startDate}
                        onChange={handleStartDateChange}
                        renderInput={(params) => <TextField {...params} />}
                        // fullWidth
                      />
                    </LocalizationProvider>
                  </div>
                  {startDate && (
                    <div className="col-lg-3 text-center">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          minDate={startDate && startDate}
                          maxDate={tomorrow}
                          label="End Date"
                          format="DD/MM/YYYY"
                          inputFormat="DD/MM/YYYY"
                          value={endDate}
                          onChange={handleEndDateChange}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  )}

                  <div className="col-lg-3">
                    <button className="down-pdf-btn" onClick={handleClear}>
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={5}
        >
          <Grid item sm={12} md={12} lg={12} xl={12}>
            <div className="total_stars">
              {/* <div className='text-center'>
                                <h3>First Connect</h3>
                            </div> */}
              <div id="chart">
                <ReactApexChart
                  options={deepDive}
                  series={deepDive.series}
                  type="line"
                  height={350}
                />
              </div>
              <div id="html-dist"></div>
              {/* <div className="text-center">
                <span>Days</span>
              </div> */}
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ScstBddDashboard;
