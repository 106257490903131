import { Box } from "@mui/material";
import { NavLink, Outlet } from "react-router-dom";
const JuryScstLayout = () => {
  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="form_div chart">
            <Box
              sx={{
                display: "flex",
                paddingTop: "5px",
                textAlign: "center",
                marginBottom: "25px",
                marginRight: "25px",
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <NavLink className="nav-link" to={"/jury/scst/approval-pending"}>
                Approval Pending
              </NavLink>
              <NavLink className="nav-link" to={"/jury/scst/startup"}>
                All Startups
              </NavLink>
            </Box>
            <Outlet></Outlet>
          </div>
        </div>
      </section>
    </>
  );
};
export default JuryScstLayout;
