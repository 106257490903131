import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import apiService from "../../../../api/apiService";
import notifyService from "../../../../api/notifySerivce";
import { Button, FormHelperText, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PreviewIcon from "@mui/icons-material/Preview";
import Fab from "@mui/material/Fab";
import { Link } from "react-router-dom";
import { MuiFileInput } from "mui-file-input";
import DataContext from "../../../../context/DataContext";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
const Tanseed7Documentsupload = () => {
  const { handleBacktanseed7, VisuallyHiddenInput, isWhitespace } =
    useContext(DataContext);
  const [formData, setFormData] = useState({});
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [loading, setLoading] = useState(false);

  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
  }, []);
  const getApi = async () => {
    await apiService(`startup/tanseed/supportingdocs/get`, "", "get").then(
      (res) => {
        if (res?.data) {
          let response = res.data;
          if (response.id !== 0) {
            setFormData({});
            setFormData(response);
            if (response.editForm === false) {
              setReadyOnlyValue(true);
            }
            if (response.pitchDeckName) {
              setPitchDeskValue(response.pitchDeckName);
              setPitchDeskName(response.pitchDeck);
              setPitchDeskURL(response.pitchDeckName);
            }
            if (response.financialProjectionsName) {
              setFinancialProjectValue(response.financialProjectionsName);
              setFinancialProjectName(response.financialProjections);
              setFinancialProjectURL(response.financialProjectionsName);
            }
            if (response.userDataName) {
              setUserDataValue(response.userDataName);
              setUserDataName(response.userData);
              setUserDataURL(response.userDataName);
            }
            if (response.analysisReportName) {
              setAnalysisReportValue(response.analysisReportName);
              setAnalysisReportName(response.analysisReport);
              setAnalysisReportURL(response.analysisReportName);
            }
            if (response.supportingDocName) {
              setSupportingDocValue(response.supportingDocName);
              setSupportingDocName(response.supportingDoc);
              setSupportingDocURL(response.supportingDocName);
            }
          } else {
            setFormData(response);
          }
        }
      }
    );
  };

  const [pitchDeskValue, setPitchDeskValue] = useState(null);
  const [pitchDeskName, setPitchDeskName] = useState(null);
  const [pitchDeskURL, setPitchDeskURL] = useState(null);

  const [financialProjectValue, setFinancialProjectValue] = useState(null);
  const [financialProjectName, setFinancialProjectName] = useState(null);
  const [financialProjectURL, setFinancialProjectURL] = useState(null);

  const [userDataValue, setUserDataValue] = useState(null);
  const [userDataName, setUserDataName] = useState(null);
  const [userDataURL, setUserDataURL] = useState(null);

  const [analysisReportValue, setAnalysisReportValue] = useState(null);
  const [analysisReportName, setAnalysisReportName] = useState(null);
  const [analysisReportURL, setAnalysisReportURL] = useState(null);

  const [supportingDocValue, setSupportingDocValue] = useState(null);
  const [supportingDocName, setSupportingDocName] = useState(null);
  const [supportingDocURL, setSupportingDocURL] = useState(null);
  let logoselecetdFile = "";
  const handleFileUpload = (
    event,
    filekey,
    fileName,
    setdata,
    setName,
    setUrl
  ) => {
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      if (logoselecetdFile) {
        const fileSizeLimit = 10 * 1024 * 1024; // 10 MB limit
        if (logoselecetdFile.size > fileSizeLimit) {
          // File size exceeds limit, show error message
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 10 MB."
          );
          return; // Exit function
        }

        var reader = new FileReader();
        var imagetype = logoselecetdFile.type;
        var imagedatatype = imagetype.split("/");
        var img_crt_type = imagedatatype[1];
        if (
          img_crt_type === "jpeg" ||
          img_crt_type === "jpg" ||
          img_crt_type === "png" ||
          img_crt_type === "pdf"
        ) {
          setValue(filekey, "");
          setValue(fileName, "");
          setdata("");
          setName("");
          setUrl("");
          if (event.target === undefined) {
            setdata(event);
          } else {
            setdata(event.target.files[0]);
          }
          var fileValue = logoselecetdFile;
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(",");
            var ImageValue = spl[1];
            var img_name = fileValue.name;
            setUrl(logourl1);
            setValue(filekey, ImageValue);
            setValue(fileName, img_name);
            setName(img_name);
            clearErrors(filekey);
            clearErrors(fileName);
          };
        } else {
          notifyService(
            "danger",
            "File Format Invalided",
            "Please check your file format"
          );
        }
      }
    } else {
      setValue(filekey, "");
      setValue(fileName, "");
      setdata("");
      setName("");
      setUrl("");
    }
  };
  const onSubmit = (data) => {
    if (readyOnlyValue === false) {
      setLoading(true);
      var getform = data;
      apiService("startup/tanseed/supportingdocs/save", getform, "post").then(
        (res) => {
          setLoading(false);
          if (res?.data?.responseStatus === "Success") {
            notifyService("success", "Success", "Saved successfully !!");
            handleBacktanseed7();
          }
        }
      );
    }
  };
  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBacktanseed7}>
              <i className="fas fa-arrow-alt-circle-left"></i>
            </span>{" "}
            <h6 className="ml-2 mb-0">Documents Upload</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="d-none">
                <div className="form-group">
                  <Controller
                    name="id"
                    control={control}
                    defaultValue="0"
                    render={({ field }) => <TextField {...field} />}
                  />
                </div>
              </div>
              <div className="col-lg-8 col-12">
                {pitchDeskName !== "c29tZXRoaW5n" ? (
                  <div className="form-group mt-2">
                    <Controller
                      name="pitchDeck"
                      control={control}
                      defaultValue=""
                      rules={{
                        required:
                          "PitchDeck (Startup Presentation) File is required",
                      }}
                      render={({ field }) => (
                        <MuiFileInput
                          {...field}
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              "pitchDeck",
                              "pitchDeckName",
                              setPitchDeskValue,
                              setPitchDeskName,
                              setPitchDeskURL
                            )
                          }
                          value={pitchDeskValue}
                          variant="outlined"
                          fullWidth
                          label="Upload PitchDeck (Startup Presentation) *"
                          placeholder="Choose File"
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.pitchDeck && errors.pitchDeck.message}
                    </FormHelperText>
                    <span className="font-sm">
                      Maximum 10 mb allowed doc (png, jpg, jpeg, pdf){" "}
                      <span className="text-danger">*</span>
                    </span>
                  </div>
                ) : (
                  <div>
                    <p className="mb-0">
                      Upload PitchDeck (Startup Presentation){" "}
                      <span className="text-danger">*</span>
                    </p>
                    <div className="row">
                      <div className="col-lg-8 col-12">
                        <Controller
                          name="pitchDeck"
                          control={control}
                          defaultValue=""
                          rules={{
                            required:
                              "In Corporation Certificate File is required",
                          }}
                          render={({ field }) => (
                            <Button
                              component="label"
                              variant="contained"
                              {...field}
                              fullWidth
                              onChange={(e) =>
                                handleFileUpload(
                                  e,
                                  "pitchDeck",
                                  "pitchDeckName",
                                  setPitchDeskValue,
                                  setPitchDeskName,
                                  setPitchDeskURL
                                )
                              }
                              startIcon={<CloudUploadIcon />}
                              href="#file-upload"
                              disabled={readyOnlyValue}
                            >
                              Change
                              {readyOnlyValue === false ? (
                                <VisuallyHiddenInput type="file" />
                              ) : (
                                <></>
                              )}
                            </Button>
                          )}
                        />
                        <span className="font-sm">
                          Maximum 10 mb allowed doc (png, jpg, jpeg, pdf){" "}
                          <span className="text-danger">*</span>
                        </span>
                      </div>
                      <div className="col-lg-4 col-12">
                        <Fab variant="extended">
                          <Link
                            to={`${pitchDeskURL}`}
                            target="_blank"
                            color="black"
                          >
                            <PreviewIcon sx={{ mr: 1 }} />
                            View Document
                          </Link>
                        </Fab>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="elevatorPitch"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: false,
                      validate: {
                        noWhitespace: (value) =>
                          value === "" ||
                          !isWhitespace(value) ||
                          "Whitespace not allowed",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Elevator Pitch of Your Startup"
                        placeholder="Enter Elevator Pitch of Your Startup"
                        fullWidth
                        {...field}
                        inputProps={{ readOnly: readyOnlyValue }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.elevatorPitch && errors.elevatorPitch.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-8 col-12">
                {financialProjectName !== "c29tZXRoaW5n" ? (
                  <div className="form-group mt-2">
                    <Controller
                      name="financialProjections"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <MuiFileInput
                          {...field}
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              "financialProjections",
                              "financialProjectionsName",
                              setFinancialProjectValue,
                              setFinancialProjectName,
                              setFinancialProjectURL
                            )
                          }
                          value={financialProjectValue}
                          variant="outlined"
                          fullWidth
                          label="Upload Financial Projections"
                          placeholder="Choose File"
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.financialProjections &&
                        errors.financialProjections.message}
                    </FormHelperText>
                    <span className="font-sm">
                      Maximum 10 mb allowed doc (png, jpg, jpeg, pdf){" "}
                      <span className="text-danger">*</span>
                    </span>
                  </div>
                ) : (
                  <div>
                    <p className="mb-0">Upload Financial Projections</p>
                    <div className="row">
                      <div className="col-lg-8 col-12">
                        <Controller
                          name="financialProjections"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <Button
                              component="label"
                              variant="contained"
                              {...field}
                              fullWidth
                              onChange={(e) =>
                                handleFileUpload(
                                  e,
                                  "financialProjections",
                                  "financialProjectionsName",
                                  setFinancialProjectValue,
                                  setFinancialProjectName,
                                  setFinancialProjectURL
                                )
                              }
                              startIcon={<CloudUploadIcon />}
                              href="#file-upload"
                              disabled={readyOnlyValue}
                            >
                              Change
                              {readyOnlyValue === false ? (
                                <VisuallyHiddenInput type="file" />
                              ) : (
                                <></>
                              )}
                            </Button>
                          )}
                        />
                        <span className="font-sm">
                          Maximum 10 mb allowed doc (png, jpg, jpeg, pdf){" "}
                          <span className="text-danger">*</span>
                        </span>
                      </div>
                      <div className="col-lg-4 col-12">
                        <Fab variant="extended">
                          <Link
                            to={`${financialProjectURL}`}
                            target="_blank"
                            color="black"
                          >
                            <PreviewIcon sx={{ mr: 1 }} />
                            View Document
                          </Link>
                        </Fab>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="videoPitchLink"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: false,
                      validate: {
                        noWhitespace: (value) =>
                          value === "" ||
                          !isWhitespace(value) ||
                          "Whitespace not allowed",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Video Pitch/Product Demo"
                        placeholder="Enter Video Pitch / Product Demo"
                        fullWidth
                        {...field}
                        inputProps={{ readOnly: readyOnlyValue }}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.videoPitchLink && errors.videoPitchLink.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-8 col-12">
                {userDataName !== "c29tZXRoaW5n" ? (
                  <div className="form-group mt-2">
                    <Controller
                      name="userData"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <MuiFileInput
                          {...field}
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              "userData",
                              "userDataName",
                              setUserDataValue,
                              setUserDataName,
                              setUserDataURL
                            )
                          }
                          value={userDataValue}
                          variant="outlined"
                          fullWidth
                          label="Upload Customer and User Data"
                          placeholder="Choose File"
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.userData && errors.userData.message}
                    </FormHelperText>
                    <span className="font-sm">
                      Maximum 10 mb allowed doc (png, jpg, jpeg, pdf){" "}
                      <span className="text-danger">*</span>
                    </span>
                  </div>
                ) : (
                  <div>
                    <p className="mb-0">Upload Customer and User Data</p>
                    <div className="row">
                      <div className="col-lg-8 col-12">
                        <Controller
                          name="userData"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <Button
                              component="label"
                              variant="contained"
                              {...field}
                              fullWidth
                              onChange={(e) =>
                                handleFileUpload(
                                  e,
                                  "userData",
                                  "userDataName",
                                  setUserDataValue,
                                  setUserDataName,
                                  setUserDataURL
                                )
                              }
                              startIcon={<CloudUploadIcon />}
                              href="#file-upload"
                              disabled={readyOnlyValue}
                            >
                              Change
                              {readyOnlyValue === false ? (
                                <VisuallyHiddenInput type="file" />
                              ) : (
                                <></>
                              )}
                            </Button>
                          )}
                        />
                        <span className="font-sm">
                          Maximum 10 mb allowed doc (png, jpg, jpeg, pdf){" "}
                          <span className="text-danger">*</span>
                        </span>
                      </div>
                      <div className="col-lg-4 col-12">
                        <Fab variant="extended">
                          <Link
                            to={`${userDataURL}`}
                            target="_blank"
                            color="black"
                          >
                            <PreviewIcon sx={{ mr: 1 }} />
                            View Document
                          </Link>
                        </Fab>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-lg-8 col-12">
                {analysisReportName !== "c29tZXRoaW5n" ? (
                  <div className="form-group mt-2">
                    <Controller
                      name="analysisReport"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <MuiFileInput
                          {...field}
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              "analysisReport",
                              "analysisReportName",
                              setAnalysisReportValue,
                              setAnalysisReportName,
                              setAnalysisReportURL
                            )
                          }
                          value={analysisReportValue}
                          variant="outlined"
                          fullWidth
                          label="Upload Market Analysis or Reports"
                          placeholder="Choose File"
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.analysisReport && errors.analysisReport.message}
                    </FormHelperText>
                    <span className="font-sm">
                      Maximum 10 mb allowed doc (png, jpg, jpeg, pdf){" "}
                      <span className="text-danger">*</span>
                    </span>
                  </div>
                ) : (
                  <div>
                    <p className="mb-0">Upload Market Analysis or Reports</p>
                    <div className="row">
                      <div className="col-lg-8 col-12">
                        <Controller
                          name="analysisReport"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <Button
                              component="label"
                              variant="contained"
                              {...field}
                              fullWidth
                              onChange={(e) =>
                                handleFileUpload(
                                  e,
                                  "analysisReport",
                                  "analysisReportName",
                                  setAnalysisReportValue,
                                  setAnalysisReportName,
                                  setAnalysisReportURL
                                )
                              }
                              startIcon={<CloudUploadIcon />}
                              href="#file-upload"
                              disabled={readyOnlyValue}
                            >
                              Change
                              {readyOnlyValue === false ? (
                                <VisuallyHiddenInput type="file" />
                              ) : (
                                <></>
                              )}
                            </Button>
                          )}
                        />
                        <span className="font-sm">
                          Maximum 10 mb allowed doc (png, jpg, jpeg, pdf){" "}
                          <span className="text-danger">*</span>
                        </span>
                      </div>
                      <div className="col-lg-4 col-12">
                        <Fab variant="extended">
                          <Link
                            to={`${analysisReportURL}`}
                            target="_blank"
                            color="black"
                          >
                            <PreviewIcon sx={{ mr: 1 }} />
                            View Document
                          </Link>
                        </Fab>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-lg-8 col-12">
                {supportingDocName !== "c29tZXRoaW5n" ? (
                  <div className="form-group mt-2">
                    <Controller
                      name="supportingDoc"
                      control={control}
                      defaultValue=""
                      rules={{ required: false }}
                      render={({ field }) => (
                        <MuiFileInput
                          {...field}
                          onChange={(e) =>
                            handleFileUpload(
                              e,
                              "supportingDoc",
                              "supportingDocName",
                              setSupportingDocValue,
                              setSupportingDocName,
                              setSupportingDocURL
                            )
                          }
                          value={supportingDocValue}
                          variant="outlined"
                          fullWidth
                          label="Upload Any other supporting documentation"
                          placeholder="Choose File"
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.supportingDoc && errors.supportingDoc.message}
                    </FormHelperText>
                    <span className="font-sm">
                      Maximum 10 mb allowed doc (png, jpg, jpeg, pdf){" "}
                      <span className="text-danger">*</span>
                    </span>
                  </div>
                ) : (
                  <div>
                    <p className="mb-0">
                      Upload Any other supporting documentation
                    </p>
                    <div className="row">
                      <div className="col-lg-8 col-12">
                        <Controller
                          name="supportingDoc"
                          control={control}
                          defaultValue=""
                          rules={{ required: false }}
                          render={({ field }) => (
                            <Button
                              component="label"
                              variant="contained"
                              {...field}
                              fullWidth
                              onChange={(e) =>
                                handleFileUpload(
                                  e,
                                  "supportingDoc",
                                  "supportingDocName",
                                  setSupportingDocValue,
                                  setSupportingDocName,
                                  setSupportingDocURL
                                )
                              }
                              startIcon={<CloudUploadIcon />}
                              href="#file-upload"
                              disabled={readyOnlyValue}
                            >
                              Change
                              {readyOnlyValue === false ? (
                                <VisuallyHiddenInput type="file" />
                              ) : (
                                <></>
                              )}
                            </Button>
                          )}
                        />
                        <span className="font-sm">
                          Maximum 10 mb allowed doc (png, jpg, jpeg, pdf){" "}
                          <span className="text-danger">*</span>
                        </span>
                      </div>
                      <div className="col-lg-4 col-12">
                        <Fab variant="extended">
                          <Link
                            to={`${supportingDocURL}`}
                            target="_blank"
                            color="black"
                          >
                            <PreviewIcon sx={{ mr: 1 }} />
                            View Document
                          </Link>
                        </Fab>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="form-group text-center">
              <Button
                variant="contained"
                onClick={handleBacktanseed7}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyValue === false ? (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Preview</span>
                </LoadingButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Tanseed7Documentsupload;
