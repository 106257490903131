import React, { useContext, useState } from 'react'
import NewReportFilters from './NewReportFilters'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material'
import DataContext from '../../../context/DataContext'
import apiService from '../../../api/apiService'
import { FaFileExport } from "react-icons/fa6";
import notifyService from '../../../api/notifySerivce'

const NewScStReport = () => {
    const { setPageLoading } = useContext(DataContext)
    const [scsthub, setScsthub] = useState("All")
    const [yearNameSelected, setYearNameSelected] = useState('All');
    const [yearName, setYearName] = useState('0');

    const [openExportModal, setOpenExportModal] = useState(false)
    const handleExportCloseModal =()=>{setOpenExportModal(false)}
    const handleExportOpenModal =()=>{setOpenExportModal(true)}

    const handleClear = () => {
        setScsthub("All");
        setYearNameSelected("All")
        setYearName('0')
    };
    const handleExport = ()=>{
        // setPageLoading(true)
        let startYear = '0';   

        if (yearName && yearName !== 'All') { 
            startYear = yearName.split('-')[0].trim();
        }else{
            startYear = '0'; 
        }
        var req = {
            hub: scsthub,
            startYear:startYear
        }
        apiService("admin/scst/admin/report/mail", req, "post")
        .then((result) => {
            if(result){
                notifyService('success', 'Success', 'Export successfully !!')
                handleExportCloseModal()
                // handleClear()
            }
        }).catch((err) => {
            console.log(err);
            
        });
    }
    return (
        <div className="reports-commons">
            <div className="scsthub-div form_div chart">
                <NewReportFilters scsthub={scsthub} setScsthub={setScsthub} yearNameSelected={yearNameSelected} setYearNameSelected={setYearNameSelected} yearName={yearName} setYearName={setYearName} handleClear={handleClear} />
                <div className='mt-3'>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <div>
                                <div className='text-center'>
                                    <div className='mt-3'>
                                    <Button className="down-pdf-btn" startIcon={<FaFileExport/>} onClick={()=>handleExportOpenModal()} >Export</Button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Dialog
                open={openExportModal}
                // onClose={handleExportCloseModal} 
                maxWidth={"sm"}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <center>
                        <img src="/images/img/attention -man.gif" alt="" className="alert-image" />
                    </center>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <h4 className="text-center p-3">Are you sure ?</h4>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="d-flex justify-content-center mb-2">
                    <Button
                        className="remove-api-modal-cancel"
                        onClick={handleExportCloseModal}
                    >
                        Back
                    </Button>
                    <Button className="remove-api-modal-confirm" onClick={()=>handleExport()}>
                        Export
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default NewScStReport
