import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import apiService from "../../../../api/apiService";
import notifyService from "../../../../api/notifySerivce";
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import DataContext from "../../../../context/DataContext";
import DeleteIcon from "@mui/icons-material/Delete";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import DeletePop from "../../../common-page/DeletePop";
const Tanseed7Fundingutilization = () => {
  const [formData, setFormData] = useState({
    fundPurposeAmount: [{}],
  });
  const { navigator, handleBacktanseed7, validateNumberonly, setOpenPop } =
    useContext(DataContext);
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    fields: fundFields,
    append: fundAppend,
    remove: fundRemove,
  } = useFieldArray({
    control,
    name: "fundPurposeAmount",
  });
  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
    dropdownValueApi();
  }, []);
  const [previousRoundsList, setPreviousRoundsList] = useState([]);
  const [purposeList, setPurposeList] = useState([]);
  const dropdownValueApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=startup`, "", "get").then(
      (res) => {
        if (res?.data?.dropDownValues) {
          var val = res.data.dropDownValues;
          setPreviousRoundsList(val.previousFundingStatus);
          setPurposeList(val.purposes);
        }
      }
    );
  };
  const getApi = async () => {
    await apiService(`startup/tanseed/fundingutilisation/get`, "", "get").then(
      (res) => {
        if (res?.data) {
          setFormData({});
          let response = res.data;
          if (response.id !== 0) {
            setFormData(response);
            if (response.editForm === false) {
              setReadyOnlyValue(true);
            }
            if (response.previousFundingRounds) {
              setPreviousFundingRoundsValue(response.previousFundingRounds);
            }
          } else {
            setFormData(response);
          }
        }
      }
    );
  };
  const addFundDetailsBtn = () => {
    fundAppend({
      purpose: "",
      fundAmount: "",
    });
  };

  const [previousFundingRoundsValue, setPreviousFundingRoundsValue] =
    useState("");
  const handleSelectpreviousRounds = (value) => {
    setPreviousFundingRoundsValue(value);
    setValue("previousFundingRounds", value);
    clearErrors("previousFundingRounds");
  };

  const handleSelectPurpose = (value, index) => {
    setValue(`fundPurposeAmount.${index}.purpose`, value);
    clearErrors(`fundPurposeAmount.${index}.purpose`);
  };

  const onSubmit = (data) => {
    if (readyOnlyValue === false) {
      setLoading(true);
      var getform = data;
      apiService(
        "startup/tanseed/fundingutilisation/save",
        getform,
        "post"
      ).then((res) => {
        setLoading(false);
        if (res) {
          if (res.data.responseStatus === "Success") {
            notifyService("success", "Success", "Saved successfully !!");
            navigator("/startup/tanseed/documents-upload");
          }
        }
      });
    }
  };
  // delete Tractions
  const [deleteIndex, setDaleteIndex] = useState();
  const handleDeleteFundingHistory = (index) => {
    setOpenPop(true);
    setDaleteIndex(index);
  };
  const handleClosePop = () => {
    setOpenPop(false);
    setDaleteIndex();
  };
  const deleteApi = (index) => {
    deleteFundingHistory(index);
  };
  const deleteFundingHistory = (index) => {
    const id = getValues(`fundPurposeAmount.${index}.id`);
    if (id !== undefined) {
      apiService(`startup/tanseed/funddetails/remove?id=${id}`, "", "get").then(
        (res) => {
          if (res) {
            if (res.data.responseStatus === "Success") {
              indexBasedremovePurposeAmount(index);
            }
          }
        }
      );
    } else {
      indexBasedremovePurposeAmount(index);
    }
  };
  const indexBasedremovePurposeAmount = (index) => {
    var fundPurposeAmount = getValues("fundPurposeAmount");
    if (fundPurposeAmount.length === 1 && index === 0) {
      var value = getValues();
      value.fundPurposeAmount = [
        {
          purpose: "",
          fundAmount: "",
        },
      ];
      setFormData(value);
    } else {
      fundRemove(index);
      var valu = getValues();
      setFormData(valu);
    }
  };
  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBacktanseed7}>
              <i className="fas fa-arrow-alt-circle-left"></i>
            </span>{" "}
            <h6 className="ml-2 mb-0">Funding Utilization</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="d-none">
                <div className="form-group">
                  <Controller
                    name="id"
                    control={control}
                    defaultValue="0"
                    render={({ field }) => <TextField {...field} />}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="previousFundingRounds"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Previous Funding Rounds is required" }}
                    render={({ field }) => (
                      <Autocomplete
                        disableClearable
                        disablePortal
                        {...field}
                        value={previousFundingRoundsValue}
                        options={previousRoundsList}
                        onChange={(e, selectedOptions) =>
                          handleSelectpreviousRounds(selectedOptions)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Previous Funding Rounds *"
                          />
                        )}
                        disabled={readyOnlyValue}
                      />
                    )}
                  />
                  <FormHelperText className="fnt-sm c-blue">
                    Select one or more
                  </FormHelperText>
                  <FormHelperText className="text-danger">
                    {errors.previousFundingRounds &&
                      errors.previousFundingRounds.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="div_header">
                  <div className="d-flex">
                    <h6>Funding Utilization Details</h6>
                    <Button
                      variant="outlined"
                      disabled={readyOnlyValue}
                      className="ml-auto"
                      onClick={() => addFundDetailsBtn("")}
                    >
                      Add Fund Utilization
                    </Button>
                  </div>
                </div>
                <div>
                  {fundFields.length ? (
                    <div>
                      {fundFields.map((field, index, type) => (
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div className="">{index + 1}</div>
                          <div className="col">
                            <FormControl sx={{ width: "100%" }}>
                              <Controller
                                name={`fundPurposeAmount.${index}.purpose`}
                                control={control}
                                defaultValue=""
                                rules={{ required: "Purpose is required" }}
                                render={({ field }) => (
                                  <Autocomplete
                                    disableClearable
                                    disablePortal
                                    {...field}
                                    options={purposeList}
                                    onChange={(e, selectedOptions) =>
                                      handleSelectPurpose(
                                        selectedOptions,
                                        index
                                      )
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Purpose  *"
                                      />
                                    )}
                                    disabled={readyOnlyValue}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.fundPurposeAmount?.[index]?.purpose &&
                                  errors.fundPurposeAmount?.[index]?.purpose
                                    .message}
                              </FormHelperText>
                            </FormControl>
                          </div>
                          <div className="col">
                            <FormControl sx={{ width: "100%" }}>
                              <Controller
                                name={`fundPurposeAmount.${index}.fundAmount`}
                                control={control}
                                defaultValue=""
                                rules={{ required: "Fund Amount is required" }}
                                render={({ field }) => (
                                  <TextField
                                    id="outlined-multiline-static"
                                    label="Fund Amount (₹)*"
                                    placeholder="Enter Fund Amount (₹)"
                                    fullwidth
                                    {...field}
                                    inputProps={{ readOnly: readyOnlyValue }}
                                    onKeyDown={(e) => validateNumberonly(e)}
                                  />
                                )}
                              />
                              <FormHelperText className="text-danger">
                                {errors.fundPurposeAmount?.[index]
                                  ?.fundAmount &&
                                  errors.fundPurposeAmount?.[index]?.fundAmount
                                    .message}
                              </FormHelperText>
                            </FormControl>
                          </div>
                          <div className="">
                            <DeleteIcon
                              style={{ fill: "#d32f2f", cursor: "pointer" }}
                              onClick={() => {
                                readyOnlyValue === false ? (
                                  handleDeleteFundingHistory(index)
                                ) : (
                                  <></>
                                );
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="nodata_tag">
                      <p>Funding Purpose details is empty</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group text-center">
              <Button
                variant="contained"
                onClick={handleBacktanseed7}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyValue === false ? (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </LoadingButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
      </section>
      <DeletePop
        deleteApi={deleteApi}
        deleteIndex={deleteIndex}
        handleClosePop={handleClosePop}
      />
    </>
  );
};

export default Tanseed7Fundingutilization;
