// import React, { useContext, useState, useEffect } from 'react';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import DataContext from '../../../context/DataContext';
// import apiService from '../../../api/apiService';
// import useDidMountEffect from '../../../hooks/useDidMountEffect';

// const StartupApplicationStatus = ({ startupIds }) => {
//     const { setPageLoading } = useContext(DataContext);
//     const [tableData, setTableData] = useState({});
//     const phaseNames = [
//         { label: 'Screening', key: 'screening' },
//         { label: 'Evaluation', key: 'evaluation' },
//         { label: 'BDD', key: 'bdd' },
//         { label: 'IC', key: 'ic' },
//         { label: 'PSC', key: 'psc' },
//         { label: 'LDD', key: 'ldd' },
//         { label: 'FDD', key: 'fdd' },
//         { label: 'CP', key: 'cp' },
//         { label: 'FS', key: 'fs' },
//         { label: 'FD', key: 'fd' }
//     ];

//     useDidMountEffect(() => {
//         startupStatus();
//     }, []);

//     const startupStatus = () => {
//         setPageLoading(true);
//         var req = {
//             startupId: Number(startupIds),
//         };
//         apiService('admin/startup/application/status', req, 'post')
//             .then((res) => {
//                 console.log(res.data);
//                 if (res.data) {
//                     setPageLoading(false);
//                     setTableData(res.data);
//                 }
//             })
//             .catch((err) => {
//                 console.log(err);
//             });
//     };

//     return (
//         <div style={{ placeItems: 'center' }}>
//             {Object.keys(tableData).length > 0 ? (
//                 <TableContainer component={Paper} className="table-container">
//                     <Table sx={{ minWidth: 650 }} aria-label="caption table">
//                         <TableHead>
//                             <TableRow>
//                                 <TableCell align="center" className="table-header">
//                                     S.No
//                                 </TableCell>
//                                 <TableCell align="center" className="table-header">
//                                     Phase
//                                 </TableCell>
//                                 <TableCell align="center" className="table-header">
//                                     No of Days
//                                 </TableCell>
//                             </TableRow>
//                         </TableHead>
//                         <TableBody>
//                             {phaseNames.map((phase, i) => (
//                                 <TableRow key={i} className="table-row">
//                                     <TableCell align="center" className="table-cell">
//                                         {i + 1}
//                                     </TableCell>
//                                     <TableCell align="center" className="table-cell">
//                                         {phase.label}
//                                     </TableCell>
//                                     <TableCell align="center" className="table-cell">
//                                         {tableData[phase.key] !== undefined ? tableData[phase.key] : 'N/A'}
//                                     </TableCell>
//                                 </TableRow>
//                             ))}
//                         </TableBody>
//                     </Table>
//                 </TableContainer>
//             ) : (
//                 <div className="no-data">
//                     <img src="/images/Tanfundlogo-black-1.png" alt="No Data" />
//                     <h4>No Data</h4>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default StartupApplicationStatus;
import React, { useContext, useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import DataContext from '../../../context/DataContext';
import apiService from '../../../api/apiService';

const StartupApplicationStatus = ({ startupIds }) => {
    const { setPageLoading } = useContext(DataContext);


    const [chartData, setChartData] = useState({
        series: [
            {
                name: 'Days',
                data: []
            }
        ]
    });

    const [clarificationData, setClarificationData] = useState({
        series: [
            {
                name: 'Days',
                data: []
            }
        ]
    });
    useEffect(() => {
        startupStatus();
        startupclarificationStatus()
    }, []);

    const startupStatus = () => {
        setPageLoading(true);
        const req = {
            startupId: Number(startupIds),
        };
        apiService('admin/startup/application/status', req, 'post')
            .then((res) => {
                setPageLoading(false);
                if (res.data) {
                    const durations = [
                        res.data.screening ?? 0, res.data.evaluation ?? 0, res.data.bdd ?? 0,
                        res.data.ic ?? 0, res.data.psc ?? 0, res.data.ldd ?? 0,
                        res.data.fdd ?? 0, res.data.cp ?? 0, res.data.fs ?? 0,
                        res.data.fd ?? 0
                    ];
                    setChartData({
                        series: [{
                            name: 'Days',
                            data: durations
                        }]
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                setPageLoading(false);
            });
    };
    const startupclarificationStatus = () => {
        setPageLoading(true);
        const req = {
            startupId: Number(startupIds),
        };
        apiService('admin/startup/clarification/status', req, 'post')
            .then((res) => {
                setPageLoading(false);
                if (res.data) {
                    const clarification = [
                        res.data.screening ?? 0, res.data.evaluation ?? 0, res.data.bdd ?? 0,
                        res.data.ic ?? 0, res.data.psc ?? 0, res.data.ldd ?? 0,
                        res.data.fdd ?? 0, res.data.cp ?? 0, res.data.fs ?? 0,
                        res.data.fd ?? 0
                    ];
                    setClarificationData({
                        series: [{
                            name: 'Days',
                            data: clarification
                        }]
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                setPageLoading(false);
            });
    };
    const chartOptions = {
        chart: {
            height: 400,
            type: 'bar',
            toolbar: { show: false },
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                horizontal: false,
                distributed: true,
                dataLabels: {
                    position: 'top',
                },
            },
        },
        colors: [
            '#FF5733', // Screening
            '#33FF57', // Evaluation
            '#3357FF', // BDD
            '#FF33A5', // IC
            '#A533FF', // PSC
            '#FF9C33', // LDD
            '#33FFC5', // FDD
            '#FFC133', // CP
            '#57FF33', // FS
            '#5733FF', // FD
        ],
        dataLabels: {
            enabled: true,
            formatter: (val) => val > 0 ? `${val} day(s)` : '',
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ['#304758'],
            },
        },
        xaxis: {
            categories: [
                'Screening', 'Evaluation', 'BDD', 'IC', 'PSC', 'LDD', 'FDD', 'CP', 'FS', 'FD',
            ],
            position: 'bottom',
            axisBorder: { show: false },
            axisTicks: { show: false },
            tooltip: { enabled: true },
            labels: {
                style: {
                    fontWeight: 'bold',
                    colors: ['#304758'],
                    fontSize: '14px',
                }
            }
        },
        yaxis: {
            title: { text: 'Day(s)' },
            axisBorder: { show: false },
            axisTicks: { show: false },
            labels: {
                formatter: (val) => val.toFixed(1), // Ensure decimals are shown
            },
            tickAmount: 5,
            min: 0,
            max: chartData.series[0].data.length > 0 ? Math.max(...chartData.series[0].data) + 1 : 5,
        },
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'right',
            labels: {
                colors: ['#304758'],
            },
            itemMargin: {
                vertical: 10,
            },
            containerMargin: {
                left: 10,
                right: 10,
            },
            maxHeight: undefined,
            floating: false,
            itemWidth: 20,
        },
    };
    const clarificationOptions = {
        chart: {
            height: 400,
            type: 'bar',
            toolbar: { show: false },
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                horizontal: false,
                distributed: true,
                dataLabels: {
                    position: 'top',
                },
            },
        },
        colors: [
            '#FF5733', // Screening
            '#33FF57', // Evaluation
            '#3357FF', // BDD
            '#FF33A5', // IC
            '#A533FF', // PSC
            '#FF9C33', // LDD
            '#33FFC5', // FDD
            '#FFC133', // CP
            '#57FF33', // FS
            '#5733FF', // FD
        ],
        dataLabels: {
            enabled: true,
            formatter: (val) => val > 0 ? `${val} day(s)` : '',
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ['#304758'],
            },
        },
        xaxis: {
            categories: [
                'Screening', 'Evaluation', 'BDD', 'IC', 'PSC', 'LDD', 'FDD', 'CP', 'FS', 'FD',
            ],
            position: 'bottom',
            axisBorder: { show: false },
            axisTicks: { show: false },
            tooltip: { enabled: true },
            labels: {
                style: {
                    fontWeight: 'bold',
                    colors: ['#304758'],
                    fontSize: '14px',
                }
            }
        },
        yaxis: {
            title: { text: 'Day(s)' },
            axisBorder: { show: false },
            axisTicks: { show: false },
            labels: {
                formatter: (val) => val.toFixed(1), // Ensure decimals are shown
            },
            tickAmount: 5,
            min: 0,
            max: clarificationData.series[0].data.length > 0 ? Math.max(...clarificationData.series[0].data) + 1 : 5,
        },
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'right',
            labels: {
                colors: ['#304758'],
            },
            itemMargin: {
                vertical: 10,
            },
            containerMargin: {
                left: 10,
                right: 10,
            },
            maxHeight: undefined,
            floating: false,
            itemWidth: 20,
        },
    };
    return (
        <div>
          {chartData.series && ( <div id="chart">
                <ReactApexChart
                    options={chartOptions}
                    series={chartData.series}
                    type="bar"
                    height={350}
                />
            </div>)}
            {clarificationData.series && (
                <>
                <div className='text-center my-3'><h4>Clarification History</h4></div>
                <div id="chart">
                <ReactApexChart
                    options={clarificationOptions}
                    series={clarificationData.series}
                    type="bar"
                    height={350}
                />
            </div>
            </>)}
        </div>
    );
};

export default StartupApplicationStatus;



