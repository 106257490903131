import { Autocomplete, Button, Divider, FormControl, Grid, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DataContext from "../../context/DataContext";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import apiService from "../../api/apiService";
import ReactApexChart from 'react-apexcharts';
import Chart from "react-apexcharts";

const Scsthubdashboard = () => {
    const { setPageLoading, yearListApi, } = useContext(DataContext)
    const [chartData, setChartData] = useState([]);
    const [investorsListValue, setInvestorListValue] = useState([]);
    const [selectedItem, setSelectedItem] = useState('All');
    const [yearName, setYearName] = useState('0');
    const [yearNameSelected, setYearNameSelected] = useState('All');
    const [monthName, setMonthName] = useState(0);
    const [districtName, setDistrictName] = useState("All");
    const [selectedFilteredYear, setSelectedFilteredYear] = useState(null);
    const [activeChip, setActiveChip] = useState("All");
    const nameval = localStorage.getItem("name")
    const [selectedSection, setSelectedSection] = useState('all');
    const handlelistClick = (val) => {
        debugger
        setSelectedSection(val);
    };
    const activeStyle = {
        backgroundColor: '#253B80', color: '#fff', '&:hover': {
            backgroundColor: '#253B80', color: '#fff'
        }
    };
    const listData = [
        {
            onClick: 'all',
            text: 'All'
        },
        {
            onClick: 'screening',
            text: 'Screening'
        },
        {
            onClick: 'evaluation',
            text: 'Evaluation'
        },
        {
            onClick: 'bdd',
            text: 'BDD'
        },
        {
            onClick: 'ic',
            text: 'IC'
        },
        {
            onClick: 'psc',
            text: 'PSC'
        },
        {
            onClick: 'ldd',
            text: 'LDD'
        },
        {
            onClick: 'fdd',
            text: 'FDD'
        },
        {
            onClick: 'fs',
            text: 'FS'
        },
        {
            onClick: 'fd',
            text: 'FD'
        },
    ];
    useDidMountEffect(() => {
        yearListApi()
        districtGet()
        // handleSelectYear(yearName)
    }, []);
    useDidMountEffect(() => {

        if (selectedItem) {
            chartDatas();
        } else {
            setChartData([]);
        }

        districtHubGet()

    }, [selectedItem, districtName, yearName, yearNameSelected, selectedFilteredYear, activeChip]);


    useDidMountEffect(() => {
        yearApi(2021);
    }, []);
    useDidMountEffect(() => {
        if (!selectedItem && investorsListValue.length > 0) {
            setSelectedItem(investorsListValue[0].id);
        }
    }, [investorsListValue]);

    const districtGet = () => {
        apiService('admin/hub/get', "", "get").then(
            (res) => {
                setPageLoading(false)
                if (res) {
                    if (res && res.data.hubs) {
                        var hub = res.data.hubs

                        hub.unshift('All')
                        setInvestorListValue(hub)
                    }
                }
            }
        );
    }
    const [cityList, setCityList] = useState([])
    const hubList = localStorage.getItem('name')
    const districtHubGet = () => {
        setCityList('')
        apiService(`admin/hub/district/get?hub=${hubList}`, "", "get").then(
            (res) => {
                setPageLoading(false)
                if (res) {
                    if (res && res.data && res.data.districts) {
                        var dis = res.data.districts
                        dis.unshift('All')
                        setCityList(dis)
                    }
                }
            }
        );
    }
    const handleChartClick = (item) => {
        setSelectedItem(item);
        setDistrictName('All')
    };
    let month = [
        'All', 'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ]
    const [monthList, setMonthList] = useState(month)
    const [selectedMonth, setSelectedMonth] = useState(monthList[0])
    const handleSelectMonth = (e, value) => {
        const index = monthList.indexOf(value);
        setSelectedMonth(value)
        setMonthName(index)
    }
    const handleSelectCity = (value) => {
        setDistrictName(value);
    };
    const [filteredYears, setFilteredYears] = useState([]);
    const yearApi = (startyear) => {
        apiService(`common/dashboard/listyears?startyear=2021`, "", "get").then(
            (res) => {
                setPageLoading(false);
                if (res && res.data && res.data.yearMap) {
                    var yearMap = res.data.yearMap;
                    var allYears = Object.keys(yearMap).map((year) => ({
                        label: year,
                        value: yearMap[year]
                    }));

                    allYears.unshift({ label: "All", value: 0 });

                    setFilteredYears(allYears);
                }
            }
        );
    };
    const handleSelectYear = (event, selectedOption) => {
        setYearName(selectedOption);
        setYearNameSelected(selectedOption)
        if (selectedOption) {
            var name = selectedOption.split(' - ')
            setYearName(name[0]);
        } else {
            setYearName('2024');

        }
    };
    const [tabIndex, setTabIndex] = useState(1);
    const handleTabDetails = (value, index) => {


        setTabIndex(index + 1)

    };
    const handleChange = (event, newValue) => {
        setActiveChip('All')
        const newTabDetails = investorsListValue[newValue];
        setSelectedItem(newTabDetails)
        if (newTabDetails) {

        }

    };
    const handleChipClick = (chip, id, fundID) => {
        setActiveChip(chip);
    };


    const [screening, setScreening] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [screening1, setScreening1] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [evaluation, setEvaluation] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [evaluation1, setEvaluation1] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [bDD, setBDD] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [bDD1, setBDD1] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [bDDDeepDive, setBDDDeepDive] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        colors: [
            '#008FFB',
            '#FF5733',
            '#33FF57',
            '#FF33A5',
            '#A533FF',
            '#FF9C33',
            '#33FFC5',
            '#FFC133',
            '#57FF33',
            '#5733FF',
        ],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [bDDDeepDive1, setBDDDeepDive1] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        colors: [
            '#008FFB',
            '#FF5733',
            '#33FF57',

            '#FF33A5',
            '#A533FF',
            '#FF9C33',
            '#33FFC5',
            '#FFC133',
            '#57FF33',
            '#5733FF',
        ],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });


    const [ic, setIc] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [ic1, setIc1] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [psc, setPsc] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [psc1, setPsc1] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [ldd, setLdd] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [ldd1, setLdd1] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [fdd, setFdd] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [fdd1, setFdd1] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [fs, setFs] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [fs1, setFs1] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [fd, setFd] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [fd1, setFd1] = useState({
        series: [],
        chart: {
            width: 380,
            type: "pie",
        },
        title: {
            align: "center",
        },
        labels: [""],
        legend: {
            position: "bottom",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {},
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    const [barVal, setBarVal] = useState({
        series: [],
        categories: []
    });
    const chartDatas = () => {
        setChartData([]);

        setPageLoading(true);
        let startYear = '0';

        if (yearName && yearName !== 'All') {
            startYear = yearName.split('-')[0].trim();
        }
        var req = {
            hub: hubList,
            district: activeChip,
            startYear: startYear
            // startYear: yearName === 'All' ? 0 : yearName,
            // endYear: selectedFilteredYear !== null ? selectedFilteredYear : '',
        };
        apiService('admin/scst/dashboard', req, "post")
            .then((result) => {
                setPageLoading(false);
                if (result) {
                    setChartData(result.data);
                }
                if (result.data.barChart) {
                    setBarVal(result.data.barChart);
                } else {
                    setBarVal({
                        series: [],
                        categories: []
                    });
                }
                if (result.data.validationGreater7Days && result.data.validationGreater7DaysLabels) {
                    const isPscEmpty = result.data.validationGreater7Days.every((val) => val === 0);
                    setScreening({
                        ...screening,
                        series: result.data.validationGreater7Days,
                        labels: result.data.validationGreater7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.validationUnder7Days && result.data.validationUnder7DaysLabels) {
                    const isPscEmpty = result.data.validationUnder7Days.every((val) => val === 0);
                    setScreening1({
                        ...screening1,
                        series: result.data.validationUnder7Days,
                        labels: result.data.validationUnder7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }

                if (result.data.evaluationGreater7Days && result.data.evaluationGreater7DaysLabels) {
                    const isPscEmpty = result.data.evaluationGreater7Days.every((val) => val === 0);
                    setEvaluation({
                        ...evaluation,
                        series: result.data.evaluationGreater7Days,
                        labels: result.data.evaluationGreater7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.evaluationUnder7Days && result.data.evaluationUnder7DaysLabels) {
                    const isPscEmpty = result.data.evaluationUnder7Days.every((val) => val === 0);
                    setEvaluation1({
                        ...evaluation1,
                        series: result.data.evaluationUnder7Days,
                        labels: result.data.evaluationUnder7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.bddFirstConnectGreater7Days && result.data.bddFirstConnectGreater7DaysLabels) {
                    const isPscEmpty = result.data.bddFirstConnectGreater7Days.every((val) => val === 0);
                    setBDD({
                        ...bDD,
                        series: result.data.bddFirstConnectGreater7Days,
                        labels: result.data.bddFirstConnectGreater7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.bddFirstConnectUnder7Days && result.data.bddFirstConnectUnder7DaysLabels) {
                    const isPscEmpty = result.data.bddFirstConnectUnder7Days.every((val) => val === 0);
                    setBDD1({
                        ...bDD1,
                        series: result.data.bddFirstConnectUnder7Days,
                        labels: result.data.bddFirstConnectUnder7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.bddDeepDiveGreater7Days && result.data.bddDeepDiveGreater7DaysLabels) {
                    const isPscEmpty = result.data.bddDeepDiveGreater7Days.every((val) => val === 0);
                    setBDDDeepDive({
                        ...bDDDeepDive,
                        series: result.data.bddDeepDiveGreater7Days,
                        labels: result.data.bddDeepDiveGreater7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.bddFirstConnectUnder7Days && result.data.bddDeepDiveUnder7DaysLabels) {
                    const isPscEmpty = result.data.bddFirstConnectUnder7Days.every((val) => val === 0);
                    setBDDDeepDive1({
                        ...bDDDeepDive1,
                        series: result.data.bddFirstConnectUnder7Days,
                        labels: result.data.bddDeepDiveUnder7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.icGreater7Days && result.data.icGreater7DaysLabels) {
                    const isPscEmpty = result.data.icGreater7Days.every((val) => val === 0);
                    setIc({
                        ...ic,
                        series: result.data.icGreater7Days,
                        labels: result.data.icGreater7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.icUnder7Days && result.data.icUnder7DaysLabels) {
                    const isPscEmpty = result.data.icUnder7Days.every((val) => val === 0);
                    setIc1({
                        ...ic1,
                        series: result.data.icUnder7Days,
                        labels: result.data.icUnder7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.pscGreater7Days && result.data.pscGreater7DaysLabels) {
                    const isPscEmpty = result.data.pscGreater7Days.every((val) => val === 0);
                    setPsc({
                        ...psc,
                        series: result.data.pscGreater7Days,
                        labels: result.data.pscGreater7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.pscUnder7Days && result.data.pscUnder7DaysLabels) {
                    const isPscEmpty = result.data.pscUnder7Days.every((val) => val === 0);
                    setPsc1({
                        ...psc1,
                        series: result.data.pscUnder7Days,
                        labels: result.data.pscUnder7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.lddGreater7Days && result.data.lddGreater7DaysLabels) {
                    const isPscEmpty = result.data.lddGreater7Days.every((val) => val === 0);
                    setLdd({
                        ...ldd,
                        series: result.data.lddGreater7Days,
                        labels: result.data.lddGreater7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.lddUnder7Days && result.data.lddUnder7DaysLabels) {
                    const isPscEmpty = result.data.lddUnder7Days.every((val) => val === 0);
                    setLdd1({
                        ...ldd1,
                        series: result.data.lddUnder7Days,
                        labels: result.data.lddUnder7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.fddGreater7Days && result.data.fddGreater7DaysLabels) {
                    const isPscEmpty = result.data.fddGreater7Days.every((val) => val === 0);
                    setFdd({
                        ...fdd,
                        series: result.data.fddGreater7Days,
                        labels: result.data.fddGreater7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.fddUnder7Days && result.data.fddUnder7DaysLabels) {
                    const isPscEmpty = result.data.fddUnder7Days.every((val) => val === 0);
                    setFdd1({
                        ...fdd1,
                        series: result.data.fddUnder7Days,
                        labels: result.data.fddUnder7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.fsGreater7Days && result.data.fsGreater7DaysLabels) {
                    const isPscEmpty = result.data.fsGreater7Days.every((val) => val === 0);
                    setFs({
                        ...fs,
                        series: result.data.fsGreater7Days,
                        labels: result.data.fsGreater7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.fsUnder7Days && result.data.fsUnder7DaysLabels) {
                    const isPscEmpty = result.data.fsUnder7Days.every((val) => val === 0);
                    setFs1({
                        ...fs,
                        series: result.data.fsUnder7Days,
                        labels: result.data.fsUnder7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.fdGreater7Days && result.data.fdGreater7DaysLabels) {
                    const isPscEmpty = result.data.fdGreater7Days.every((val) => val === 0);
                    setFd({
                        ...fd,
                        series: result.data.fdGreater7Days,
                        labels: result.data.fdGreater7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
                if (result.data.fdUnder7Days && result.data.fdUnder7DaysLabels) {
                    const isPscEmpty = result.data.fdUnder7Days.every((val) => val === 0);
                    setFd1({
                        ...fd,
                        series: result.data.fdUnder7Days,
                        labels: result.data.fdUnder7DaysLabels,
                        isEmpty: isPscEmpty,
                    });
                }
            })
    };
    const options = {
        chart: {
            type: 'bar',
            height: 350,
            width: '100%',
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }
        ],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 7,
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'last',
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                },
                columnWidth: '40%'
            }
        },
        xaxis: {
            categories: barVal.categories
        },
        legend: {
            position: 'right',
            offsetY: 40
        },
        fill: {
            opacity: 1
        },
        colors: [
            '#008FFB',
            '#FEB019',
            '#FF5733',
        ],
    };
    return (
        <section className="">
            <div className="container-fluid">
                <div className="form_div chart">

                    <div className="collapse show mt-3 mb-3" id="collapseWidthExample">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            disableClearable
                                            disablePortal
                                            value={yearNameSelected || ""}
                                            options={filteredYears.map((year) => year.label)}
                                            onChange={handleSelectYear}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Financial Year" fullWidth />
                                            )}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className='d-flex align-items-center'>
                                    <img src='/images/handshake.png' alt='' className='star_logo' />
                                    <h6 className='font-weight-bold ml-lg-3 ml-md-3'>Total Startups</h6>
                                    <div className='star_details ml-auto text-center'>
                                        <h5 className='font-weight-bold'>{chartData.totalStartups ? chartData.totalStartups : '0'}</h5>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="chips text-center">
                            {cityList && cityList.map((item, index) => (
                                <Button
                                    key={index}
                                    variant={activeChip === item ? "contained" : "outlined"}
                                    className={activeChip === item ? "tan-chip1" : "tan-chip2"}
                                    onClick={() => handleChipClick(item)}
                                    sx={{ margin: '5px' }}
                                >
                                    {item}
                                </Button>
                            ))}
                        </div>
                        <div className="row">

                            <div className="col-lg-12">
                                <div>
                                    <div id="chart">
                                        <ReactApexChart options={options} series={barVal.series} type="bar" height={350} />
                                    </div>
                                    <div id="html-dist"></div>
                                </div>
                            </div>
                            {/* {screening && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>Screening</h5>
                                    <Divider />
                                    <div className="dashBox dashBox4">
                                        {screening.isEmpty ? (
                                            <p className="not-avail">No data available for Screening</p>
                                        ) : (
                                            <Chart
                                                options={screening}
                                                series={screening.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {evaluation && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>Evaluation</h5>
                                    <Divider />
                                    <div className="dashBox dashBox4">
                                        {evaluation.isEmpty ? (
                                            <p className="not-avail">No data available for Evaluation</p>
                                        ) : (
                                            <Chart
                                                options={evaluation}
                                                series={evaluation.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {bDD && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>BDD FirstConnect</h5>
                                    <Divider />
                                    <div className=" dashBox4">
                                        {bDD.isEmpty ? (
                                            <p className="not-avail">No data available for BDD</p>
                                        ) : (
                                            <Chart
                                                options={bDD}
                                                series={bDD.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {bDDDeepDive && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>BDD Deep Dive</h5>
                                    <Divider />                                   
                                    <div className=" dashBox4">
                                        {bDDDeepDive.isEmpty ? (
                                            <p className="not-avail">No data available for BDD Deep Dive</p>
                                        ) : (
                                            <Chart
                                                options={bDDDeepDive}
                                                series={bDDDeepDive.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>

                                </div>
                            </div>
                        )}
                        {ic && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>IC</h5>
                                    <Divider />

                                    <div className="dashBox dashBox4">
                                        {ic.isEmpty ? (
                                            <p className="not-avail">No data available for IC</p>
                                        ) : (
                                            <Chart
                                                options={ic}
                                                series={ic.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {psc && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>PSC</h5>
                                    <Divider />
                                    <div className="dashBox dashBox4">
                                        {psc.isEmpty ? (
                                            <p className="not-avail">No data available for PSC</p>
                                        ) : (
                                            <Chart
                                                options={psc}
                                                series={psc.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {ldd && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>LDD</h5>
                                    <Divider />

                                    <div className="dashBox dashBox4">
                                        {ldd.isEmpty ? (
                                            <p className="not-avail">No data available for LDD</p>
                                        ) : (
                                            <Chart
                                                options={ldd}
                                                series={ldd.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {fdd && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>FDD</h5>
                                    <Divider />

                                    <div className="dashBox dashBox4">
                                        {fdd.isEmpty ? (
                                            <p className="not-avail">No data available for FDD</p>
                                        ) : (
                                            <Chart
                                                options={fdd}
                                                series={fdd.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {fs && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>FS</h5>
                                    <Divider />

                                    <div className="dashBox dashBox4">
                                        {fs.isEmpty ? (
                                            <p className="not-avail">No data available for FS</p>
                                        ) : (
                                            <Chart
                                                options={fs}
                                                series={fs.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {fd && (
                            <div className="col-lg-4 text-center">
                                <div className="total_stars mt-4">
                                    <h5 className='font-weight-bold text-center'>FD</h5>
                                    <Divider />

                                    <div className="dashBox dashBox4">
                                        {fd.isEmpty ? (
                                            <p className="not-avail">No data available for FD</p>
                                        ) : (
                                            <Chart
                                                options={fd}
                                                series={fd.series}
                                                type="pie"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )} */}
                        </div>
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{ paddingTop: '0px !important' }}>
                                <div className="total_stars-left">
                                    <List>
                                        {listData.map((item, index) => (
                                            <div key={item.text}>
                                                <ListItem
                                                    button
                                                    onClick={() => handlelistClick(item.text.toLowerCase())}
                                                    sx={selectedSection === item.text.toLowerCase() ? activeStyle : {}}
                                                >
                                                    <ListItemText primary={item.text} />
                                                </ListItem>


                                                {index < listData.length - 1 && <Divider />}
                                            </div>
                                        ))}
                                    </List>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} lg={9} xl={9} sx={{ height: '100vh', overflowY: 'auto', paddingTop: '0px !important' }}>
                                <>
                                    {(selectedSection === 'all' || selectedSection === 'screening') && (
                                        <>
                                            <div className="total_stars mb-5">
                                                {selectedSection === 'all' && (<h6 className="font-weight-bold text-center mb-3">Screening</h6>)}
                                                <Divider />
                                                <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
                                                    <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                                        {screening && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Greater than 7 Day(s)</p>
                                                                <Divider />
                                                                {screening.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for Screening</p>
                                                                ) : (
                                                                    <div className="dashBox dashBox4">
                                                                        <Chart options={screening} series={screening.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>

                                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Divider variant="middle" orientation="vertical" flexItem />
                                                    </Grid>

                                                    <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                                        {screening1 && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Less than 7 Day(s)</p>
                                                                <Divider />
                                                                {screening1.isEmpty ? (
                                                                    <p className="font-weight-bold text-center my-3">No data available for Screening</p>
                                                                ) : (
                                                                    <div className="dashBox dashBox4">
                                                                        <Chart options={screening1} series={screening1.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </>
                                    )}
                                    {(selectedSection === 'all' || selectedSection === 'evaluation') && (
                                        <>
                                            <div className="total_stars mb-5">
                                                {selectedSection === 'all' && (<h6 className="font-weight-bold text-center mb-3">Evaluation</h6>)}
                                                <Divider />
                                                <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {evaluation && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Greater than 7 Day(s)</p>
                                                                <Divider />
                                                                {evaluation.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for Evaluation</p>
                                                                ) : (
                                                                    <div className="dashBox dashBox4">
                                                                        <Chart options={evaluation} series={evaluation.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Divider variant="middle" orientation="vertical" flexItem />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {evaluation1 && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Less than 7 Day(s)</p>
                                                                <Divider />
                                                                {evaluation1.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for Evaluation</p>
                                                                ) : (
                                                                    <div className="dashBox dashBox4">
                                                                        <Chart options={evaluation1} series={evaluation1.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </>
                                    )}
                                    {(selectedSection === 'all' || selectedSection === 'bdd') && (
                                        <>
                                            <div className="total_stars mb-5">
                                                <h6 className="font-weight-bold text-center mb-3">BDD First Connect</h6>
                                                <Divider />
                                                <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {bDD && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Greater than 7 Day(s)</p>
                                                                <Divider />
                                                                {bDD.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for BDD</p>
                                                                ) : (
                                                                    <div className="dashBoxss dashBox4">
                                                                        {/* <Chart options={{ ...bDD, colors: bDD.colors }} series={bDD.series} type="pie" /> */}
                                                                        <Chart options={bDD} series={bDD.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Divider variant="middle" orientation="vertical" flexItem />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {bDD1 && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Less than 7 Day(s)</p>
                                                                <Divider />
                                                                {bDD1.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for BDD</p>
                                                                ) : (
                                                                    <div className="dashBoxss dashBox4">
                                                                        <Chart options={bDD1} series={bDD1.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </div>

                                            <div className="total_stars mb-5">
                                                <h6 className="font-weight-bold text-center mb-3">BDD Deep Dive</h6>
                                                <Divider />
                                                <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {bDDDeepDive && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Greater than 7 Day(s)</p>
                                                                <Divider />
                                                                {bDDDeepDive.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for BDD</p>
                                                                ) : (
                                                                    <div className="dashBoxss dashBox4">
                                                                        {/* <Chart options={{ ...bDD, colors: bDD.colors }} series={bDD.series} type="pie" /> */}
                                                                        <Chart options={bDDDeepDive} series={bDDDeepDive.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Divider variant="middle" orientation="vertical" flexItem />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {bDDDeepDive1 && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Less than 7 Day(s)</p>
                                                                <Divider />
                                                                {bDDDeepDive1.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for BDD</p>
                                                                ) : (
                                                                    <div className="dashBoxss dashBox4">
                                                                        <Chart options={bDDDeepDive1} series={bDDDeepDive1.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </>
                                    )}
                                    {(selectedSection === 'all' || selectedSection === 'ic') && (
                                        <>
                                            <div className="total_stars mb-5">
                                                {selectedSection === 'all' && (<h6 className="font-weight-bold text-center mb-3">IC</h6>)}
                                                <Divider />
                                                <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {ic && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Greater than 7 Day(s)</p>
                                                                <Divider />
                                                                {ic.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for IC</p>
                                                                ) : (
                                                                    <div className="dashBox dashBox4">
                                                                        <Chart options={ic} series={ic.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Divider variant="middle" orientation="vertical" flexItem />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {ic1 && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Less than 7 Day(s)</p>
                                                                <Divider />
                                                                {ic1.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for IC</p>
                                                                ) : (
                                                                    <div className="dashBox dashBox4">
                                                                        <Chart options={ic1} series={ic1.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </>
                                    )}
                                    {(selectedSection === 'all' || selectedSection === 'psc') && (
                                        <>
                                            <div className="total_stars mb-5">
                                                {selectedSection === 'all' && (<h6 className="font-weight-bold text-center mb-3">PSC</h6>)}
                                                <Divider />
                                                <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {psc && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Greater than 7 Day(s)</p>
                                                                <Divider />
                                                                {psc.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for PSC</p>
                                                                ) : (
                                                                    <div className="dashBox dashBox4">
                                                                        <Chart options={psc} series={psc.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Divider variant="middle" orientation="vertical" flexItem />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {psc1 && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Less than 7 Day(s)</p>
                                                                <Divider />
                                                                {psc1.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for PSC</p>
                                                                ) : (
                                                                    <div className="dashBox dashBox4">
                                                                        <Chart options={psc1} series={psc1.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </>
                                    )}
                                    {(selectedSection === 'all' || selectedSection === 'ldd') && (
                                        <>
                                            <div className="total_stars mb-5">
                                                {selectedSection === 'all' && (<h6 className="font-weight-bold text-center mb-3">LDD</h6>)}
                                                <Divider />
                                                <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {ldd && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Greater than 7 Day(s)</p>
                                                                <Divider />
                                                                {ldd.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for LDD</p>
                                                                ) : (
                                                                    <div className="dashBox dashBox4">
                                                                        <Chart options={ldd} series={ldd.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Divider variant="middle" orientation="vertical" flexItem />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {ldd1 && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Less than 7 Day(s)</p>
                                                                <Divider />
                                                                {ldd1.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for LDD</p>
                                                                ) : (
                                                                    <div className="dashBox22 dashBox4">
                                                                        <Chart options={ldd1} series={ldd1.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </>
                                    )}
                                    {(selectedSection === 'all' || selectedSection === 'fdd') && (
                                        <>
                                            <div className="total_stars mb-5">
                                                {selectedSection === 'all' && (<h6 className="font-weight-bold text-center mb-3">FDD</h6>)}
                                                <Divider />
                                                <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {fdd && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Greater than 7 Day(s)</p>
                                                                <Divider />
                                                                {fdd.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for FDD</p>
                                                                ) : (
                                                                    <div className="dashBox dashBox4">
                                                                        <Chart options={fdd} series={fdd.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Divider variant="middle" orientation="vertical" flexItem />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {fdd1 && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Less than 7 Day(s)</p>
                                                                <Divider />
                                                                {fdd1.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for FDD</p>
                                                                ) : (
                                                                    <div className="dashBox dashBox4">
                                                                        <Chart options={fdd1} series={fdd1.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </>
                                    )}
                                    {(selectedSection === 'all' || selectedSection === 'fs') && (
                                        <>
                                            <div className="total_stars mb-5">
                                                {selectedSection === 'all' && (<h6 className="font-weight-bold text-center mb-3">FS</h6>)}
                                                <Divider />
                                                <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {fdd && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Greater than 7 Day(s)</p>
                                                                <Divider />
                                                                {fs.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for FS</p>
                                                                ) : (
                                                                    <div className="dashBox dashBox4">
                                                                        <Chart options={fs} series={fs.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Divider variant="middle" orientation="vertical" flexItem />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {fs1 && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Less than 7 Day(s)</p>
                                                                <Divider />
                                                                {fs1.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for FS</p>
                                                                ) : (
                                                                    <div className="dashBox dashBox4">
                                                                        <Chart options={fs1} series={fs1.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </>
                                    )}
                                    {(selectedSection === 'all' || selectedSection === 'fd') && (
                                        <>
                                            <div className="total_stars mb-5">
                                                {selectedSection === 'all' && (<h6 className="font-weight-bold text-center mb-3">FD</h6>)}
                                                <Divider />
                                                <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {fd && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Greater than 7 Day(s)</p>
                                                                <Divider />
                                                                {fd.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for FD</p>
                                                                ) : (
                                                                    <div className="dashBox dashBox4">
                                                                        <Chart options={fd} series={fd.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Divider variant="middle" orientation="vertical" flexItem />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                                        {fd1 && (
                                                            <>
                                                                <p className="font-weight-bold text-center my-3">Less than 7 Day(s)</p>
                                                                <Divider />
                                                                {fd1.isEmpty ? (
                                                                    <p className="not-avail text-center">No data available for FD</p>
                                                                ) : (
                                                                    <div className="dashBox dashBox4">
                                                                        <Chart options={fd1} series={fd1.series} type="pie" />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </>
                                    )}
                                </>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Scsthubdashboard
