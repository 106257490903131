import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import apiService from "../../../../api/apiService";
import notifyService from "../../../../api/notifySerivce";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import DataContext from "../../../../context/DataContext";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tanseed7Fundingprograms from "./ModalDetails/Tanseed7Fundingprograms";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import DeletePop from "../../../common-page/DeletePop";
const Tanseed7Fundingfinancials = () => {
  const [formData, setFormData] = useState({});
  const {
    handleBacktanseed7,
    navigator,
    MenuProps,
    Paper,
    modalstyle,
    icon,
    checkedIcon,
    validateNumberonly,
    isWhitespace,
    setOpenPop,
  } = useContext(DataContext);
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    fields: fundFields,
    append: fundAppend,
    remove: fundRemove,
  } = useFieldArray({
    control,
    name: "fundHistory",
  });
  const [fundingProgramDetails, setFundingProgramDetails] = useState([]);

  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
    dropdownValueApi();
    // eslint-disable-next-line
  }, []);
  const [preferredInvestorTypeList, setPreferredInvestorTypeList] = useState(
    []
  );
  const [investmentTicketSize, setInvestmentTicketSize] = useState([]);
  const [instrumentTypeData, setInstrumentTypeData] = useState([]);
  const [revenuesList, setLevenuesList] = useState([]);
  const [startupinstrumentTypeList, setStartupinstrumentTypeList] = useState(
    []
  );
  const [fundingProgramsList, setfundingProgramsList] = useState([]);
  const dropdownValueApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=startup`, "", "get").then(
      (res) => {
        if (res?.data?.dropDownValues) {
          var val = res.data.dropDownValues;
          setPreferredInvestorTypeList(val.prefInvestorTypes);
          setInvestmentTicketSize(val.desiredFundingAmount);
          setInstrumentTypeData(val.prefFundingInstruments);
          setLevenuesList(val.revenueStages);
          setStartupinstrumentTypeList(val.previousFundHistoryInstruments);
          setfundingProgramsList(val.fundingPrograms);
        }
      }
    );
  };
  const getApi = async () => {
    await apiService(
      `startup/tanseed/fundingandfinancials/get`,
      "",
      "get"
    ).then((res) => {
      if (res?.data) {
        setFormData({});
        let response = res.data;
        if (response.id !== 0) {
          setFormData(response);
          if (response.editForm === false) {
            setReadyOnlyValue(true);
          }
          if (response.startupFundingPrograms) {
            setFundingProgramDetails(response.startupFundingPrograms);
          }
          if (response.preferredInvestorType) {
            setPreferredInvestorTypeValue(response.preferredInvestorType);
          }
          if (response.investmentInstrumentType) {
            setInvestmentInstrumentTypeValue(response.investmentInstrumentType);
          }
        } else {
          setFormData(response);
        }
      }
    });
  };
  const addFundDetailsBtn = () => {
    fundAppend({
      fundRaised: "",
      organisationName: "",
      instrumentType: "",
    });
  };
  const [fundingProgramIndex, setFundingProgramIndex] = useState("");
  const addFundingProgramBtn = (index) => {
    setFundingProgramIndex(index);
    handleOpen();
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [preferredInvestorTypeValue, setPreferredInvestorTypeValue] = useState(
    []
  );
  const handleSelectPreferredInvestorType = (value) => {
    setPreferredInvestorTypeValue(value);
    setValue("preferredInvestorType", value);
    clearErrors("preferredInvestorType");
  };

  const handleSelectRevenues = (value) => {
    setValue("revenueStage", value);
    clearErrors("revenueStage");
  };

  const handleSelectTicketSize = (value) => {
    setValue("investTicketSize", value);
    clearErrors("investTicketSize");
  };

  const [investmentInstrumentTypeValue, setInvestmentInstrumentTypeValue] =
    useState([]);
  const handleSelectInvestment = (value) => {
    setInvestmentInstrumentTypeValue(value);
    setValue("investmentInstrumentType", value);
    clearErrors("investmentInstrumentType");
  };
  // delete History
  const [deleteFunction, setDeleteFunction] = useState();
  const [deleteIndex, setDaleteIndex] = useState();
  const [deleteId, setDaleteId] = useState();
  const handleDeleteHistory = (index) => {
    setOpenPop(true);
    setDeleteFunction("fundingHistory");
    setDaleteIndex(index);
  };
  const handleDeleteProgram = (index, id) => {
    setOpenPop(true);
    setDeleteFunction("fundingProgram");
    setDaleteIndex(index);
    setDaleteId(id);
  };
  const handleClosePop = () => {
    setOpenPop(false);
    setDeleteFunction();
    setDaleteIndex();
    setDaleteId();
  };
  const deleteApi = (index, id) => {
    if (deleteFunction === "fundingHistory") {
      deleteFundingHistory(index);
    }
    if (deleteFunction === "fundingProgram") {
      deleteFundingProgram(index, id);
    }
  };
  const deleteFundingHistory = async (index) => {
    const id = getValues(`fundHistory.${index}.id`);
    if (id !== undefined) {
      apiService(`startup/tanseed/fundhistory/remove?id=${id}`, "", "get").then(
        (res) => {
          if (res?.data?.responseStatus === "Success") {
            indexBasedremoveFundingHistory(index);
            getApi();
          }
        }
      );
    } else {
      indexBasedremoveFundingHistory(index);
    }
  };
  const indexBasedremoveFundingHistory = (index) => {
    fundRemove(index);
    var val = getValues();
    setFormData(val);
  };
  // delete Program
  const deleteFundingProgram = (indexToDelete, id) => {
    if (id !== "0" && id !== undefined) {
      apiService(
        `startup/tanseed/fundingprograms/remove?id=${id}`,
        "",
        "get"
      ).then((res) => {
        if (res?.data?.responseStatus === "Success") {
          const updatedFundingPrograms = fundingProgramDetails.filter(
            (_, index) => index !== indexToDelete
          );
          setFundingProgramDetails(updatedFundingPrograms);
          getApi();
        }
      });
    } else {
      const updatedFundingPrograms = fundingProgramDetails.filter(
        (_, index) => index !== indexToDelete
      );
      setFundingProgramDetails(updatedFundingPrograms);
    }
  };
  const onSubmit = (data) => {
    if (readyOnlyValue === false) {
      setLoading(true);
      var getform = data;
      if (fundingProgramDetails.length > 0) {
        getform.startupFundingPrograms = fundingProgramDetails;
      } else {
        getform.startupFundingPrograms = [];
      }

      apiService(
        "startup/tanseed/fundingandfinancials/save",
        getform,
        "post"
      ).then((res) => {
        setLoading(false);
        if (res?.data?.responseStatus === "Success") {
          notifyService("success", "Success", "Saved successfully !!");
          navigator("/startup/tanseed/team");
        }
      });
    }
  };
  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBacktanseed7}>
              <i className="fas fa-arrow-alt-circle-left"></i>
            </span>{" "}
            <h6 className="ml-2 mb-0">Funding & Financials</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="d-none">
                <div className="form-group">
                  <Controller
                    name="id"
                    control={control}
                    defaultValue="0"
                    render={({ field }) => <TextField {...field} />}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-12 ">
                <div className="form-group mt-2">
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="preferredInvestorType"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Preferred Investor Type is required",
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          disableClearable
                          disablePortal
                          {...field}
                          multiple={true}
                          value={preferredInvestorTypeValue}
                          limitTags={2}
                          options={preferredInvestorTypeList}
                          onChange={(e, selectedOptions) =>
                            handleSelectPreferredInvestorType(selectedOptions)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Preferred Investor Type *"
                            />
                          )}
                          disabled={readyOnlyValue}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                        />
                      )}
                    />
                    <FormHelperText className="fnt-sm c-blue">
                      Select one or more
                    </FormHelperText>
                    <FormHelperText className="text-danger">
                      {errors.preferredInvestorType &&
                        errors.preferredInvestorType.message}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="investTicketSize"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Desired Funding Amount is required" }}
                      render={({ field }) => (
                        <Autocomplete
                          disableClearable
                          disablePortal
                          {...field}
                          options={investmentTicketSize}
                          onChange={(e, selectedOptions) =>
                            handleSelectTicketSize(selectedOptions)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Desired Funding Amount (₹)*"
                            />
                          )}
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="text-danger">
                      {errors.investTicketSize &&
                        errors.investTicketSize.message}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="investmentInstrumentType"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Preferred Funding Instrument is required",
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          disableClearable
                          disablePortal
                          multiple
                          {...field}
                          limitTags={2}
                          value={investmentInstrumentTypeValue}
                          options={instrumentTypeData}
                          onChange={(e, selectedOptions) =>
                            handleSelectInvestment(selectedOptions)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Preferred Funding Instrument *"
                            />
                          )}
                          disabled={readyOnlyValue}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                        />
                      )}
                    />
                    <FormHelperText className="fnt-sm c-blue">
                      Select one or more
                    </FormHelperText>
                    <FormHelperText className="text-danger">
                      {errors.investmentInstrumentType &&
                        errors.investmentInstrumentType.message}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="revenueStage"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Revenue Stage is required" }}
                    render={({ field }) => (
                      <Autocomplete
                        disableClearable
                        disablePortal
                        {...field}
                        options={revenuesList}
                        onChange={(e, selectedOptions) =>
                          handleSelectRevenues(selectedOptions)
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Revenue Stage *" />
                        )}
                        disabled={readyOnlyValue}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.revenueStage && errors.revenueStage.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="annualRevenue"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Annual Revenue is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Annual Revenue (₹)*"
                        placeholder="Enter Annual Revenue (₹)"
                        fullWidth
                        inputProps={{ readOnly: readyOnlyValue }}
                        onKeyDown={(e) => validateNumberonly(e)}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.annualRevenue && errors.annualRevenue.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="monthlyBurnRate"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Monthly Burn Rate is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Monthly Burn Rate  (₹)*"
                        placeholder="Enter Monthly Burn Rate (₹)"
                        fullWidth
                        inputProps={{ readOnly: readyOnlyValue }}
                        onKeyDown={(e) => validateNumberonly(e)}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.monthlyBurnRate && errors.monthlyBurnRate.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="totalFundsRaised"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Total Funds Raised till Date is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Total Funds Raised till Date (₹) *"
                        placeholder="Enter Total Funds Raised till Date (₹)"
                        fullWidth
                        inputProps={{ readOnly: readyOnlyValue }}
                        onKeyDown={(e) => validateNumberonly(e)}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.totalFundsRaised && errors.totalFundsRaised.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="div_header">
                  <div className="d-flex">
                    <h6>Previous Fundraising History</h6>
                    <Button
                      variant="outlined"
                      disabled={readyOnlyValue}
                      className="ml-auto"
                      onClick={() => addFundDetailsBtn("")}
                    >
                      Add Fundraising History
                    </Button>
                  </div>
                </div>
                <div>
                  {fundFields.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableBody>
                          {fundFields.map((field, index, type) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`fundHistory.${index}.fundRaised`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Fund Raised is required",
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Fund Raised (₹)*"
                                        placeholder="Enter Fund Raised (₹)"
                                        fullwidth
                                        {...field}
                                        inputProps={{
                                          readOnly: readyOnlyValue,
                                        }}
                                        onKeyDown={(e) => validateNumberonly(e)}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.fundHistory?.[index]?.fundRaised &&
                                      errors.fundHistory?.[index]?.fundRaised
                                        .message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`fundHistory.${index}.organisationName`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Organization Name is required",
                                      validate: {
                                        noWhitespace: (value) =>
                                          !isWhitespace(value) ||
                                          "Whitespace not allowed",
                                      },
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Organization Name *"
                                        placeholder="Enter Organization Name"
                                        fullwidth
                                        {...field}
                                        inputProps={{
                                          readOnly: readyOnlyValue,
                                        }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.fundHistory?.[index]
                                      ?.organisationName &&
                                      errors.fundHistory?.[index]
                                        ?.organisationName.message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell sx={{ width: "25%" }}>
                                <FormControl sx={{ width: "100%" }}>
                                  <InputLabel htmlFor="grouped-native-select">
                                    Instrument Type *
                                  </InputLabel>
                                  <Controller
                                    name={`fundHistory.${index}.instrumentType`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Instrument Type is required",
                                    }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        readOnly={readyOnlyValue}
                                        defaultValue={type}
                                        input={<OutlinedInput label="Name" />}
                                        MenuProps={MenuProps}
                                      >
                                        {startupinstrumentTypeList &&
                                          startupinstrumentTypeList.map(
                                            (name) => (
                                              <MenuItem key={name} value={name}>
                                                {name}
                                              </MenuItem>
                                            )
                                          )}
                                      </Select>
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.fundHistory?.[index]
                                      ?.instrumentType &&
                                      errors.fundHistory?.[index]
                                        ?.instrumentType.message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell align="center">
                                <DeleteIcon
                                  style={{ fill: "#d32f2f", cursor: "pointer" }}
                                  onClick={() => {
                                    readyOnlyValue === false ? (
                                      handleDeleteHistory(index)
                                    ) : (
                                      <></>
                                    );
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div className="nodata_tag">
                      <p>Fundraising history details is empty</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 mb-3">
                <div className="div_header">
                  <div className="d-flex">
                    <h6>Funding Programs</h6>
                    <Button
                      variant="outlined"
                      disabled={readyOnlyValue}
                      className="ml-auto"
                      onClick={() => addFundingProgramBtn("")}
                    >
                      Add Funding Program
                    </Button>
                  </div>
                </div>
                <div>
                  {fundingProgramDetails.length ? (
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead>
                          <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Partner</TableCell>
                            <TableCell>Program</TableCell>
                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fundingProgramDetails.map((tableData, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{tableData.type}</TableCell>
                              <TableCell>
                                {tableData.partner ? tableData.partner : `-`}
                              </TableCell>
                              <TableCell>
                                {tableData.program ? tableData.program : `-`}
                              </TableCell>
                              <TableCell align="center">
                                <EditIcon
                                  style={{ fill: "#66bb6a", cursor: "pointer" }}
                                  onClick={() => {
                                    readyOnlyValue === false ? (
                                      addFundingProgramBtn(index)
                                    ) : (
                                      <></>
                                    );
                                  }}
                                />
                                <DeleteIcon
                                  style={{ fill: "#dc3545", cursor: "pointer" }}
                                  onClick={() => {
                                    readyOnlyValue === false ? (
                                      handleDeleteProgram(index, tableData.id)
                                    ) : (
                                      <></>
                                    );
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div className="nodata_tag">
                      <p>Funding details is empty</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group text-center">
              <Button
                variant="contained"
                onClick={handleBacktanseed7}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyValue === false ? (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </LoadingButton>
              ) : (
                <></>
              )}
            </div>
          </form>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalstyle} className="form_tag_lg">
              <Tanseed7Fundingprograms
                handleClose={handleClose}
                fundingProgramDetails={fundingProgramDetails}
                setFundingProgramDetails={setFundingProgramDetails}
                fundingProgramIndex={fundingProgramIndex}
                fundingProgramsList={fundingProgramsList}
              />
            </Box>
          </Modal>
        </div>
      </section>
      <DeletePop
        deleteApi={deleteApi}
        deleteIndex={deleteIndex}
        deleteId={deleteId}
        handleClosePop={handleClosePop}
      />
    </>
  );
};

export default Tanseed7Fundingfinancials;
