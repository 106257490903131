import { Box, DialogTitle } from "@mui/material";
import React, { useContext } from "react";
import apiService from "../../../../../api/apiService";
import notifyService from "../../../../../api/notifySerivce";
import DataContext from "../../../../../context/DataContext";

const style = {
  p: 4,
};
const Tanseed7Modal = ({ handleApplyClose, optedForTanseed, page ,currentVersion}) => {
  const { navigator } = useContext(DataContext);
  const handleApplySubmit = () => {
    handleApplyClose();
    navigator("/startup/tanseed/self-declaration");
    // if (optedForTanseed === false) {
    //   apiService("startup/applyTANSEED", "", "get").then((res) => {
    //     if (res) {
    //       if (res.data.responseStatus === "Success") {
    //         notifyService("success", "Success", res.data.responseMessage);
    //         handleApplyClose();
    //         navigator("/startup/tanseed-7.0/self-declaration");
    //       } else {
    //         notifyService("danger", "Error", res.data.responseMessage);
    //       }
    //     }
    //   });
    // } else {
    //   handleApplyClose();
    //   navigator("/startup/tanseed-7.0/self-declaration");
    // }
  };
  return (
    <Box sx={style} className="modal1">
      <img
        src="/images/close.png"
        onClick={handleApplyClose}
        className="close-btn"
        alt=""
      />
      <img src="/images/tanseed-logo.png" alt="img-fluid" />
      <h3 className="head font-weight-bold">TANSEED {currentVersion}</h3>
      {page === "dashboard" && (
        <DialogTitle>You have chosen to apply for TANSEED {currentVersion}</DialogTitle>
      )}
      <div className="text-center">
        <button className="yellow" onClick={handleApplySubmit}>
          {page === "dashboard" ? "Submit" : "Apply Now"}{" "}
          <img
            src="/images/sigup-btn.png"
            className="pt-1 pl-1 arrow-img"
            alt=""
          />
        </button>
      </div>
    </Box>
  );
};

export default Tanseed7Modal;
