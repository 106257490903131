import React, { useContext, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Fab,
  FormControl,
  FormHelperText,
  FormLabel,
  styled,
  Switch,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import DataContext from "../../../context/DataContext";
import apiService from "../../../api/apiService";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import notifyService from "../../../api/notifySerivce";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { MuiFileInput } from "mui-file-input";
import { Link } from "react-router-dom";
import PreviewIcon from "@mui/icons-material/Preview";
import { FileUploader } from "react-drag-drop-files";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Viewimage from "../../../api/Viewimage";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
const fileTypes = ["png", "jpeg", "jpg"];
const fileTypesPDF = ["pdf"];

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const BusinessStrategyScst = () => {
  const showAddInfo = localStorage.getItem("showInfo");
  const [formData, setFormData] = useState({});
  const { navigator, handleBackapp, setPageLoading, validateNumberonly } =
    useContext(DataContext);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const {
    handleSubmit,
    setValue,
    watch,
    getValues,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    values: formData,
  });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [loading, setLoading] = useState(false);

  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
    dropdownValueApi();
  }, []);
  const [businessModelsData, setBusinessModelsData] = useState([]);
  const dropdownValueApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=startup`, "", "get").then(
      (res) => {
        var val = res.data.dropDownValues;
        setBusinessModelsData(val.businessModels);
      }
    );
  };
  const getApi = async () => {
    setPageLoading(true);
    await apiService(`startup/businessstrategy/get`, "", "get").then((res) => {
      if (res) {
        setPageLoading(false);
        setFormData({});
        let response = res.data;
        if (response.id !== 0) {
          if (response.letterOfIntentName) {
            response.solutionProcessDiagURL = response.solutionProcessDiagName;
          }
          if (response.letterOfIntentName) {
            response.letterOfIntentURL = response.letterOfIntentName;
          }
          setFormData(response);
          // if (response.editForm === false) {
          //   setReadyOnlyValue(true);
          // }
          if (response.editForm === false && response.superEdit === false) {
            setReadyOnlyValue(true)
        }
        if (response.editForm === false && response.superEdit === true) {
            setReadyOnlyValue(false)
        }
        if (response.editForm === true && response.superEdit === false) {
            setReadyOnlyValue(false)
        }
          // if (response.overviweDocument) {
          //     setDocumentValue(response.overviweDocument);
          //     setDocumentName(response.overviweDocument);
          //     setDocumentURL(response.overviweDocumentName);
          //     setCheckMethod("update");
          // }
        } else {
          setFormData(response);
        }
      }
    });
  };
  // const [businessModelsValue, setBusinessModelsValue] = useState('');
  // const handleSelectBusiness = (value) => {
  //     // setBusinessModelsValue(value);
  //     setValue('businessModel', value)
  //     clearErrors('businessModel');
  // }
  const onSubmit = (data) => {
    if (readyOnlyValue === false) {
      setPageLoading(true);
      var getform = data;
      apiService("startup/businessstrategy/save", getform, "post").then(
        (res) => {
          setPageLoading(false);
          if (res) {
            if (res.data.responseStatus === "Success") {
              notifyService("success", "Success", "Saved successfully !!");
              // navigator('/startup/traction-achievements')
              if (showAddInfo === "true") {
                navigator("/startup/market&competitors/scst");
              }
            }
          }
        }
      );
    }
  };
  const existingCustomerPresent = watch("existingCustomerPresent", false);
  const handleFilter = (isExistingCustomerPresent) => {
    setValue("existingCustomerPresent", isExistingCustomerPresent);
    if (!isExistingCustomerPresent) {
      setValue("existingCustomerPresent", "");
      businessCustomersRemove();
    } else {
      addBusinessCustomersBtn();
    }
  };

  const deliveryOrOneTimeSalespart = watch("deliveryOrOneTimeSalespart", false);
  const handleFilter1 = (isDeliveryOrOneTimeSalespart) => {
    setValue("deliveryOrOneTimeSalespart", isDeliveryOrOneTimeSalespart);
    if (!isDeliveryOrOneTimeSalespart) {
      setValue("deliveryOrOneTimeSalespart", "");
    }
  };

  const manufactureComponents = watch("manufactureComponents", false);
  const handleFilter2 = (isManufactureComponents) => {
    setValue("manufactureComponents", isManufactureComponents);
    if (!isManufactureComponents) {
      setValue("manufactureComponents", "");
    }
  };

  const lettersFromPotentialCustomers = watch(
    "lettersFromPotentialCustomers",
    false
  );
  const handleFilter3 = (isLettersFromPotentialCustomers) => {
    debugger
    setValue("lettersFromPotentialCustomers", isLettersFromPotentialCustomers);
    if (!isLettersFromPotentialCustomers) {
      setValue("lettersFromPotentialCustomers", "");
      clearErrors('lettersFromPotentialCustomers')
      setValue("letterOfIntent","")
      setValue("letterOfIntentName","")
      setValue("letterOfIntentURL","")
    }
  };
  const handlePaste = (event) => {
    event.preventDefault();
  };
  const followBusinessModel = watch("followBusinessModel", false);
  const handleFilter4 = (followBusinessModel) => {
    setValue("followBusinessModel", followBusinessModel);
    if (!followBusinessModel) {
      setValue("followBusinessModel", "");
    }
  };

  const canContact = watch("canContact", false);
  const handleFilter5 = (isCanContact) => {
    setValue("canContact", isCanContact);
    if (!isCanContact) {
      setValue("canContact", "");
    }
  };

  const handleSelectBusiness = (value) => {
    setValue(`businessModel`, value);
    clearErrors(`businessModel`);
  };

  // const handleFileUpload = (event, filekey, fileName, fileURL) => {
  //     let logoselecetdFile;
  //     if (event !== null) {
  //         if (event.target === undefined) {
  //             logoselecetdFile = event
  //         } else {
  //             logoselecetdFile = event.target.files[0]
  //         }
  //         if (logoselecetdFile) {
  //             const fileSizeLimit = 5 * 1024 * 1024;
  //             if (logoselecetdFile.size > fileSizeLimit) {
  //                 notifyService(
  //                     "danger",
  //                     "File Size Exceeded",
  //                     "Please upload a file smaller than 5 MB."
  //                 );
  //                 return;
  //             }
  //             var reader = new FileReader();
  //             var imagetype = logoselecetdFile.type
  //             var imagedatatype = imagetype.split("/")
  //             var img_crt_type = imagedatatype[1]
  //             if (img_crt_type === "jpg" || img_crt_type === "png" || img_crt_type === "jpeg") {
  //                 setValue(filekey, "")
  //                 setValue(fileName, "")
  //                 setValue(fileURL, '')
  //                 // setName('')
  //                 // setUrl('')
  //                 // if (event.target === undefined) {
  //                 //     setReportValue(event)
  //                 // } else {
  //                 //     setReportValue(event.target.files[0])
  //                 // }
  //                 var fileValue = logoselecetdFile
  //                 reader.readAsDataURL(logoselecetdFile);
  //                 reader.onload = () => {
  //                     var logourl1 = reader.result;
  //                     var spl = logourl1.split(',')
  //                     var ImageValue = spl[1]
  //                     var img_name = fileValue.name
  //                     // setUrl(logourl1)
  //                     setValue(filekey, ImageValue)
  //                     setValue(fileName, img_name)
  //                     setValue(fileURL, logourl1)
  //                     // setName(img_name)
  //                     clearErrors(filekey);
  //                     clearErrors(fileName);
  //                     clearErrors(fileURL);
  //                     // if (filekey === "startupLogo") {
  //                     //     setLogoInvalid(false)
  //                     // }
  //                 }
  //             } else {
  //                 notifyService('danger', 'File Format Invalided', 'Please check your file format')
  //             }
  //         }
  //     } else { }
  // }
  const handleFileUpload = (event, filekey, fileName, fileURL, format) => {
    let logoselecetdFile;
    if (event !== null) {
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      if (logoselecetdFile) {
        const fileSizeLimit = 5 * 1024 * 1024;
        if (logoselecetdFile.size > fileSizeLimit) {
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return;
        }
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type;
        var imagedatatype = imagetype.split("/");
        var img_crt_type = imagedatatype[1];
        debugger;
        if (
          (format === "pdf" && img_crt_type === "pdf") ||
          (format === "file" &&
            (img_crt_type === "jpg" ||
              img_crt_type === "png" ||
              img_crt_type === "jpeg"))
        ) {
          setValue(filekey, "");
          setValue(fileName, "");
          debugger;
          var fileValue = logoselecetdFile;
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(",");
            var ImageValue = spl[1];
            var img_name = fileValue.name;
            setValue(filekey, ImageValue);
            setValue(fileName, img_name);
            setValue(fileURL, logourl1);
            clearErrors(filekey);
            clearErrors(fileName);
            clearErrors(fileURL);
          };
        } else {
          notifyService(
            "danger",
            "File Format Invalided",
            "Please check your file format"
          );
        }
      }
    }
  };
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    setViewImage(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const {
    fields: businessCustomersFields,
    append: businessCustomersAppend,
    remove: businessCustomersRemove,
  } = useFieldArray({
    control,
    name: "startupBusinessCustomers",
  });

  const addBusinessCustomersBtn = () => {
    businessCustomersAppend({
      id: 0,
      name: "",
      email: "",
      phone: "",
      location: "",
      canContact: false,
    });
  };

  const deleteBusinessCustomers = async (index) => {
    const id = getValues(`startupBusinessCustomers.${index}.id`);
    if (id !== 0 && id !== undefined) {
      apiService(
        `startup/businesscustomers/remove?id=${id}`,
        "",
        "delete"
      ).then((res) => {
        if (res) {
          if (res.data.responseStatus === "Success") {
            indexBasedAddRow(index);
          }
        }
      });
    } else {
      indexBasedAddRow(index);
    }
  };
  const indexBasedAddRow = (index) => {
    var startupTeams = getValues("startupBusinessCustomers");
    if (startupTeams.length === 1 && index === 0) {
      var val = getValues();
      val.startupBusinessCustomers = [
        {
          id: 0,
          name: "",
          email: "",
          phone: "",
          location: "",
          canContact: false,
        },
      ];
      setFormData(val);
    } else {
      businessCustomersRemove(index);
      var val = getValues();
      setFormData(val);
    }
  };
  const validatePhoneNumber = (value) => {
    // const isValid = value.length === 10;
    if (value.length !== 10) {
      return "Phone number must be 10 digits";
    }
    const matches = value.match(
      /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/
    );
    if (matches === null) {
      return "Only numbers allowed";
    }
  };

  const validateEmail = (value) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValid = emailRegex.test(value);
    return isValid || "Invalid email address";
  };

  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBackapp}>
              <img src="/images/Fig-back.png" className="img-fluid" alt="" />
            </span>{" "}
            <h6 className="ml-2 mb-0">Business Strategy</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="d-none">
                <div className="form-group">
                  <Controller
                    name="id"
                    control={control}
                    defaultValue="0"
                    render={({ field }) => <TextField {...field} />}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="briefProblemStatement"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Problem statement is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="What is the problem statement being addressed? *"
                        placeholder="Enter problem statement"
                        fullWidth
                        multiline
                        rows={3}
                        inputProps={{ readOnly: readyOnlyValue }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.briefProblemStatement &&
                      errors.briefProblemStatement.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="problemIdentified"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Problem is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="How did you identify this problem, give background? *"
                        placeholder="Enter the Problem"
                        fullWidth
                        multiline
                        rows={3}
                        inputProps={{ readOnly: readyOnlyValue }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.problemIdentified &&
                      errors.problemIdentified.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="problemAffectCustomers"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Problem affect customers is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="How does this problem affect customers? *"
                        placeholder="Enter Problem affect customers"
                        fullWidth
                        multiline
                        rows={3}
                        inputProps={{ readOnly: readyOnlyValue }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.problemAffectCustomers &&
                      errors.problemAffectCustomers.message}
                  </FormHelperText>
                </div>
              </div>

              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="briefProposedSolution"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Proposed Solution is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="What is the proposed Solution? *"
                        placeholder="Enter Proposed Solution"
                        fullWidth
                        multiline
                        rows={3}
                        inputProps={{ readOnly: readyOnlyValue }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.briefProposedSolution &&
                      errors.briefProposedSolution.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="solutionAddressProblem"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Solution address the problem is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="How can the solution address the problem you have outlined earlier? *"
                        placeholder="Enter solution address the problem"
                        fullWidth
                        multiline
                        rows={3}
                        inputProps={{ readOnly: readyOnlyValue }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.solutionAddressProblem &&
                      errors.solutionAddressProblem.message}
                  </FormHelperText>
                </div>
              </div>

              {/* <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="problemIdentified" control={control} defaultValue="" rules={{ required: 'Problem identified is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="How was this problem identified? *"
                                                placeholder='Enter Problem identified'
                                                fullWidth
                                                multiline
                                                rows={3}
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.problemIdentified && errors.problemIdentified.message}</FormHelperText>
                                </div>
                            </div> */}

              {/* <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="validationPurposes" control={control} defaultValue="" rules={{ required: 'Customer contact details is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Can you provide customer contact details for validation purposes? *"
                                                placeholder='Enter customer contact details'
                                                fullWidth
                                                multiline
                                                rows={3}
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.validationPurposes && errors.validationPurposes.message}</FormHelperText>
                                </div>
                            </div> */}
              {/* <div className='col-lg-8 col-12'>
                                <div className="form-group mt-2">
                                    <Controller name="customerAcquisitionTimeline" control={control} defaultValue="" rules={{ required: 'Take/do you estimate is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="In your business model, how long does it take/do you estimate it will take for you to close one customer for your product/service? *"
                                                placeholder='Enter take/do you estimate'
                                                fullWidth
                                                multiline
                                                rows={3}
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.customerAcquisitionTimeline && errors.customerAcquisitionTimeline.message}</FormHelperText>
                                </div>
                            </div> */}
              {/* <div className="col-lg-4 col-12 mb-3 ">
                                {checkMethod === "save" ? (
                                    <div className="form-group ">
                                        <Controller
                                            name="overviweDocument"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required:
                                                    false,
                                            }}
                                            render={({ field }) => (
                                                <MuiFileInput
                                                    {...field}
                                                    onChange={(e) =>
                                                        handleFileUpload(
                                                            e,
                                                            "overviweDocument",
                                                            "overviweDocumentName",
                                                            setDocumentValue,
                                                            setDocumentName,
                                                            setDocumentURL,
                                                            "file"
                                                        )
                                                    }
                                                    value={documentValue}
                                                    variant="outlined"
                                                    // inputProps={}
                                                    // onReset={(e) => handleremove(e)}
                                                    inputRef={inputRef}
                                                    // inputProps= {
                                                    //   ref=
                                                    // }

                                                    fullWidth
                                                    label="Can you provide a solution overview document?"
                                                    placeholder="Choose File"
                                                />
                                            )}
                                        />
                                        <FormHelperText className="text-danger">
                                            {errors.overviweDocument && errors.overviweDocument.message}
                                        </FormHelperText>
                                        <div>
                                            <span className="fn-12">
                                                Maximum 5 mb allowed doc (png, jpg, jpeg, pdf){" "}
                                                <span className="text-danger">*</span>
                                            </span>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <p className="mb-0">
                                            Can you provide a solution overview document?
                                        </p>
                                        <div className="row">
                                            <div className="col-lg-7 col-12 my-3">
                                                <Controller
                                                    name="overviweDocument"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{
                                                        required:
                                                            "Solution overview document is required",
                                                    }}
                                                    render={({ field }) => (
                                                        <Button
                                                            component="label"
                                                            variant="contained"
                                                            {...field}
                                                            fullWidth
                                                            onChange={(e) =>
                                                                handleFileUpload(
                                                                    e,
                                                                    "overviweDocument",
                                                                    "overviweDocumentName",
                                                                    setDocumentValue,
                                                                    setDocumentName,
                                                                    setDocumentURL,
                                                                    "file"
                                                                )
                                                            }
                                                            startIcon={<CloudUploadIcon />}
                                                            href="#file-upload"
                                                            disabled={readyOnlyValue}
                                                        >
                                                            Change
                                                            {readyOnlyValue === false ? (
                                                                <VisuallyHiddenInput type="file" />
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Button>
                                                    )}
                                                />
                                                <div>
                                                    <span className="fn-12">
                                                        Maximum 5 mb allowed doc (png, jpg, jpeg, pdf){" "}
                                                        <span className="text-danger">*</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-12">
                                                <Fab variant="extended">
                                                    <Link
                                                        to={`${documentURL}`}
                                                        target="_blank"
                                                        color="black"
                                                    >
                                                        <PreviewIcon sx={{ mr: 1 }} />
                                                        View Document
                                                    </Link>
                                                </Fab>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div> */}

              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="customerAcquisitionTimeline"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Customer acquisition timeline is required",
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="What's your estimated customer acquisition timeline? *"
                        placeholder="Enter Customer acquisition timeline"
                        fullWidth
                        inputProps={{ readOnly: readyOnlyValue }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.customerAcquisitionTimeline &&
                      errors.customerAcquisitionTimeline.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="businessModel"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Business Model is required" }}
                    render={({ field }) => (
                      <Autocomplete
                        disableClearable
                        disablePortal
                        {...field}
                        options={businessModelsData}
                        onChange={(e, selectedOptions) =>
                          handleSelectBusiness(selectedOptions)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="What is your business Model? *"
                          />
                        )}
                        disabled={readyOnlyValue}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.businessModel && errors.businessModel.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="unitCosts"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Unit costs is required" }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="What are the unit costs for your product/service? *"
                        placeholder="Enter unit costs"
                        fullWidth
                        inputProps={{ readOnly: readyOnlyValue }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.unitCosts && errors.unitCosts.message}
                  </FormHelperText>
                </div>
              </div>
              {/* <div className='col-lg-4 col-12'>
                                <div className="form-group mt-2">
                                    <div className="form-group mt-2">
                                        <div className="d-flex">
                                            <span className="mt-1">Does your business involve the following (B2B/B2C/B2G)</span>
                                            <span className="ml-2 d-flex justify-content-center align-items-center">
                                                No{" "}
                                                <AntSwitch className="mx-2"
                                                    checked={followBusinessModel}
                                                    disabled={readyOnlyValue}
                                                    onChange={() => handleFilter4(!followBusinessModel)}
                                                    inputProps={{ 'aria-label': 'ant design' }} />
                                                {" "}
                                                Yes{" "}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              <div className="col-lg-4 col-12">
                <div className="form-group mt-2">
                  <div className="form-group mt-2">
                    <div className="d-flex">
                      <span className="mt-1">
                        Are contracts for service/product delivery or one-time
                        sales part of your model?
                      </span>
                      <span className="ml-2 d-flex justify-content-center align-items-center">
                        No{" "}
                        <AntSwitch
                          className="mx-2"
                          checked={deliveryOrOneTimeSalespart}
                          disabled={readyOnlyValue}
                          onChange={() =>
                            handleFilter1(!deliveryOrOneTimeSalespart)
                          }
                          inputProps={{ "aria-label": "ant design" }}
                        />{" "}
                        Yes{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                {/* <div className="form-group mt-2">
                                    <Controller name="contractManufacturing" control={control} defaultValue="" rules={{ required: 'Contract manufacturing is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Do you manufacture components in-house or use contract manufacturing? *"
                                                placeholder='Enter contract manufacturing'
                                                fullWidth
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.contractManufacturing && errors.contractManufacturing.message}</FormHelperText>
                                </div> */}
                <div className="form-group mt-2">
                  <div className="d-flex">
                    <span className="mt-1">
                      Do you manufacture components in-house or use contract
                      manufacturing?
                    </span>
                    <span className="ml-2 d-flex justify-content-center align-items-center">
                      No{" "}
                      {/* <Switch
                                                checked={manufactureComponents}
                                                disabled={readyOnlyValue}
                                                onChange={() => handleFilter2(!manufactureComponents)}
                                                color="primary"
                                            /> */}
                      <AntSwitch
                        className="mx-2"
                        checked={manufactureComponents}
                        disabled={readyOnlyValue}
                        onChange={() => handleFilter2(!manufactureComponents)}
                        inputProps={{ "aria-label": "ant design" }}
                      />{" "}
                      Yes{" "}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                {/* <div className="form-group mt-2">
                                    <Controller name="lettersOfIntent" control={control} defaultValue="" rules={{ required: 'Letters of Intent is required' }}
                                        render={({ field }) =>
                                            <TextField
                                                variant='outlined'
                                                label="Have you secured any Letters of Intent from potential customers? *"
                                                placeholder='Enter Letters of Intent'
                                                fullWidth
                                                inputProps={
                                                    { readOnly: readyOnlyValue, }
                                                }
                                                {...field}
                                            />} />
                                    <FormHelperText className='text-danger'>{errors.lettersOfIntent && errors.lettersOfIntent.message}</FormHelperText>
                                </div> */}
                <div className="form-group mt-2">
                  <div className="d-flex">
                    <span className="mt-1">
                      Have you secured any Letters of Intent from potential
                      customers?
                    </span>
                    <span className="ml-2 d-flex justify-content-center align-items-center">
                      No{" "}
                      {/* <Switch
                                                checked={lettersFromPotentialCustomers}
                                                disabled={readyOnlyValue}
                                                onChange={() => handleFilter3(!lettersFromPotentialCustomers)}
                                                color="primary"
                                            /> */}
                      <AntSwitch
                        className="mx-2"
                        checked={lettersFromPotentialCustomers}
                        disabled={readyOnlyValue}
                        onChange={() =>
                          handleFilter3(!lettersFromPotentialCustomers)
                        }
                        inputProps={{ "aria-label": "ant design" }}
                      />{" "}
                      Yes{" "}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-12 mb-3 pl-3">
                <div className="form-group mt-2">
                  <div className="d-flex">
                    {/* <span className="mt-1">Do you have existing customers?</span> */}
                    <span className="mt-1">
                      Do you have any existing customers for your business or
                      have you secured any customers for your idea? If so,
                      please list at least the top 5 customer names and their
                      location
                    </span>
                    <span className="ml-4 d-flex justify-content-center align-items-center">
                      No{" "}
                      {/* <Switch
                                                checked={existingCustomerPresent}
                                                disabled={readyOnlyValue}
                                                onChange={() => handleFilter(!existingCustomerPresent)}
                                                color="primary"
                                            /> */}
                      <AntSwitch
                        className="mx-2"
                        checked={existingCustomerPresent}
                        disabled={readyOnlyValue}
                        onChange={() => handleFilter(!existingCustomerPresent)}
                        inputProps={{ "aria-label": "ant design" }}
                      />{" "}
                      Yes{" "}
                    </span>
                  </div>
                </div>
              </div>
              {existingCustomerPresent === true && (
                <>
                  {/* <div className="col-lg-4 col-12">
                                        <div className="form-group mt-2">
                                            <Controller
                                                name="customerDetails"
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: "Name & Location is required",
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        variant="outlined"
                                                        label="Name & Location *"
                                                        placeholder="Enter Name & Location"
                                                        fullWidth
                                                        multiline
                                                        rows={3}
                                                        inputProps={{ readOnly: readyOnlyValue }}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                            <FormHelperText className="text-danger">
                                                {errors.customerDetails && errors.customerDetails.message}
                                            </FormHelperText>
                                        </div>
                                    </div> */}
                  <Accordion
                    className="Addfundacc-bor"
                    expanded={isAccordionOpen}
                    onChange={() => setIsAccordionOpen(!isAccordionOpen)}
                  >
                    <AccordionSummary
                      aria-controls="panel1-content"
                      id="panel1-header"
                      className="Addfundacc"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: 0,
                        }}
                      >
                        <AddCircleIcon className="add-icon mr-3" /> List top 5
                        customer names and their location
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="col-12 mb-3">
                          <div>
                            {businessCustomersFields.length >= 0 && (
                              <TableContainer
                                component={Paper}
                                className="table-back"
                              >
                                <Table aria-label="caption table">
                                  <TableBody>
                                    {businessCustomersFields.map(
                                      (field, index, type) => (
                                        <TableRow key={index}>
                                          <TableCell
                                            sx={{
                                              borderBottom: "none",
                                              padding: "5px",
                                            }}
                                          >
                                            {index + 1}
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              width: "21%",
                                              borderBottom: "none",
                                              padding: "5px",
                                            }}
                                          >
                                            <FormControl sx={{ width: "100%" }}>
                                              <Controller
                                                name={`startupBusinessCustomers.${index}.name`}
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                  required: "Name is required",
                                                }}
                                                render={({ field }) => (
                                                  <TextField
                                                    id="outlined-multiline-static"
                                                    label="Name *"
                                                    placeholder="Enter name"
                                                    fullwidth
                                                    {...field}
                                                    inputProps={{
                                                      readOnly: readyOnlyValue,
                                                    }}
                                                  />
                                                )}
                                              />
                                              <FormHelperText className="text-danger">
                                                {errors
                                                  .startupBusinessCustomers?.[
                                                  index
                                                ]?.name &&
                                                  errors
                                                    .startupBusinessCustomers?.[
                                                    index
                                                  ]?.name.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              width: "21%",
                                              borderBottom: "none",
                                              padding: "5px",
                                            }}
                                          >
                                            <FormControl sx={{ width: "100%" }}>
                                              <Controller
                                                name={`startupBusinessCustomers.${index}.phone`}
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                  required:
                                                    "Phone Number is required",
                                                  validate: validatePhoneNumber,
                                                }}
                                                render={({ field }) => (
                                                  <TextField
                                                    id="outlined-multiline-static"
                                                    label="Phone Number*"
                                                    placeholder="Enter phone Number"
                                                    fullwidth
                                                    {...field}
                                                    inputProps={{
                                                      readOnly: readyOnlyValue,
                                                      maxLength: 10,
                                                    }}
                                                    type="text"
                                                    onKeyDown={(e) =>
                                                      validateNumberonly(e)
                                                    }
                                                  />
                                                )}
                                              />
                                              <FormHelperText className="text-danger">
                                                {errors
                                                  .startupBusinessCustomers?.[
                                                  index
                                                ]?.phone &&
                                                  errors
                                                    .startupBusinessCustomers?.[
                                                    index
                                                  ]?.phone.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              width: "21%",
                                              borderBottom: "none",
                                              padding: "5px",
                                            }}
                                          >
                                            <FormControl sx={{ width: "100%" }}>
                                              <Controller
                                                name={`startupBusinessCustomers.${index}.email`}
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                  required: "Email is required",
                                                  validate: validateEmail,
                                                }}
                                                render={({ field }) => (
                                                  <TextField
                                                    id="outlined-multiline-static"
                                                    label="Email *"
                                                    placeholder="Enter email"
                                                    fullwidth
                                                    {...field}
                                                    inputProps={{
                                                      readOnly: readyOnlyValue,
                                                    }}
                                                    onPaste={handlePaste}
                                                    onKeyDown={(e) => {
                                                      if (e.key === " ") {
                                                          e.preventDefault(); // Prevent space key
                                                      }
                                                  }}
                                                  />
                                                )}
                                              />
                                              <FormHelperText className="text-danger">
                                                {errors
                                                  .startupBusinessCustomers?.[
                                                  index
                                                ]?.email &&
                                                  errors
                                                    .startupBusinessCustomers?.[
                                                    index
                                                  ]?.email.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              width: "21%",
                                              borderBottom: "none",
                                              padding: "5px",
                                            }}
                                          >
                                            <FormControl sx={{ width: "100%" }}>
                                              <Controller
                                                name={`startupBusinessCustomers.${index}.location`}
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                  required:
                                                    "Location is required",
                                                }}
                                                render={({ field }) => (
                                                  <TextField
                                                    id="outlined-multiline-static"
                                                    label="Location *"
                                                    placeholder="Enter location"
                                                    fullwidth
                                                    {...field}
                                                    inputProps={{
                                                      readOnly: readyOnlyValue,
                                                    }}
                                                  />
                                                )}
                                              />
                                              <FormHelperText className="text-danger">
                                                {errors
                                                  .startupBusinessCustomers?.[
                                                  index
                                                ]?.location &&
                                                  errors
                                                    .startupBusinessCustomers?.[
                                                    index
                                                  ]?.location.message}
                                              </FormHelperText>
                                            </FormControl>
                                          </TableCell>
                                          {/* <TableCell sx={{ width: '22%', borderBottom: 'none', padding: '5px' }}>
                                                                                        <FormControl sx={{ width: '100%' }} >
                                                                                            <div className="form-group mt-2">
                                                                                                <div className="">
                                                                                                    <span className="mt-1 ml-3">Can be Contact</span>
                                                                                                    <span className=" d-flex justify-content-center align-items-center">
                                                                                                        <AntSwitch className="mt-2"
                                                                                                            checked={canContact}
                                                                                                            disabled={readyOnlyValue}
                                                                                                            onChange={() => handleFilter5(!canContact)}
                                                                                                            inputProps={{ 'aria-label': 'ant design' }} />
                                                                                                        {" "}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </FormControl>
                                                                                    </TableCell> */}
                                          <TableCell
                                            sx={{
                                              width: "21%",
                                              borderBottom: "none",
                                              padding: "5px",
                                            }}
                                          >
                                            <FormControl sx={{ width: "100%" }}>
                                              <Controller
                                                name={`startupBusinessCustomers.${index}.canContact`}
                                                control={control}
                                                defaultValue={false}
                                                render={({
                                                  field: { value, onChange },
                                                }) => (
                                                  <div className="form-group mt-2">
                                                    <div className="">
                                                      <span className="mt-1 ml-3">
                                                        Can be Contacted
                                                      </span>
                                                      <span className="d-flex justify-content-center align-items-center">
                                                        <AntSwitch
                                                          className="mt-2"
                                                          checked={value}
                                                          disabled={
                                                            readyOnlyValue
                                                          }
                                                          onChange={(event) =>
                                                            onChange(
                                                              event.target
                                                                .checked
                                                            )
                                                          }
                                                          inputProps={{
                                                            "aria-label":
                                                              "ant design",
                                                          }}
                                                        />
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                              />
                                            </FormControl>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              borderBottom: "none",
                                              padding: "5px",
                                            }}
                                            align="center"
                                          >
                                            <DeleteIcon
                                              sx={{
                                                backgroudColor: "#F4F4F4",
                                                fontSize: "24px",
                                                fill: "#808080",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                readyOnlyValue === false ? (
                                                  deleteBusinessCustomers(index)
                                                ) : (
                                                  <></>
                                                );
                                              }}
                                            />
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                                <center>
                                  <Button
                                    className="add-more-btn"
                                    disabled={readyOnlyValue}
                                    onClick={() => addBusinessCustomersBtn("")}
                                  >
                                    <AddCircleIcon className="mr-2" />
                                    Add More
                                  </Button>
                                </center>
                              </TableContainer>
                              //     ) : (
                              // <div className='nodata_tag'>
                              //     <p>Fundraising history details is empty</p>
                              // </div>
                            )}
                          </div>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
              <div className="col-lg-6 col-12 mt-2">
                {getValues("solutionProcessDiagName") && (
                  <span>
                    Diagrammatic Representation of your solution process
                  </span>
                )}
                <div className="d-flex align-items-center">
                  <div className="form-group my-1">
                    <Controller
                      name="solutionProcessDiagName"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Diagrammatic representation is required",
                      }}
                      render={({ field }) => (
                        <FileUploader
                          {...field}
                          handleChange={(e) =>
                            handleFileUpload(
                              e,
                              "solutionProcessDiag",
                              "solutionProcessDiagName",
                              "solutionProcessDiagURL",
                              "file"
                            )
                          }
                          value={getValues("solutionProcessDiagName")}
                          name="file"
                          types={fileTypes}
                          children={
                            <div
                              className={
                                getValues("solutionProcessDiagName")
                                  ? "fileupload-pdf-dpiit-bg-active"
                                  : "fileupload-pdf-dpiit-bg"
                              }
                            >
                              <center>
                                <p
                                  className={
                                    getValues("solutionProcessDiagName")
                                      ? "fileupload-pdf-active"
                                      : "fileupload-pdf"
                                  }
                                >
                                  <span>
                                    <AttachFileIcon className="file-icon mr-2" />
                                    {getValues("solutionProcessDiagName")
                                      ? "Uploaded (Click to Change)"
                                      : "Diagrammatic Representation of your solution process *"}
                                  </span>
                                </p>
                              </center>
                            </div>
                          }
                          disabled={readyOnlyValue}
                        />
                      )}
                    />
                    <FormHelperText className="ml-2">
                      Maximum size: 5 MB. File format .jpg, .png, .jpeg
                    </FormHelperText>
                    <FormHelperText
                      sx={{ marginLeft: "5px" }}
                      className="text-danger"
                    >
                      {errors.solutionProcessDiagName &&
                        errors.solutionProcessDiagName.message}
                    </FormHelperText>
                  </div>
                  <span
                    className="pre-btn-dpiit"
                    onClick={() =>
                      handleClickOpen(getValues("solutionProcessDiagURL"))
                    }
                  >
                    <VisibilityIcon className="icon-size mr-1" />
                    <Link style={{ color: "#80809e" }}> Preview</Link>
                  </span>
                </div>
              </div>
              <div className="col-lg-6 col-12 mt-2"></div>
              {getValues('lettersFromPotentialCustomers') === true && (
              <div className="col-lg-12 col-12 mt-2">
              {/* {
                                  getValues('letterOfIntentName') && (
                                      <span>Letter of Intent</span>
                                  )
                              } */}
              <span>
                Please share any Letter of Intent you may have secured from
                any potential customer for your service/product.
              </span>
              <div className="d-flex align-items-center">
                <div className="form-group my-1">
                  <Controller
                    name="letterOfIntentName"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Letter of Intent is required" }}
                    render={({ field }) => (
                      <FileUploader
                        {...field}
                        handleChange={(e) =>
                          handleFileUpload(
                            e,
                            "letterOfIntent",
                            "letterOfIntentName",
                            "letterOfIntentURL",
                            "pdf"
                          )
                        }
                        value={getValues("letterOfIntentName")}
                        name="file"
                        types={fileTypesPDF}
                        children={
                          <div
                            className={
                              getValues("letterOfIntentName")
                                ? "fileupload-pdf-dpiit-bg-active"
                                : "fileupload-pdf-dpiit-bg"
                            }
                          >
                            <center>
                              <p
                                className={
                                  getValues("letterOfIntentName")
                                    ? "fileupload-pdf-active"
                                    : "fileupload-pdf"
                                }
                              >
                                <span>
                                  <AttachFileIcon className="file-icon mr-2" />
                                  {getValues("letterOfIntentName")
                                    ? "Uploaded (Click to Change)"
                                    : "Letter of Intent *"}
                                </span>
                              </p>
                            </center>
                          </div>
                        }
                        disabled={readyOnlyValue}
                      />
                    )}
                  />
                  <FormHelperText className="ml-2">
                    Maximum size: 5 MB. File format .pdf
                  </FormHelperText>
                  <FormHelperText
                    sx={{ marginLeft: "5px" }}
                    className="text-danger"
                  >
                    {errors.letterOfIntentName &&
                      errors.letterOfIntentName.message}
                  </FormHelperText>
                </div>
                <span
                  className="pre-btn-dpiit"
                  onClick={() =>
                    handleClickOpen(getValues("letterOfIntentURL"))
                  }
                >
                  <VisibilityIcon className="icon-size mr-1" />
                  <Link style={{ color: "#80809e" }}> Preview</Link>
                </span>
              </div>
            </div>
             ) }
              
              <div className="col-12 mt-2"></div>
            </div>
            <div className="form-group text-right">
              <Button
                variant="contained"
                onClick={handleBackapp}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyValue === false ? (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </LoadingButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
      </section>
      <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
    </>
  );
};

export default BusinessStrategyScst;
