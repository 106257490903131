import React, { useContext, useState } from 'react'
import { Autocomplete, FormControl, Button, TextField, Grid, } from "@mui/material";
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import apiService from '../../../api/apiService';
import DataContext from '../../../context/DataContext';


const NewReportFilters = ({handleClear,scsthub, setScsthub,yearNameSelected, setYearNameSelected,yearName, setYearName}) => {
    const { setPageLoading } = useContext(DataContext)
      const [hubList, setHubList] = useState([""]);
    useDidMountEffect(() => {
        getScstHub()
        yearApi(2021);
    }, []);

    const getScstHub = async () => {
        await apiService(`partner/hub/filter`, "", "get")
            .then((res) => {
                if (res.data) {
                    var hub = res.data
                    hub.unshift('All')
                    setHubList(hub);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleScstHub = (value) => {
        setScsthub(value);
    };
    const [filteredYears, setFilteredYears] = useState([]);
    const yearApi = () => {
        apiService(`common/dashboard/listyears?startyear=2021`, "", "get").then(
            (res) => {
                setPageLoading(false);
                if (res && res.data && res.data.yearMap) {
                    var yearMap = res.data.yearMap;

                    var allYears = Object.keys(yearMap).map((year) => ({
                        label: year,
                        value: yearMap[year]
                    }));

                    allYears.unshift({ label: "All", value: 0 });

                    setFilteredYears(allYears);
                }
            }
        );
    };
    // const handleSelectYear = (event, selectedOption) => {
    //     setYearNameSelected(selectedOption);

    //     if (selectedOption && selectedOption !== 'All') {
    //         var selectedYear = filteredYears.find(year => year.label === selectedOption);

    //         if (selectedYear) {
    //             setYearName(selectedYear.label);
    //             yearApi(selectedYear.value);
    //         }
    //     } else {
    //         setYearName('0');
    //         yearApi(2021);

    //     }
    // };
    const handleSelectYear = (event, selectedOption) => {
        setYearName(selectedOption);
        setYearNameSelected(selectedOption)
        if (selectedOption) {
            var name=selectedOption.split(' - ')
            setYearName(name[0]);
        } else {
            setYearName('2024');

        }
        debugger
    };
    
    return (
        <div>
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disableClearable
                            disablePortal
                            value={scsthub}
                            options={hubList}
                            onChange={(e, selectedOptions) =>
                                handleScstHub(selectedOptions)
                            }
                            renderInput={(params) => (
                                <TextField {...params} label="Hub" fullWidth />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disableClearable
                            disablePortal
                            value={yearNameSelected}
                            options={filteredYears.map((year) => year.label)}
                            onChange={handleSelectYear}
                            renderInput={(params) => (
                                <TextField {...params} label="Financial Year" fullWidth />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                    <div>
                            <Button
                                variant="contained"
                                sx={{ marginRight: "10px" }}
                                onClick={() => handleClear()}
                            >
                                Clear
                            </Button>
                    </div>
                </Grid>
            </Grid>
        </div >
    )
}


export default NewReportFilters

