import React, { useContext, useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Autocomplete,
  FormControl,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  InputBase,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import axios from "axios";

const SearchinputDiv = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  color: "black",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "17ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const BddOnboarded = ({
  setPage,
  districtName,
  setDistrictName,
  scsthub,
  setScsthub,
  yearName,
  setYearName,
  monthName,
  setMonthName,
  manualName,
  toYearName,
  setToYearName,
  setManualName,
  path,
  setSearchInputValue,
  searchInputValue,
  phaseValue,
  setPhaseValue,
  statusValue,
  setStatusValue,
  lblName,
  toMonthName,
  setToMonthName,
}) => {
  const roles = localStorage.getItem("role");
  const {
    yearListApi,
    yearListValue,
    setSelectedYear,
    scstBDDRemoveFiters,
    endYearListValue,
    setNotificationChange,
  } = useContext(DataContext);
  const [cityList, setCityList] = useState([]);
  const [hubList, setHubList] = useState([]);

  useDidMountEffect(() => {
    yearListApi();
    getCityApi();
    getScstHub();
    handleSelectYear(yearName, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputValue]);
  const getCityApi = async () => {
    var role = localStorage.getItem("role");
    let url;
    if (role === "SCSTHUB") {
      url = "admin/district/get";
    } else {
      url = `admin/dropdownvalues/get?key=district`;
    }
    await apiService(url, "", "get").then((res) => {
      if (res && res.data) {
        if (role === "SCSTHUB") {
          setCityList(res.data);
        } else if (res.data.dropDownValues) {
          var val = res.data.dropDownValues;
          setCityList(val.districts);
        }
      }
    });
  };
  const getScstHub = async () => {
    await apiService(`partner/hub/filter`, "", "get")
      .then((res) => {
        setHubList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSelectCity = (value) => {
    setDistrictName(value);
    localStorage.setItem("districtNameBDDStartup", value);
    localStorage.setItem("pageBDDStartup", 0);
  };

  const handleScstHub = (value) => {
    setScsthub(value);
    localStorage.setItem("scsthubBDDStartup", value);
    localStorage.setItem("pageBDDStartup", 0);
  };
  let month = [
    "All",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let endmonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [monthList, setMonthList] = useState(month);

  const handleSelectYear = (value, change) => {
    let year = new Date().getFullYear();
    const currentMonthIndex = new Date().getMonth();
    setYearName(value);
    setSelectedYear(value);
    localStorage.setItem("yearNameBDDStartup", value);
    localStorage.setItem("pageBDDStartup", 0);
    setPage(0);
    let yearValue;
    if (typeof value === "string") {
      setMonthName(0);
      setSelectedMonth(monthList[0]);
      yearValue = Number(value);
    } else {
      yearValue = value;
      setSelectedMonth(monthList[monthName]);
    }
    if (year === yearValue) {
      let valPush = [];
      for (let i = 0; i <= currentMonthIndex + 1; i++) {
        var element = monthList[i];
        valPush.push(element);
      }
      setMonthList(valPush);
      let vallastPush = [];
      for (let i = 0; i <= currentMonthIndex + 1; i++) {
        var element = endmonth[i];
        vallastPush.push(element);
      }
      setSelectedEndMonthList(vallastPush);
    } else {
      setMonthList(month);
      setSelectedEndMonthList(endmonth);
    }
    if (value) {
      const selectedIndex = endYearListValue.indexOf(value.toString());
      var endyear = [];
      for (let i = 0; i < selectedIndex + 1; i++) {
        endyear.push(endYearListValue[i]);
      }
      setFilteredYears(endyear);
    } else {
      setFilteredYears(endYearListValue);
    }
    if (value === "All") {
      setToMonthName("");
    }
    setToYearName("");
  };
  const [selectedMonth, setSelectedMonth] = useState(monthList[0]);
  const handleSelectMonth = (e, value) => {
    const index = monthList.indexOf(value);
    setSelectedMonth(value);
    setMonthName(index);
    localStorage.setItem("monthNameBDDStartup", index);
    localStorage.setItem("pageBDDStartup", 0);
  };

  useDidMountEffect(() => {
    scstFilterApi();
  }, [phaseValue]);
  const [phaseName, setPhaseName] = useState([]);
  const scstFilterApi = () => {
    axios
      .get("/json/scstfilter.json", "")
      .then((result) => {
        setPhaseName(result.data);
        debugger
        if (phaseValue) {
          handlePhaseFiter(phaseValue, result.data);
        }
        debugger
        // let filteredPhases = result.data;
        if (roles === 'SCSTADMIN' || roles === 'SCSTHUBAVP') {
          debugger
         const filteredPhases = result.data.map(phase => ({
            ...phase,
            stageList: phase.stageList.filter(stage => stage.stageId !== 9 && stage.stageId !== 12) // Remove stageId 12
          }));
          console.log(filteredPhases);
          handlePhaseFiter(phaseValue, filteredPhases);
        }
       
        
        debugger
        // if (roles === "BDD") {
        //   debugger
        //   const filteredPhases = result.data.map(phase => ({
        //     ...phase, 
        //     stageList: phase.stageList.filter(stage => stage.stageId !== 12) // Remove stageId 12
        //   }));
        
        //   handlePhaseFiter(filteredPhases,result.data);
        // }
      })
      .catch((err) => { });
  };
  const [stageList, setStageList] = useState([]);
  const handlePhaseFiter = (value, phaseList) => {
    if (phaseList === "click") {
      localStorage.removeItem("statusValueBDDStartup");
    }
    setStageList([]);
    setPhaseValue(value);

    localStorage.setItem("phaseValueBDDStartup", value);
    let valueFilter;
    if (phaseList !== "click") {
      valueFilter = phaseList;
    } else {
      valueFilter = phaseName;
    }
    if (value !== "All") {
      var statusData = valueFilter.filter((phase) => phase.phaseName === value);
      if (statusData && statusData[0].stageList) {
        setStageList(statusData[0].stageList);
        var statusValueScst = localStorage.getItem("statusValueBDDStartup");

        if (statusValueScst === null) {
          localStorage.setItem(
            "statusValueBDDStartup",
            statusData[0].stageList[0].stageId
          );

          setStatusValue(statusData[0].stageList[0].stageId);
        } else {
          setStatusValue(statusValueScst);
        }
      }
    } else {
      if (roles === "BDD") {
        if (roles === "BDD") {
          var statusValueScst = Number(localStorage.getItem("statusValueBDDStartup"));
          handleStatusFiter(statusValueScst ? statusValueScst : 101);
          // handleStatusFiter(101);
        } else {
          handleStatusFiter(100);
        }
      } else if (roles === "IC") {
        if (roles === "IC") {
          handleStatusFiter(55);
        } else {
          handleStatusFiter(100);
        }
      } else if (roles === "MENTORSHIP") {
        if (roles === "MENTORSHIP") {
          handleStatusFiter(52);
        } else {
          handleStatusFiter(100);
        }
      } else if (roles === "PSC") {
        if (roles === "PSC") {
          handleStatusFiter(56);
        } else {
          handleStatusFiter(100);
        }
      } else if (roles === "LDD") {
        if (roles === "LDD") {
          handleStatusFiter(57);
        } else {
          handleStatusFiter(100);
        }
      } else if (roles === "FDD") {
        if (roles === "FDD") {
          handleStatusFiter(57);
        } else {
          handleStatusFiter(100);
        }
      }
    }
  };
  const handleStatusFiter = (value) => {
    setStatusValue(value);
    localStorage.setItem("statusValueBDDStartup", value);
    // localStorage.setItem('pageScst', 0)
    setPage(0);
  };

  // const handleStatusFiter = (value) => {
  //     setStatusValue(value)
  //     localStorage.setItem('statusValueBDDStartup', value)
  // }
  const d = new Date();
  let year = d.getFullYear();
  const handleClear = () => {
    setNotificationChange(false);
    setDistrictName("");
    setScsthub("");
    setMonthName(0);
    setSelectedMonth("All");
    setYearName("2022");
    setManualName(0);
    setToYearName("");
    scstBDDRemoveFiters();
    setPhaseValue("All");
    setStageList([]);
    handleSelectYear("2022", "");
    if (roles === "BDD") {
      setStatusValue(101);
      localStorage.setItem("statusValueBDDStartup", 101);
      localStorage.setItem("phaseValueBDDStartup", 'All');
    } else if (roles === "IC") {
      setStatusValue(55);
      localStorage.setItem("statusValueBDDStartup", 55);
    } else if (roles === "PSC") {
      setStatusValue(56);
      localStorage.setItem("statusValueBDDStartup", 56);
    } else if (roles === "MENTORSHIP") {
      setStatusValue(52);
      localStorage.setItem("statusValueBDDStartup", 52);
    } else if (roles === "LDD") {
      setStatusValue(57);
      localStorage.setItem("statusValueBDDStartup", 57);
    } else if (roles === "FDD") {
      setStatusValue(57);
      localStorage.setItem("statusValueBDDStartup", 57);
    } else {
      setStatusValue(100);
      localStorage.setItem("statusValueBDDStartup", 100);
    }
    // if (path === 'scstbdd') {
    //     setStatusValue(3)
    // } else if (path === 'ic') {
    //     setStatusValue(11)
    // } else if (path === 'psc') {
    //     setStatusValue(14)
    // } else {
    //     setStatusValue(3)
    // }
  };

  const handleSearch = (searchValue) => {
    setSearchInputValue(searchValue);
    localStorage.setItem("searchInputValueBDDStartup", searchValue);
    localStorage.setItem("pageBDDStartup", 0);
  };
  const [filteredYears, setFilteredYears] = useState([]);
  // const [selectedMonth, setSelectedMonth] = useState(monthList[0])
  const [selectedToMonth, setSelectedToMonth] = useState(monthList[12]);
  const [selectedEndMonthList, setSelectedEndMonthList] = useState(endmonth);
  const handelEndYear = (value) => {
    setToYearName(value);
    let year = new Date().getFullYear();
    if (year === Number(value)) {
      let index = new Date().getMonth();
      var month = endmonth;
      var endmonths = [];
      for (let i = 0; i < index + 1; i++) {
        endmonths.push(month[i]);
      }
      setSelectedEndMonthList(endmonths);
      setToMonthName(index + 1);
      setSelectedToMonth(endmonth[index]);
    } else {
      setSelectedEndMonthList(endmonth);
      setToMonthName(12);
      setSelectedToMonth(endmonth[11]);
    }

    localStorage.setItem("toYearNameBDDStartup", value);
    localStorage.setItem("pageBDDStartup", 0);
  };
  const handleSelectToMonth = (e, value) => {
    const index = selectedEndMonthList.indexOf(value);
    setToMonthName(index);
    setSelectedToMonth(value);
    localStorage.setItem("toYearNameBDDStartup", index);
    localStorage.setItem("pageBDDStartup", 0);
  };
  return (
    <div>
      <div className="header_tag">
        <h5 className="mb-0">{lblName}</h5>
        <div className="ml-auto d-flex align-items-center">
          <SearchinputDiv
            sx={{ display: { xs: "none", sm: "block" } }}
            onChange={(e) => handleSearch(e.target.value)}
          >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search by Name"
              type="search"
              inputProps={{ "aria-label": "Search by Name" }}
            />
          </SearchinputDiv>
          <Button
            className="ml-3"
            variant="outlined"
            type="button"
            data-toggle="collapse"
            data-target="#collapseWidthExample"
          >
            <FilterAltIcon />
            Filters
          </Button>
        </div>
      </div>
      <div className="d-flex">
        {/* {
                    (path !== 'jury/scst') && (
                        <div className=" my-2">
                            <Button
                                variant="outlined"
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseWidthExample"
                            >
                                <FilterAltIcon />
                                Filters
                            </Button>
                        </div>
                    )
                } */}
        <div className="ml-auto my-2"></div>
      </div>
      {/* filter code */}
      <div className="collapse show mt-3 mb-3" id="collapseWidthExample">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-2">
            <div className="form-group">
              <FormControl fullWidth>
                <Autocomplete
                  disableClearable
                  disablePortal
                  value={districtName}
                  options={cityList}
                  onChange={(e, selectedOptions) =>
                    handleSelectCity(selectedOptions)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Startup District" fullWidth />
                  )}
                />
              </FormControl>
            </div>
          </div>
          {path !== "validation" && path !== "evaluation" && (
            <div className="col-lg-2">
              <div className="form-group">
                <FormControl fullWidth>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    value={scsthub}
                    options={hubList}
                    onChange={(e, selectedOptions) =>
                      handleScstHub(selectedOptions)
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Hub" fullWidth />
                    )}
                  />
                </FormControl>
              </div>
            </div>
          )}
          <div className="col-lg-2">
            <div className="form-group">
              <FormControl fullWidth>
                <Autocomplete
                  disableClearable
                  disablePortal
                  value={yearName}
                  options={yearListValue}
                  onChange={(e, selectedOptions) =>
                    handleSelectYear(selectedOptions)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="From Year" fullWidth />
                  )}
                />
              </FormControl>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="form-group">
              <FormControl fullWidth>
                <Autocomplete
                  disableClearable
                  disablePortal
                  value={selectedMonth}
                  options={monthList}
                  onChange={(e, selectedOptions) =>
                    handleSelectMonth(e, selectedOptions)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="From Month" fullWidth />
                  )}
                />
              </FormControl>
            </div>
          </div>
          {yearName !== "All" && (
            <div className="col-lg-2">
              <div className="form-group">
                {/* Second Autocomplete */}
                <FormControl fullWidth>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    value={toYearName}
                    options={filteredYears}
                    onChange={(event, newValue) => handelEndYear(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} label="To Year" fullWidth />
                    )}
                  />
                </FormControl>
              </div>
            </div>
          )}
          {toYearName !== "" && (
            <div className="col-lg-2">
              <div className="form-group">
                <FormControl fullWidth>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    value={selectedToMonth}
                    options={selectedEndMonthList}
                    onChange={(e, selectedOptions) =>
                      handleSelectToMonth(e, selectedOptions)
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="To Month" fullWidth />
                    )}
                  />
                </FormControl>
              </div>
            </div>
          )}
          {/* <div className="col-lg-2">
                        <div className="form-group">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-filled-label">Onboarded Type</InputLabel>
                                <Select

                                    onChange={(e) => handleMenualFiter(e.target.value)}
                                    value={manualName}
                                >
                                    <MenuItem value={0}>Both</MenuItem>
                                    <MenuItem value={1}>Through Manual</MenuItem>
                                    <MenuItem value={2}>Through Portal</MenuItem>
                                </Select>


                            </FormControl>
                        </div>
                    </div> */}
          {/* {path === "scstmentorship" && (
            <div className="col-lg-2">
              <div className="form-group">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">
                    Status
                  </InputLabel>
                  <Select
                    onChange={(e) => handleStatusFiter(e.target.value)}
                    value={statusValue}
                  >
                    <MenuItem value={52}>All</MenuItem>
                    <MenuItem value={1}>Validation Approved</MenuItem>
                    <MenuItem value={3}>Recommended to BDD</MenuItem>
                    <MenuItem value={4}>
                      Recommended to Mentorship / Other Schemes
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          )} */}
          {roles === "BDD" && (
            <>
              <div className="col-lg-2">
                <div className="form-group">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-filled-label">
                      Phase
                    </InputLabel>
                    <Select
                      onChange={(e) =>
                        handlePhaseFiter(e.target.value, "click")
                      }
                      value={phaseValue}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      <MenuItem value={"BDD First Connect"}>
                        BDD First Connect
                      </MenuItem>
                      <MenuItem value={"BDD Deep Dive"}>BDD Deep Dive</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              {phaseValue !== "All" && (
                <div className="col-lg-3">
                  <div className="form-group">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-filled-label">
                        Status
                      </InputLabel>
                      <Select
                        onChange={(e) => handleStatusFiter(e.target.value)}
                        value={statusValue}
                      >
                        {stageList.map((stage, index) => (
                          <MenuItem value={stage.stageId} key={index}>
                            {stage.stageName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
            </>
          )}
          {
            (roles === 'SCSTADMIN' || roles === 'SCSTHUBAVP' || roles === 'MENTORSHIP') && (
              <>
                <div className="col-lg-2">
                  <div className="form-group">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-filled-label">Phase</InputLabel>
                      <Select
                        onChange={(e) => handlePhaseFiter(e.target.value, "click")}
                        value={phaseValue}
                      >
                        <MenuItem value={'All'}>All</MenuItem>
                        {
                          phaseName.map((phase, index) => (
                            <MenuItem value={phase.phaseName}>{phase.phaseName}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </div>
                </div>


              </>
            )
          }
          {/* {
                        phaseValue !== 'All' && (
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-filled-label">Status</InputLabel>
                                        <Select
                                            onChange={(e) => handleStatusFiter(e.target.value)}
                                            value={statusValue}
                                        >
                                            {
                                                stageList.map((stage, index) => (
                                                    <MenuItem value={stage.stageId}>{stage.stageName}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        )
                    } */}

          {path === "ic" && (
            <div className="col-lg-2">
              <div className="form-group">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">
                    Status
                  </InputLabel>
                  <Select
                    onChange={(e) => handleStatusFiter(e.target.value)}
                    value={statusValue}
                  >
                    <MenuItem value={55}>All</MenuItem>
                    <MenuItem value={11}>Approved for IC</MenuItem>

                    <MenuItem value={29}>IC Rejected</MenuItem>
                    {/* <MenuItem value={13}>IC Report under Review</MenuItem> */}
                    <MenuItem value={13}>Approved for PSC</MenuItem>
                    <MenuItem value={14}>IC Recommended to Mentorship</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
          {path === "psc" && (
            <div className="col-lg-2">
              <div className="form-group">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">
                    Status
                  </InputLabel>
                  <Select
                    onChange={(e) => handleStatusFiter(e.target.value)}
                    value={statusValue}
                  >
                    <MenuItem value={56}>All</MenuItem>
                    <MenuItem value={13}>Approved for PSC</MenuItem>
                    <MenuItem value={15}>PSC Rejected</MenuItem>
                    <MenuItem value={16}>Approved for DD</MenuItem>
                    <MenuItem value={30}>PSC Recommended to Other Schemes</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
          {path === "scstldd" && (
            <div className="col-lg-2">
              <div className="form-group">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">
                    Status
                  </InputLabel>
                  <Select
                    onChange={(e) => handleStatusFiter(e.target.value)}
                    value={statusValue}
                  >
                    <MenuItem value={57}>All</MenuItem>
                    <MenuItem value={16}>DD Details Pending For Startup</MenuItem>
                    <MenuItem value={17}>
                      DD Details Submitted by Startup
                    </MenuItem>
                    <MenuItem value={18}>DD Report under Review</MenuItem>
                    <MenuItem value={19}>Recommended to Other Schemes</MenuItem>
                    <MenuItem value={20}>Approved for CP</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
          {path === "scstfdd" && (
            <div className="col-lg-2">
              <div className="form-group">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">
                    Status
                  </InputLabel>
                  <Select
                    onChange={(e) => handleStatusFiter(e.target.value)}
                    value={statusValue}
                  >
                    <MenuItem value={57}>All</MenuItem>
                    <MenuItem value={16}>DD Details Pending For Startup</MenuItem>
                    <MenuItem value={17}>
                      DD Details Submitted by Startup
                    </MenuItem>
                    <MenuItem value={18}>DD Report under Review</MenuItem>
                    <MenuItem value={19}>Recommended to Other Schemes</MenuItem>
                    <MenuItem value={20}>Approved for CP</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
        </div>
        <div className="mt-3 mb-3">
          <center>
            <Button
              variant="contained"
              sx={{ marginRight: "10px" }}
              onClick={() => handleClear()}
            // data-toggle="collapse"
            // data-target="#collapseWidthExample"
            >
              Clear
            </Button>
            {/* <Button variant="contained" onClick={handleFilter}>
                            Filter
                        </Button> */}
          </center>
        </div>
      </div>
    </div>
  );
};

export default BddOnboarded;
