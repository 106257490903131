import React, { useContext, useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import apiService from "../../../api/apiService";
import DataContext from "../../../context/DataContext";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import BddOnboarded from "./BddOnboarded";
import CustomLink from "../../common-page/CustomLink";

const BddStartupsOnboarded = ({ role, name }) => {
  const { setPageLoading, tableIndexValue, notificationChange } =
    useContext(DataContext);
  const [count, setCount] = useState(0);

  var pageBDDStartup = Number(localStorage.getItem("pageBDDStartup"));
  const [page, setPage] = useState(pageBDDStartup ? pageBDDStartup : 0);

  var listSizeBDDStartup = Number(localStorage.getItem("listSizeBDDStartup"));
  const [rowsPerPage, setRowsPerPage] = useState(
    listSizeBDDStartup ? listSizeBDDStartup : 10
  );

  var districtNameBDDStartup = localStorage.getItem("districtNameBDDStartup");
  const [districtName, setDistrictName] = useState(
    districtNameBDDStartup ? districtNameBDDStartup : ""
  );

  var scsthubBDDStartup = localStorage.getItem("scsthubBDDStartup");
  const [scsthub, setScsthub] = useState(
    scsthubBDDStartup ? scsthubBDDStartup : ""
  );
  const d = new Date();
  let year = d.getFullYear();
  var yearNameBDDStartup = Number(localStorage.getItem("yearNameBDDStartup"));
  const [yearName, setYearName] = useState(
    yearNameBDDStartup ? yearNameBDDStartup : "2022"
  );
  var toYearNameBDDStartup = Number(
    localStorage.getItem("toYearNameBDDStartup")
  );
  const [toYearName, setToYearName] = useState(
    toYearNameBDDStartup ? toYearNameBDDStartup : ""
  );
  var monthNameBDDStartup = Number(localStorage.getItem("monthNameBDDStartup"));
  const [monthName, setMonthName] = useState(
    monthNameBDDStartup ? monthNameBDDStartup : 0
  );

  var toMonthNameBDDStartup = Number(
    localStorage.getItem("toMonthNameBDDStartup")
  );
  const [toMonthName, setToMonthName] = useState(
    toMonthNameBDDStartup ? toMonthNameBDDStartup : ""
  );

  var manualNameBDDStartup = Number(
    localStorage.getItem("manualNameBDDStartup")
  );
  const [manualName, setManualName] = useState(
    manualNameBDDStartup ? manualNameBDDStartup : 0
  );

  var searchInputValueBDDStartup = localStorage.getItem(
    "searchInputValueBDDStartup"
  );
  const [searchInputValue, setSearchInputValue] = useState(
    searchInputValueBDDStartup ? searchInputValueBDDStartup : ""
  );

  var phaseValueBDDStartup = localStorage.getItem("phaseValueBDDStartup");
  const [phaseValue, setPhaseValue] = useState("All");
  // console.log(phaseValueScst);

  var statusValueBDDStartup = Number(
    localStorage.getItem("statusValueBDDStartup")
  );
  const [statusValue, setStatusValue] = useState(
    statusValueBDDStartup ? statusValueBDDStartup : 100
  );

  const roles = localStorage.getItem("role");
  useDidMountEffect(() => {
    setSearchInputValue("");
  }, []);
 
  useDidMountEffect(() => {
    if (roles === "BDD") {
      setPhaseValue("BDD First Connect");
    } else {
      setPhaseValue(phaseValueBDDStartup ? phaseValueBDDStartup : "All");
    }

    if (roles === "IC") {
      setPhaseValue("All");
    } else if (roles === "PSC") {
      setPhaseValue("All");
    } else if (roles === "LDD") {
      setPhaseValue("LDD");
    } else if (roles === "FDD") {
      setPhaseValue("FDD");
    } else {
      setPhaseValue(phaseValue ? phaseValue : "All");
    }
  }, []);

  useDidMountEffect(() => {
    var phaseValueBDDStartup = localStorage.getItem("phaseValueBDDStartup");
    setPhaseValue(phaseValueBDDStartup);
    var statusValueBDDStartup = Number(
      localStorage.getItem("statusValueBDDStartup")
    );
    setStatusValue(statusValueBDDStartup);
  }, [notificationChange]);

  useDidMountEffect(() => {
    (async () => await scstBddlist())();
    // eslint-disable-next-line
  }, [
    page,
    rowsPerPage,
    districtName,
    scsthub,
    yearName,
    toYearName,
    monthName,
    toMonthName,
    manualName,
    searchInputValue,
    phaseValue,
    statusValue,
  ]);

  const [scstBddList, setScstBddList] = useState([]);
  const scstBddlist = () => {
    setPageLoading(true);
    setScstBddList([]);
    var req = {
      listSize: rowsPerPage,
      pageNumber: page + 1,
      district: districtName,
      hub: scsthub,
      // year: yearName === 'All' ? 0 : yearName,
      fromYear: yearName === "All" ? 0 : yearName,
      toYear: toYearName === "All" ? 0 : toYearName,
      // month: monthName,
      fromMonth: monthName,
      toMonthName: toMonthName,
      searchString: searchInputValue,
      status: statusValue,
    };
    apiService("partner/scst/admin/startups/list", req, "post").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        setCount(res.data.count);
        if (res.data.startups) {
          setScstBddList(res.data.startups);
        } else {
          setScstBddList([]);
        }
      }
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem("pageNumberBDDStartup", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem(
      "listSizeBDDStartup",
      parseInt(event.target.value, 10)
    );
    setPage(0);
    localStorage.setItem("pageNumberBDDStartup", 0);
  };
  useDidMountEffect(() => {
    if (role === "BDD") {
      debugger
      var statusValueScst = Number(localStorage.getItem("statusValueBDDStartup"));
      setStatusValue(statusValueScst ? statusValueScst : 101);
    }
    
    debugger
  }, [notificationChange]);
  return (
    <div>
      <BddOnboarded
        setPage={setPage}
        districtName={districtName}
        setDistrictName={setDistrictName}
        scsthub={scsthub}
        setScsthub={setScsthub}
        yearName={yearName}
        setYearName={setYearName}
        toYearName={toYearName}
        setToYearName={setToYearName}
        monthName={monthName}
        setMonthName={setMonthName}
        manualName={manualName}
        setManualName={setManualName}
        toMonthName={toMonthName}
        setToMonthName={setToMonthName}
        path={role}
        searchInputValue={searchInputValue}
        setSearchInputValue={setSearchInputValue}
        phaseValue={phaseValue}
        setPhaseValue={setPhaseValue}
        statusValue={statusValue}
        setStatusValue={setStatusValue}
        lblName={name}
      />
      <div className="table_tag">
        {scstBddList.length ? (
          <TableContainer component={Paper}>
            <TablePagination
              component="div"
              count={count}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ float: "left" }}
            />
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Startup Name</TableCell>
                  <TableCell>District</TableCell>
                  <TableCell>Assigned to hub</TableCell>
                  <TableCell>Entity</TableCell>
                  <TableCell>Onboarded Type</TableCell>
                  <TableCell>Date</TableCell>
                  {/* <TableCell align='center'>Status</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {scstBddList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {tableIndexValue(rowsPerPage, page, index)}
                    </TableCell>
                    <TableCell>
                      <CustomLink
                        to={`/${role}/startups/${btoa(row.startupId)}/${btoa(
                          row.partnerOrInvestorId
                        )}`}
                      >
                        {row.startupName}
                      </CustomLink>
                      {/* {row.startupName} */}
                    </TableCell>
                    {/* <TableCell>{row.startupName}</TableCell> */}
                    <TableCell>{row.district}</TableCell>
                    <TableCell>{row.investorName}</TableCell>
                    <TableCell>{row.entityType}</TableCell>
                    <TableCell>
                      {row.onboardType === 1 ? "Manual" : "Portal"}
                    </TableCell>
                    <TableCell>{row.date}</TableCell>
                    {/* <TableCell align='center'>{row.status}</TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
              <caption>
                <TablePagination
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  style={{ float: "left" }}
                />
              </caption>
            </Table>
          </TableContainer>
        ) : (
          <div className="nodata_tag">
            <img
              src="/images/Tanfundlogo-black-1.png"
              className="no_data_logo"
              alt=""
            />
            <h4>Startup list is empty</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default BddStartupsOnboarded;
