import React, { useState } from "react";
import Viewimage from "../../../../../api/Viewimage";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import * as pdfjsLib from "pdfjs-dist";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import {
  Worker,
  Viewer,
  SpecialZoomLevel,
  Icon,
  MinimalButton,
  Position,
  Tooltip,
} from "@react-pdf-viewer/core";
const Documentsupload = ({ details }) => {
  const pdfUrl = `data:image/pdf;base64,${details.pitchDeck}`;
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    setViewImage(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const workerSrc = ` https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
  const disableScrollPlugin = () => {
    const renderViewer = ({ slot }) => {
      if (slot.subSlot && slot.subSlot.attrs && slot.subSlot.attrs.style) {
        slot.subSlot.attrs.style = {
          ...slot.subSlot.attrs.style,
          overflow: "hidden",
        };
      }

      return slot;
    };

    return {
      renderViewer,
    };
  };

  const disableScrollPluginInstance = disableScrollPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { GoToPreviousPage, GoToNextPage } = pageNavigationPluginInstance;
  return (
    <main>
      <section>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="d-flex">
                  <label className="mr-3">
                    Elevator Pitch of Your Startup :
                  </label>
                  <p className="ml-auto">
                    <b>{details.elevatorPitch ? details.elevatorPitch : `-`}</b>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="d-flex">
                  <label className="mr-3">Video Pitch/Product Demo :</label>
                  <p className="ml-auto">
                    <b>
                      {details.videoPitchLink ? details.videoPitchLink : `-`}
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              {/* {
                details.pitchDeck && (
                  <div className='col-lg-6 col-12'>
                    <div className='d-flex'>
                      <label className='mr-3'>PitchDeck :</label>
                      <p className='ml-auto'>
                        <span className='viewdetails' onClick={() => { handleClickOpen(details.pitchDeck) }}>
                          View Document
                        </span>
                      </p>
                    </div>
                  </div>
                )
              } */}

              {details.financialProjectionsName && (
                <div className="col-lg-6 col-12">
                  <div className="d-flex">
                    <label className="mr-3">Financial Projections :</label>
                    <p className="ml-auto">
                      <span
                        className="viewdetails"
                        onClick={() => {
                          handleClickOpen(details.financialProjectionsName);
                        }}
                      >
                        View Document
                      </span>
                    </p>
                  </div>
                </div>
              )}

              {details.userDataName && (
                <div className="col-lg-6 col-12">
                  <div className="d-flex">
                    <label className="mr-3">Customer & User Data :</label>
                    <p className="ml-auto">
                      <span
                        className="viewdetails"
                        onClick={() => {
                          handleClickOpen(details.userDataName);
                        }}
                      >
                        View Document
                      </span>
                    </p>
                  </div>
                </div>
              )}

              {details.analysisReportName && (
                <div className="col-lg-6 col-12">
                  <div className="d-flex">
                    <label className="mr-3">Market Analysis / Reports :</label>
                    <p className="ml-auto">
                      <span
                        className="viewdetails"
                        onClick={() => {
                          handleClickOpen(details.analysisReportName);
                        }}
                      >
                        View Document
                      </span>
                    </p>
                  </div>
                </div>
              )}

              {details.supportingDocName && (
                <div className="col-lg-6 col-12">
                  <div className="d-flex">
                    <label className="mr-3"> Supporting documentation :</label>
                    <p className="ml-auto">
                      <span
                        className="viewdetails"
                        onClick={() => {
                          handleClickOpen(details.supportingDocName);
                        }}
                      >
                        View Document
                      </span>
                    </p>
                  </div>
                </div>
              )}
              <p>
                <b className="ml-3">Pitch Deck File :</b>
              </p>
              {details.pitchDeck ? (
                <div className="full-pdf">
                  <Worker workerUrl={workerSrc}>
                    <Viewer
                      fileUrl={pdfUrl}
                      defaultScale={SpecialZoomLevel.PageFit}
                      plugins={[
                        disableScrollPluginInstance,
                        pageNavigationPluginInstance,
                      ]}
                    />
                  </Worker>

                  <div className="pdf-left-arrow">
                    <GoToPreviousPage>
                      {(props) => (
                        <Tooltip
                          position={Position.BottomCenter}
                          target={
                            <MinimalButton onClick={props.onClick}>
                              <Icon size={16}>
                                <path d="M18.4.5,5.825,11.626a.5.5,0,0,0,0,.748L18.4,23.5" />
                              </Icon>
                            </MinimalButton>
                          }
                          content={() => "Previous page"}
                          offset={{ left: 0, top: 8 }}
                        />
                      )}
                    </GoToPreviousPage>
                  </div>
                  <div className="pdf-right-arrow">
                    <GoToNextPage>
                      {(props) => (
                        <Tooltip
                          position={Position.BottomCenter}
                          target={
                            <MinimalButton onClick={props.onClick}>
                              <Icon size={16}>
                                <path d="M5.6.5,18.175,11.626a.5.5,0,0,1,0,.748L5.6,23.5" />
                              </Icon>
                            </MinimalButton>
                          }
                          content={() => "Next page"}
                          offset={{ left: 0, top: 8 }}
                        />
                      )}
                    </GoToNextPage>
                  </div>
                </div>
              ) : (
                `-`
              )}
            </div>
          </div>
        </div>
      </section>
      <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
    </main>
  );
};

export default Documentsupload;
