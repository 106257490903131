import React, { useContext, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DataContext from "../../../context/DataContext";
import TablePagination from "@mui/material/TablePagination";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { styled, alpha } from "@mui/material/styles";
import apiService from "../../../api/apiService";
import downloadExcel from "../../../hooks/downloadExcel";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import notifyService from "../../../api/notifySerivce";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CustomLink from "../../common-page/CustomLink";
import SendEmail from "../startup-page/SendEmail";
import SendEmailTemplate from "./SendEmailTemplate";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const SearchinputDiv = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "beige",
  "&:hover": {
    backgroundColor: "beige",
  },
  color: "black",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "23ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const PreviousTanseed = () => {
  const { open, tableIndexValue, setPageLoading, modalstyle } =
    useContext(DataContext);
  const [cohorts, setCohorts] = useState(false);
  const { control, getValues, reset } = useForm({});
  const [fundVersionName, setFundVersionName] = useState("");
  useDidMountEffect(() => {
    // fundListApi();
    handleSelectFund()
    fetchFundVersions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fundId, setFundId] = useState(0);
  const [fundVersionId, setFundVersionId] = useState(0);
  const [searchInputValue, setSearchInputValue] = useState("");
  useDidMountEffect(() => {
    listApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, fundVersionId, searchInputValue, cohorts]);
  const [fundNameList, setFundNameList] = useState([]);
  const [fundVerstionList, setFundVerstionList] = useState([]);



  const fundListApi = () => {
    apiService("admin/fund/list", "", "get")
      .then((result) => {
        if (result && result.data.fundingPrograms) {
          setFundNameList(result.data.fundingPrograms);
          if (result.data.fundingPrograms.length > 0) {
            const firstFund = result.data.fundingPrograms[0];
            setFundName(firstFund.fund);
            setFundId(firstFund.id);
            fetchFundVersions(firstFund.id);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const fetchFundVersions = (fundId) => {
    apiService(`admin/fundversion/list?id=${8}`, "", "get")
      .then((result) => {
        if (result && result.data.fundingPrograms) {
          setFundVerstionList(result.data.fundingPrograms);
          // debugger
          //           const defaultVersion = result.data.fundingPrograms.find(
          //             (program) => program.version === "6.0"
          //           );

          //           if (defaultVersion) {
          //             setFundVersionName(defaultVersion.version);
          //             setFundVersionId(defaultVersion.id);
          //           } else {
          const firstVersion = result.data.fundingPrograms[0];
          setFundVersionName(firstVersion.version);
          setFundVersionId(firstVersion.id);
          // }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const [fundName, setFundName] = useState("");

  const handleSelectFund = (value) => {
    // setFundName(value);
    // const name = fundNameList.filter((item) => item.fund === value);
    // setFundId(name[0].id);
    apiService(`admin/fundversion/list?id=${8}`, "", "get")
      .then((result) => {
        if (result) {
          if (result.data.fundingPrograms) {
            setFundVerstionList(result.data.fundingPrograms);
          }
        }
      })
      .catch((err) => { });
  };
  const handlefundVerstion = (value) => {
    setFundVersionName(value);
    const fundVer = fundVerstionList.filter((item) => item.version === value);
    setFundVersionId(fundVer[0].id);
  };

  const handleClear = () => {
    reset();
    setCohorts(false);
    setSearchName("");
    setSearchInputValue("");
    setCount(0);
    setPage(0);
    setRowsPerPage(10);
    // const defaultFund = fundNameList[0] || {};
    // setFundName(defaultFund.fund || "");
    // setFundId(defaultFund.id || 0);

    // const defaultVersion =
    //   fundVerstionList.find((item) => item.version === "6.0") ||
    //   fundVerstionList[0];
    setFundVersionName(fundVerstionList ? fundVerstionList[0].version : "");
    setFundVersionId(fundVerstionList ? fundVerstionList[0].id : 0);
    debugger
  };
  const [listData, setListData] = useState([]);
  const listApi = () => {
    setListData([]);
    setPageLoading(true);
    var req = getValues();
    req.fundId = 8;
    req.searchString = searchInputValue;
    req.fundVersionId = fundVersionId;
    req.listSize = rowsPerPage;
    req.pageNumber = page + 1;
    req.cohorts = cohorts;

    apiService("admin/startups/fundrecieved/list", req, "post")
      .then((result) => {
        setPageLoading(false);
        if (result) {
          if (result.data.startups) {
            setListData(result.data.startups);
            setCount(result.data.count);
          }
        }
      })
      .catch((err) => { });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [open2, setOpen2] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen2(!open2);
  };
  const [openAllExport, setopenAllExport] = useState(false);
  const handleExportAllClick = () => {
    setopenAllExport(true);
  };
  const handleExportAllClose = () => {
    reset();
    setopenAllExport(false);
  };
  const exportExcelStartup = (download, emails) => {
    setPageLoading(true);
    var req;
    if (download === "All") {
      req = {
        download: download,
        roleId: 4,
        listSize: rowsPerPage,
        pageNumber: page + 1,
        fundId: 8,
        fundVersionId: fundVersionId,
        email: emails,
      };
    } else {
      req = {
        download: download,
        roleId: 4,
        listSize: rowsPerPage,
        pageNumber: page + 1,
        fundId: 8,
        fundVersionId: fundVersionId,
      };
    }

    apiService("admin/startups/fundrecieved/list", req, "downloadpost").then(
      (res) => {
        // setLoading(false);
        setPageLoading(false);
        if (res) {
          if (res.data) {
            if (res.data.byteLength === 184) {
              notifyService("danger", "Startup list is empty", "");
            } else {
              if (download !== "All") {
                downloadExcel(res.data, "startup_");
              } else {
                notifyService(
                  "success",
                  "Success",
                  "An Excel file has been sent to your email address."
                );
                handleExportAllClose();
              }
              // setDownloadValueStartup('')
            }
          }
        }
      }
    );
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const [checkedList, setCheckedList] = useState([]);
  const [itemsChecked, setItemsChecked] = useState(false);
  const [indexValue, setIndexValue] = useState([]);
  const handleCheckboxClick = (e, index) => {
    const { value, checked } = e.target;
    var num = Number(value);
    //
    if (checked) {
      setCheckedList([...checkedList, value * 1]);
      setIndexValue([indexValue, index]);
      sectFgs();
    } else {
      // indexValue
      setIndexValue((indexValue) =>
        indexValue.filter((item) => item !== index)
      );
      setCheckedList((checkedList) =>
        checkedList.filter((item) => item !== num)
      );
      if (checkedList.length === 1) {
        setItemsChecked(false);
        setCheckedList([]);
      }
      // sectFgs()
    }
  };
  const sectFgs = () => {
    console.log(indexValue.length);
    if (listData.length === indexValue.length) {
      setItemsChecked(true);
    } else {
      setItemsChecked(false);
    }
  };
  const selectItem = (e) => {
    const { checked } = e.target;
    const collection = [];
    if (checked) {
      for (const category of listData) {
        collection.push(category.startupId);
      }
    }
    setCheckedList(collection);
    setItemsChecked(checked);
  };
  const resetCheckbox = () => {
    setItemsChecked(false);
    setCheckedList([]);
  };
  const [searchName, setSearchName] = useState("");
  const handleSearch = (searchValue) => {
    if (searchValue.length > 2) {
      setSearchInputValue(searchValue);
      setPage(0);
    } else {
      setSearchInputValue("");
      setPage(0);
    }
    setSearchName(searchValue);
  };
  return (
    <div className="table_tag">
      <div className="table_header_tag set-res-flex align-item-center">
        {checkedList.length > 0 && (
          <SendEmailTemplate
            startupId={checkedList}
            userListItemTanseed={resetCheckbox}
          />
        )}
        <div className="table_header_tag1 set-res-flex">
          <SearchinputDiv
            sx={{ display: { xs: "none", sm: "block" } }}
            onChange={(e) => handleSearch(e.target.value)}
          >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              value={searchName}
              type="search"
              placeholder="Search by Startup Name"
              inputProps={{ "aria-label": "search" }}
            />
          </SearchinputDiv>
        </div>
        <div className="ml-auto">
          <div className="d-flex align-item-center">
            {/* <div className="form-groups">
              <FormControl>
                <Controller
                  name="fundId"
                  control={control}
                  defaultValue="0"
                  rules={{ required: false }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      sx={{ width: "300px" }}
                      disableClearable
                      disablePortal
                      value={fundName}
                      // options={fundNameList}
                      options={fundNameList.map((list) => list.fund)}
                      onChange={(e, selectedOptions) =>
                        handleSelectFund(selectedOptions)
                      }
                      // size="small"
                      renderInput={(params) => (
                        <TextField {...params} label="Fund Name" fullWidth />
                      )}
                    />
                  )}
                />
              </FormControl>
            </div> */}
            {/* {fundName !== "" && ( */}
            <div className="form-groups ml-3">
              <FormControl>
                <Controller
                  name="cohorts"
                  control={control}
                  defaultValue={false}  
                  rules={{ required: false }}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                        {...field}
                          checked={cohorts}
                          onChange={(e) => setCohorts(e.target.checked)}
                          style={{ transform: 'scale(1.0)'}}
                        />
                      }
                    label={<Typography style={{ fontSize: '19px' }}>Cohorts</Typography>} 
                    />
                  )}
                />
              </FormControl>
            </div>


            <div className="form-groups ml-3">
              <FormControl>
                <Controller
                  name="fundVersionId"
                  control={control}
                  defaultValue="0"
                  rules={{ required: false }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      sx={{ width: "300px" }}
                      disableClearable
                      disablePortal
                      size="small"
                      value={fundVersionName}
                      // options={fundVerstionList}
                      options={fundVerstionList.map((list) => list.version)}
                      onChange={(e, selectedOptions) =>
                        handlefundVerstion(selectedOptions)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Fund Version"
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </div>
            {/* )} */}
            {(
              // fundName !== "" || 
              fundVersionName !== "") && (
                <div className="ml-3">
                  <Button
                    variant="contained"
                    sx={{ marginRight: "10px" }}
                    onClick={() => handleClear()}
                    data-toggle="collapse"
                    data-target="#collapseWidthExample"
                  >
                    Clear
                  </Button>
                </div>
              )}
            {listData.length ? (
              <div className="exp-btn ml-3">
                <Button
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant="outlined"
                  disableElevation
                  onClick={handleExportAllClick}
                  startIcon={<CloudDownloadIcon />}                 
                >
                  Export Startups
                </Button>
                {/* <StyledMenu
                  id="demo-customized-menu"
                  anchorEl={anchorEl}
                  open={open2}
                  onClose={handleClose2}
                >
                  <MenuItem disableRipple onClick={handleExportAllClick}>
                    Export All
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      handleClose2();
                      exportExcelStartup("This Page Only", "");
                    }}
                    disableRipple
                  >
                    This Page Only
                  </MenuItem>
                </StyledMenu> */}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div>
        {listData.length ? (
          <TableContainer component={Paper}>
            <Table aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={itemsChecked}
                      onClick={(e) => selectItem(e)}
                    />
                  </TableCell>
                  <TableCell>No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email Id</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Sector</TableCell>
                  <TableCell>City</TableCell>
                  {/* <TableCell>Fund</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {listData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        item={row}
                        value={row.startupId}
                        checked={checkedList.includes(row.startupId)}
                        onChange={(e) => handleCheckboxClick(e, index)}
                      />
                    </TableCell>
                    <TableCell>
                      {tableIndexValue(rowsPerPage, page, index)}
                    </TableCell>
                    <TableCell>
                      <CustomLink
                        to={`/admin/tanseed/details/${btoa(
                          row.startupId
                        )}/${btoa(fundVersionId)}`}
                      >
                        {row.startupName}
                      </CustomLink>

                      {/* {row.startupName} */}
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.sector ? row.sector : `-`}</TableCell>
                    <TableCell>{row.city ? row.city : `-`}</TableCell>
                    {/* <TableCell>{row.fund}</TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
              <caption>
                <TablePagination
                  component="div"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  style={{ float: "left" }}
                />
              </caption>
            </Table>
          </TableContainer>
        ) : (
          <div className="nodata_tag">
            <img
              src="/images/Tanfundlogo-black-1.png"
              className="no_data_logo"
              alt=""
            />
            <h4>TANSEED list is empty</h4>
          </div>
        )}
      </div>
      <Modal open={openAllExport}>
        <Box sx={modalstyle} className="form_tag">
          <SendEmail
            handleExportAllClose={handleExportAllClose}
            exportExcelStartup={exportExcelStartup}
          />
          {/* <Bulkupload handleExportAllClose={handleExportAllClose} title={'Investor'} api={'investor'} roleid={5} /> */}
        </Box>
      </Modal>
    </div>
  );
};

export default PreviousTanseed;
