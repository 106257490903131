import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Appicationview from "./Appicationview";
import Fundingfinancialsview from "./Fundingfinancialsview";
import Teaminfoview from "./Teaminfoview";
import Productmarketfit from "./Productmarketfit";
import Businessstrategy from "./Businessstrategy";
import Tractionachievements from "./Tractionachievements";
import Fundingutilisation from "./Fundingutilisation";
import Documentsupload from "./Documentsupload";
import Moreinfosave from "./Moreinfosave";
import Incubationaccelarationview from "./Incubationaccelarationview";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const Associatedtab = ({ startupGetData, startupValue, idNumber, name }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue1("");
    setValue(newValue);
  };
  const [value1, setValue1] = useState();

  const handleChange1 = (event, newValue) => {
    setValue("");
    setValue1(newValue);
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <div className="overallTab">
        <Box
        // sx={{
        //     flexGrow: 1, bgcolor: 'background.paper', display: {
        //             xs: 'block',
        //             md: 'flex',
        //             lg: 'flex',
        //         },
        //     }
        // }
        >
          <Tabs
            className="verticalTabCSS"
            orientation="horizontal"
            // orientation={isSmallScreen ? 'horizontal' : 'vertical'}
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tab
              className="verticalLable"
              label="Application Overview"
              {...a11yProps(0)}
            />
            <Tab
              className="verticalLable"
              label="Funding & Financials"
              {...a11yProps(1)}
            />
            <Tab
              className="verticalLable"
              label="Team Info"
              {...a11yProps(2)}
            />
            <Tab
              className="verticalLable"
              label="Incubation / Acceleration"
              {...a11yProps(3)}
            />
            <Tab
              className="verticalLable"
              label="Product and Market Fit"
              {...a11yProps(4)}
            />
            {/* <Tab className='verticalLable' label="Business Strategy" {...a11yProps(5)} />
                        <Tab className='verticalLable' label="Traction / Achievements" {...a11yProps(6)} />
                        <Tab className='verticalLable' label="Funding Utilisation" {...a11yProps(7)} />
                        <Tab className='verticalLable' label="Documents Upload" {...a11yProps(8)} />
                        {
                            name !== 'jury' && (
                                <Tab className='verticalLable' label="Request More Info" {...a11yProps(9)} />
                            )
                        } */}
          </Tabs>
          <Tabs
            className="verticalTabCSS"
            orientation="horizontal"
            // orientation={isSmallScreen ? 'horizontal' : 'vertical'}
            variant="scrollable"
            value={value1}
            onChange={handleChange1}
            aria-label="Vertical tabs example"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tab
              className="verticalLable"
              label="Business Strategy"
              {...a11yProps(0)}
            />
            <Tab
              className="verticalLable"
              label="Traction / Achievements"
              {...a11yProps(1)}
            />
            <Tab
              className="verticalLable"
              label="Funding Utilization"
              {...a11yProps(2)}
            />
            <Tab
              className="verticalLable"
              label="Documents Upload"
              {...a11yProps(3)}
            />
            {name !== "jury" && name !== "tanseedadmin" && name !== "partner" && (
              <Tab
                className="verticalLable"
                label="Request More Info"
                {...a11yProps(4)}
              />
            )}
          </Tabs>
          <TabPanel value={value} index={0}>
            <Appicationview details={startupGetData} detail={startupValue} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Fundingfinancialsview
              details={startupGetData}
              detail={startupValue}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Teaminfoview details={startupGetData} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Incubationaccelarationview details={startupGetData} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Productmarketfit details={startupGetData} />
          </TabPanel>
          <TabPanel value={value1} index={0}>
            <Businessstrategy details={startupGetData} />
          </TabPanel>
          <TabPanel value={value1} index={1}>
            <Tractionachievements details={startupGetData} />
          </TabPanel>
          <TabPanel value={value1} index={2}>
            <Fundingutilisation details={startupGetData} />
          </TabPanel>
          <TabPanel value={value1} index={3}>
            <Documentsupload details={startupGetData} />
          </TabPanel>
          {name !== "jury" && name !== "tanseedadmin" && name !== "partner" && (
            <TabPanel value={value1} index={4}>
              <Moreinfosave idNumber={idNumber} />
            </TabPanel>
          )}
          {/* {
                                (path === 'partner' || path === 'investor') && (
                                    <>
                                        <TabPanel value={value} index={2}>
                                            <Reviewrounds roundListData={roundListData} idNumber={idNumber} />
                                        </TabPanel>
                                        <TabPanel value={value} index={4}>
                                            <Moreinfo idNumber={idNumber} viewFile={viewFile} additionalInfo={additionalInfo} />
                                        </TabPanel>
                                    </>
                                )
                            } */}
          {/* {
                                (path === 'jury') && (
                                    <>
                                        <TabPanel value={value} index={2}>
                                            <Reviewrounds roundListData={roundListData} path={path} idNumber={idNumber} />
                                        </TabPanel>
                                        <TabPanel value={value} index={3}>
                                            <Reviewquestion questionListData={questionListData} />
                                        </TabPanel>
                                        <TabPanel value={value} index={4}>
                                            <Moreinfo idNumber={idNumber} viewFile={viewFile} additionalInfo={additionalInfo} />
                                        </TabPanel>
                                    </>
                                )
                            } */}
        </Box>
      </div>
    </div>
  );
};

export default Associatedtab;
