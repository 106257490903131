import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  FormHelperText,
  Tab,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DataContext from "../../context/DataContext";
import { Link, useParams } from "react-router-dom";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import apiService from "../../api/apiService";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Scsttab from "./Scsttab";
import { Stack } from "@mui/material";
// import Validatetab from './Validatetab';
import Scoremodal from "./Scoremodal";
// import Startuplistfilter from './Startuplistfilter';
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Startuplistfilter from "../investor-module/associated-startups-page/associated-details-page/Startuplistfilter";
import { AiFillSafetyCertificate } from "react-icons/ai";
import Viewimage from "../../api/Viewimage";
import CommonViewPitchDeck from "../common-page/CommonViewPitchDeck";
import BDDSubmitReport from "./BDDSubmitReport";
import ViewAllReports from "./view-reports/ViewAllReports";
import notifyService from "../../api/notifySerivce";
import IcPscApprovel from "./ic-psc-approvel/IcPscApprovel";
import { Controller, useForm } from "react-hook-form";
import Scsthubavpremarks from "../scsthubavp-module/scsthubavp-details/Scsthubavpremarks";
import CommentsModal from "./CommentsModal";
const ScstDetails = ({ path }) => {

  const [formData, setFormData] = useState({});
  const { handleSubmit, getValues, control, clearErrors, setValue,reset, formState: { errors } } = useForm(
    { values: formData }
  );
  const roles = localStorage.getItem("role");
  const mentorNav = localStorage.getItem('mentorNav')
  const { navigator, setPageLoading, getSCSTStartupStatus } =
    useContext(DataContext);
  const handleBack = () => {
    if (path === "validation") {
      navigator(`/scst/startups/validation`);
    }
    else if (roles === 'MENTORSHIP') {
      if (mentorNav === 'current') {
        navigator(`/scstmentorship/startups/list/current`);
      }
      else {
        navigator(`/scstmentorship/startups/list/previous`);
      }
    }
    else {
      navigator(`/${path}/startups/list`);
    }
  };
  const [startupGetData, setStartupGetData] = useState({});
  const { id } = useParams();
  const { startupId } = useParams();
  let idNumber = atob(startupId);
  let partnerOrInvestorId = atob(id);
  const [startupCheckList, setStartupCheckList] = useState([]);
  useDidMountEffect(() => {
    // eslint-disable-next-line
    viewStartupDetails(idNumber, partnerOrInvestorId);
    listApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useDidMountEffect(() => {
    showStartupValue();
  }, []);
  const [meetingIndex, setMeetingIndex] = useState(1);
  const [startupValue, setStartupValue] = useState({});
  const viewStartupDetails = async () => {
    setPageLoading(true);
    setNavTabValue("1");
    setStartupGetData({});
    setStartupValue({});
    apiService(`partner/startups/get?id=${idNumber}`, "", "get").then((res) => {
      if (res) {
        if (res.data) {
          if (res.data.applicationForm) {
            setStartupGetData(res.data.applicationForm);
          }
          setStartupValue(res.data);

          // if (res.data.showRounds === true) {
          //     roundPartnerList()
          // }

          var startupValue = res.data;
          if (startupValue.status >= 21) {
            setMeetingIndex(7);
          } else if (startupValue.status >= 19) {
            setMeetingIndex(5);
          } else if (startupValue.status >= 17) {
            setMeetingIndex(5);
          } else if (startupValue.status >= 16) {
            setMeetingIndex(3);
          } else if (startupValue.status >= 10) {
            setMeetingIndex(5);
          } else if (startupValue.status >= 8) {
            setMeetingIndex(5);
          } else if (startupValue.status >= 5) {
            setMeetingIndex(4);
          } else if (startupValue.status >= 3) {
            setMeetingIndex(3);
          } else if (startupValue.status >= 1) {
            setMeetingIndex(3);
          } else if (startupValue.status < 1) {
            setMeetingIndex(2);
          }
        } else {
          setStartupGetData({});
        }
      }
      setPageLoading(false);
    });
  };

  const [comments, setComments] = useState([]);
  const showStartupValue = async () => {
    // setStartupCheckList([])
    apiService(
      `startup/clarifications/status/get?id=${idNumber}`,
      "",
      "get"
    ).then((res) => {
      setStartupCheckList([]);
      if (res) {
        if (res.data) {
          if (res.data && res.data.reportComments) {
            setComments(res.data.reportComments);
          }
          setStartupCheckList(res.data);
        }
      }
    });
  };
  const [navTabValue, setNavTabValue] = useState("1");
  const handleNavTabChange = (event, newValue) => {
    setNavTabValue(newValue);
  };
  const [openList, setOpenList] = React.useState(false);
  const handleStartupList = () => {
    setOpenList(true);
  };

  const handleClose = (value) => {
    setOpenList(false);
  };

  const isRegistered = startupGetData.dpiitRegistered;

  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    setViewImage(value);
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen(false);
  };
  const [listData, setListData] = useState({});
  const listApi = () => {
    setPageLoading(true);
    var req = {
      partnerOrInvestorId: partnerOrInvestorId,
      startupId: idNumber,
    };
    apiService("partner/scst/admin/startup/get", req, "post")
      .then((result) => {
        if (result) {
          if (result.data.startupRounds) {
            setListData(result.data.startupRounds[1]);
          }
        }
        setPageLoading(false);
      })
      .catch((err) => { });
  };
  const [openReportImage, setOpenReportImage] = useState(false);
  const [viewReportImage, setViewReportImage] = useState("");
  const handleClickOpenImage = (value) => {
    setViewReportImage(value);
    setOpenReportImage(true);
  };
  const handleCloseImage = () => {
    setOpenReportImage(false);
  };
  const onStatusUpdate = (data) => {
    setPageLoading(true);
    var getform = {
      status: data,
      startupId: idNumber,
    };
    apiService("partner/scst/status/save", getform, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService("success", "Success", "Saved successfully !!");
          viewStartupDetails(idNumber, partnerOrInvestorId);
        }
      }
    });
  };
  const [openView, setOpenView] = useState(false);
  const handleViewOpen = () => {
    setOpenView(true);
  };
  const handleViewClose = () => {
    setOpenView(false);
  };
  let roundDet;
  const handledownloadpdf = () => {
    setPageLoading(true);
    if (roles === "SCSTHUB") {
      roundDet = "hub";
    } else if (roles === "BDD") {
      roundDet = "bdd";
    } else if (roles === "LDD") {
      roundDet = "ldd";
    } else if (roles === "FDD") {
      roundDet = "fdd";
    }
    var req = {
      id: idNumber,
      round: roundDet,
    };

    apiService("partner/export/scst/startup/application", req, "post")
      .then((res) => {
        setPageLoading(false);
        if (res.data && res.data.responseStatus === "Success") {
          notifyService("success", "Success", "PDF sent to the email");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleMentorApprove = () => {
    setOpenReport(true);
  }
  const [openReport, setOpenReport] = useState(false);
  const handleReportClose = () => {
    setOpenReport(false);
    clearErrors('remarks')
  };
  const onSubmit = (data) => {
    var req = {
      "startupId": idNumber,
      "status": 1,
      "comments": data.comments

    };
    debugger
    setPageLoading(true)
    apiService('mentorship/scst/status/save', req, 'post').then((res) => {
      setPageLoading(false)
      if (res) {
        if (res.data.responseStatus === "Success") {
          notifyService('success', 'Success', 'Saved successfully !!')
          setOpenReport(false);
          reset()
          listApi()
          showStartupValue()
          viewStartupDetails()
        }
      }
    })
  };
  const [openModal, setOpenModal] = useState(false);
  const handleCommentsopen =()=>{
    setOpenModal(true)
  }
  return (
    <div className="box-show">
      <section className="bg-white">
        <div className="row">
          {/* <div className='col-lg-2 p-0'>
                        <div className='box-line'>
                            <Startuplistfilter path={path} startupName={startupValue.startupName} viewStartupDetails={viewStartupDetails} handleClose={handleClose} />
                        </div>
                    </div> */}
          <div className="col-lg-12 p-0">
            <div className="p-relative">
              <div className="p-3">
                <div className="dflex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div>
                      <Button onClick={handleBack}>
                        <ArrowBackIcon />
                      </Button>
                    </div>
                    <img
                      src={startupGetData.startupLogoName}
                      className="img-fluid user_logos"
                      alt=""
                    />
                    <div className="content">
                      <div className="d-flex align-items-center mb-3">
                        <h3 className="mr-3 mb-0">
                          {startupValue.startupName}
                        </h3>
                        <button
                          className={`dpi-button ${isRegistered ? "register-btn" : "not-register-btn"
                            }`}
                        >
                          {isRegistered && (
                            <AiFillSafetyCertificate className="icon" />
                          )}
                          {isRegistered
                            ? "DPIIT Recognised"
                            : "DPIIT Not Registered"}
                        </button>
                        <button
                          className="viewdetails-surpitch"
                          onClick={() => {
                            handleClickOpen(startupGetData.pitchDeck);
                          }}
                        >
                          View PitchDeck
                        </button>
                      </div>

                      <span className="black-clr-sur">
                        <img src="/images/phone.png" alt="" className="mr-2" />{" "}
                        {startupValue.startupPhone
                          ? startupValue.startupPhone
                          : "-"}
                      </span>
                      <span className="black-clr-sur ml-3">
                        <img src="/images/email.png" alt="" className="mr-2" />{" "}
                        {startupValue.startupEmail
                          ? startupValue.startupEmail
                          : "-"}
                      </span>
                      <span className="black-clr-sur ml-3">
                        Member Since : {startupValue.date}
                      </span>
                      <div className="mt-3">
                        Status :{" "}
                        <span className="status_startup">
                          {getSCSTStartupStatus(startupCheckList.status)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="ml-auto mr-3 text-right" style={{width:'30%'}}>

                    {path === "scstbdd" && (
                      <>
                        {startupCheckList.bddSubmitButton === true && (
                          <BDDSubmitReport
                            idNumber={idNumber}
                            viewStartupDetails={viewStartupDetails}
                            showStartupValue={showStartupValue}
                            lbl="BDD First Connect"
                            status={6}
                          />
                        )}
                        {startupCheckList.bddDeepDiveSubmitButton === true && (
                          <BDDSubmitReport
                            idNumber={idNumber}
                            viewStartupDetails={viewStartupDetails}
                            showStartupValue={showStartupValue}
                            lbl="BDD Deep Dive"
                            status={10}
                          />
                        )}
                      </>
                    )}
                    {path === "scstldd" && (
                      <>
                        {startupCheckList.lddSubmitButton === true && (
                          <BDDSubmitReport
                            idNumber={idNumber}
                            viewStartupDetails={viewStartupDetails}
                            showStartupValue={showStartupValue}
                            lbl="LDD"
                            status={18}
                          />
                        )}
                        {startupCheckList.lddCpSubmitButton === true && (
                          <BDDSubmitReport
                            idNumber={idNumber}
                            viewStartupDetails={viewStartupDetails}
                            showStartupValue={showStartupValue}
                            lbl="CP LDD"
                            status={21}
                          />
                        )}
                        {startupCheckList.lddCsSubmitButton === true && (
                          <BDDSubmitReport
                            idNumber={idNumber}
                            viewStartupDetails={viewStartupDetails}
                            showStartupValue={showStartupValue}
                            lbl="CS LDD"
                            status={27}
                          />
                        )}
                      </>
                    )}
                    {path === "scstfdd" && (
                      <>
                        {startupCheckList.fddSubmitButton === true && (
                          <BDDSubmitReport
                            idNumber={idNumber}
                            viewStartupDetails={viewStartupDetails}
                            showStartupValue={showStartupValue}
                            lbl="FDD"
                            status={18}
                          />
                        )}
                        {startupCheckList.fddCpSubmitButton === true && (
                          <BDDSubmitReport
                            idNumber={idNumber}
                            viewStartupDetails={viewStartupDetails}
                            showStartupValue={showStartupValue}
                            lbl="CP FDD"
                            status={21}
                          />
                        )}
                        {startupCheckList.fddCsSubmitButton === true && (
                          <BDDSubmitReport
                            idNumber={idNumber}
                            viewStartupDetails={viewStartupDetails}
                            showStartupValue={showStartupValue}
                            lbl="CS FDD"
                            status={27}
                          />
                        )}
                      </>
                    )}
                    {roles !== "SCSTHUB" && (
                      <>
                        {startupValue.additionalDocName && (
                          <button
                            className="viewdetails-sur mr-3"
                            onClick={() =>
                              handleClickOpenImage(
                                startupValue.additionalDocName
                              )
                            }
                          >
                            View Report
                          </button>
                        )}
                      </>
                    )}
                    {(roles === "SCSTHUB" ||
                      roles === "BDD" ||
                      roles === "FDD" ||
                      roles === "LDD") && (
                        <div className="">
                          <button
                            className="down-pdf-btn mt-2"
                            onClick={() => handledownloadpdf()}
                          >
                            Export Details to the Mail
                          </button>
                        </div>
                      )}
                    {(roles === "MENTORSHIP" && startupCheckList.status === 4) && (
                      <button
                        className="viewdetails-Approve mr-3 mb-3"
                        onClick={() => handleMentorApprove()
                        }
                      >
                        Approve
                      </button>
                    )}
                    {(roles === "MENTORSHIP" && startupCheckList.status === 0) && (
                      <>
                    <button className="viewdetails-surpitch" onClick={()=>handleCommentsopen()} >View Comment</button>
                      <CommentsModal remarks={startupValue}  style={{ color: "white" }} openModal={openModal} setOpenModal={setOpenModal} />
                      </>
                      )}
                      
                    {/* view reports */}
                    {roles !== "SCSTHUB" && (
                      <>
                        <div className="mt-3">
                          {startupCheckList.bddDeepDiveReportDoc ||
                            startupCheckList.icReportDoc ||
                            startupCheckList.pscReportDoc ||
                            startupCheckList.lddReportDoc ||
                            startupCheckList.fddReportDoc ||
                            startupCheckList.cpLddReportDoc ||
                            startupCheckList.cpFddReportDoc ||
                            startupCheckList.csLddReportDoc ||
                            startupCheckList.csFddReportDoc ? (
                            <ViewAllReports
                              startupCheckList={startupCheckList}
                            />
                          ) : (
                            <>
                              {startupCheckList.bddFirstConnectReportDoc && (
                                <>
                                  <button
                                    className="viewdetails-sur mr-3 mb-3"
                                    onClick={() =>
                                      handleClickOpenImage(
                                        startupCheckList.bddFirstConnectReportDoc
                                      )
                                    }
                                  >
                                    BDD First Connect Report
                                  </button>

                                  {comments?.[0]?.comment && (
                                    <span
                                      className="c-pointer"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="View Comment"
                                      onClick={() => handleViewOpen()}
                                    >
                                      <img
                                        src="/images/comment.png"
                                        alt="Comment Icon"
                                        className="img-fluid"
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                          filter:
                                            "invert(33%) sepia(92%) saturate(1109%) hue-rotate(202deg) brightness(94%) contrast(89%)",
                                        }}
                                      />
                                    </span>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                    {path === "ic" && startupCheckList.status === 11 && (
                      <>
                        <div className="mt-3">
                          <IcPscApprovel
                            className={"viewdetails-Approve mb-2  mr-3"}
                            btnName={"Approve for PSC"}
                            idNumber={idNumber}
                            viewStartupDetails={viewStartupDetails}
                            showStartupValue={showStartupValue}
                            lbl="IC"
                            status={13}
                          />
                          <IcPscApprovel
                            className={"viewdetails-Schemes"}
                            btnName={"Recommended to Other Schemes"}
                            idNumber={idNumber}
                            viewStartupDetails={viewStartupDetails}
                            showStartupValue={showStartupValue}
                            lbl="IC"
                            status={14}
                          />
                          <IcPscApprovel
                            className={
                              "viewdetails-Schemes bg-danger text-light mt-2"
                            }
                            btnName={"IC Rejected"}
                            idNumber={idNumber}
                            viewStartupDetails={viewStartupDetails}
                            showStartupValue={showStartupValue}
                            lbl="IC"
                            status={29}
                          />
                        </div>
                      </>
                    )}
                    {path === "psc" && startupCheckList.status === 13 && (
                      <>
                        <div className="mt-3">
                          <IcPscApprovel
                            className={"viewdetails-Approve mb-2  mr-3"}
                            btnName={"Approve for DD"}
                            idNumber={idNumber}
                            viewStartupDetails={viewStartupDetails}
                            showStartupValue={showStartupValue}
                            lbl="PSC"
                            status={16}
                          />
                          <IcPscApprovel
                            className={"viewdetails-Schemes mb-2"} 
                            btnName={"Recommended to Other Schemes"}
                            idNumber={idNumber}
                            viewStartupDetails={viewStartupDetails}
                            showStartupValue={showStartupValue}
                            lbl="PSC"
                            status={30}
                          />
                          {/* <IcPscApprovel className={'viewdetails-Schemes'} btnName={'Recommended to Other Schemes'} idNumber={idNumber} viewStartupDetails={viewStartupDetails} showStartupValue={showStartupValue} lbl="PSC" status={19} /> */}
                          <IcPscApprovel
                            className={
                              "viewdetails-Schemes bg-danger text-light"
                            }
                            btnName={"PSC Rejected"}
                            idNumber={idNumber}
                            viewStartupDetails={viewStartupDetails}
                            showStartupValue={showStartupValue}
                            lbl="PSC"
                            status={15}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <hr />
                <Scsttab
                  listApi={listApi}
                  meetingIndex={meetingIndex}
                  startupGetData={startupGetData}
                  startupValue={startupValue}
                  idNumber={idNumber}
                  viewStartupDetails={viewStartupDetails}
                  listData={listData}
                  path={path}
                  partnerOrInvestorId={partnerOrInvestorId}
                  startupCheckList={startupCheckList}
                  showStartupValue={showStartupValue}
                />
              </div>

              <Drawer
                anchor="left"
                open={openList}
                onClose={handleClose}
                className="scroe_module"
              >
                <Box sx={{ width: 250 }} role="presentation">
                  <div className="box-line-sm">
                    <Startuplistfilter
                      path={path}
                      startupName={startupValue.startupName}
                      viewStartupDetails={viewStartupDetails}
                      showStartupValue={showStartupValue}
                      handleClose={handleClose}
                    />
                  </div>
                </Box>
              </Drawer>
            </div>
          </div>
        </div>
      </section>
      <CommonViewPitchDeck
        viewImage={viewImage}
        open={open}
        handleClose={handleClose1}
      />
      <Viewimage
        viewImage={viewReportImage}
        open={openReportImage}
        handleClose={handleCloseImage}
      />
      <Dialog
        open={openView}
        maxWidth="sm"
        fullWidth={true}
        onClose={handleViewClose}
      >
        <DialogContent>
          <h1 className="views-scomm">View Comments</h1>

          {/* <p className='mt-4'>{comments}</p> */}
          {comments.map((report, index) => (
            <p key={index} className="mt-4">
              {report.comment}
            </p>
          ))}
        </DialogContent>

      </Dialog>

      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={openReport}
        onClose={handleReportClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle >
          <h4>Remarks</h4>
        </DialogTitle>
        <DialogContent>
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
              <div className='col-lg-12 col-12'>

                <div className="form-group mt-2">
                  <Controller name="comments" control={control} defaultValue="" rules={{ required: "Remarks is required" }}
                    render={({ field }) =>
                      <TextField
                        variant='outlined'
                        multiline
                        label="Enter Remarks *"
                        placeholder='Enter Remarks'
                        fullWidth
                        {...field}
                      />} />
                  <FormHelperText className='text-danger'>{errors.comments && errors.comments.message}</FormHelperText>
                </div>
              </div>
            </div>
            <div className='text-center p-3'>
              <Button className='mr-3' onClick={handleReportClose} variant="outlined">
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ScstDetails;
