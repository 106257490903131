import React, { useContext, useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Autocomplete,
  FormControl,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  InputBase,
} from "@mui/material";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import apiService from "../../../api/apiService";
import { styled, alpha } from "@mui/material/styles";
import DataContext from "../../../context/DataContext";
import SearchIcon from "@mui/icons-material/Search";

const SearchinputDiv = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  color: "black",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "17ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const ApprovalPedningFilter = ({
  setPage,
  districtName,
  setDistrictName,
  scsthub,
  setScsthub,
  setSearchInputValue,
  searchInputValue,
  statusValue,
  setStatusValue,
  lblName,
  path,
  searchName,
  setSearchName
}) => {
  // console.log(phaseValue);
  // console.log(statusValue);
  //   const roles = localStorage.getItem("role");
  const { scstRemoveFiters, setNotificationChange } = useContext(DataContext);
  const [cityList, setCityList] = useState([]);
  const [hubList, setHubList] = useState([]);
  useDidMountEffect(() => {
    getScstHub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputValue]);
  useDidMountEffect(() => {
    getCityApi();
  }, [scsthub]);
  const getCityApi = async () => {
    var role = localStorage.getItem("role");
    let url;
    if (role === "SCSTHUB") {
      url = "admin/district/get";
    } else {
      // url = `admin/dropdownvalues/get?key=district`
      url = `admin/hub/district/get?hub=${scsthub}`;
    }
    await apiService(url, "", "get").then((res) => {
      if (res && res.data)
        if (role === "SCSTHUB") {
          setCityList(res.data);
        } else {
          // var val = res.data.dropDownValues;
          // setCityList(val.districts);
          if (res && res.data && res.data.districts) {
            var dis = res.data.districts;
            dis.unshift("All");
            setCityList(dis);
          }
        }
    });
  };
  const getScstHub = async () => {
    await apiService(`partner/hub/filter`, "", "get")
      .then((res) => {
        if (res.data) {
          var hub = res.data;
          hub.unshift("All");
          setHubList(hub);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const handleSelectCity = (value) => {
    setDistrictName(value);
    localStorage.setItem("districtNameScst", value);
    localStorage.setItem("pageScst", 0);
    setPage(0);
  };
  const handleScstHub = (value) => {
    setScsthub(value);
    localStorage.setItem("hubScst", value);
    localStorage.setItem("pageScst", 0);
    setDistrictName("All");
    localStorage.setItem("districtNameScst", "");
  };
  const handleStatusFiter = (value) => {
    setStatusValue(value);
    localStorage.setItem("statusValueScst", value);
    localStorage.setItem("pageScst", 0);
    setPage(0);
  };

  const handleClear = () => {
    setNotificationChange(false);
    setSearchName('')
    localStorage.removeItem("searchInputValueScst")
    // searchInputValue('')
   
    setDistrictName("All");
    setScsthub("All");
    scstRemoveFiters();
    setStatusValue(100);
    localStorage.removeItem("statusValueScst");
  };

  const handleSearch = (searchValue) => {
    if (searchValue.length > 3) {
      setSearchInputValue(searchValue);
      localStorage.setItem("searchInputValueScst", searchValue);
      localStorage.setItem("pageScst", 0);
      setPage(0);
    } else {
      setSearchInputValue("");
      localStorage.setItem("searchInputValueScst", "");
      localStorage.setItem("pageScst", 0);
      setPage(0);
    }
    setSearchName(searchValue)
  };
  return (
    <div>
      <div className="header_tag">
        <h5 className="mb-0">{lblName}</h5>
        {path !== "jury/scst" && (
          <div className="ml-auto d-flex align-items-center">
            <SearchinputDiv
              sx={{ display: { xs: "none", sm: "block" } }}
              onChange={(e) => handleSearch(e.target.value)}
            >
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                value={searchName}
                placeholder="Search by Name"
                type="search"
                inputProps={{ "aria-label": "Search by Name" }}
              />
            </SearchinputDiv>
            <Button
              className="ml-3"
              variant="outlined"
              type="button"
              data-toggle="collapse"
              data-target="#collapseWidthExample"
            >
              <FilterAltIcon />
              Filters
            </Button>
          </div>
        )}
      </div>
      <div className="d-flex">
        <div className="ml-auto my-2"></div>
      </div>
      {/* filter code */}
      <div className="collapse mt-3 mb-3" id="collapseWidthExample">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-2">
            <div className="form-group">
              <FormControl fullWidth>
                <Autocomplete
                  disableClearable
                  disablePortal
                  value={scsthub}
                  options={hubList}
                  onChange={(e, selectedOptions) =>
                    handleScstHub(selectedOptions)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Hub" fullWidth />
                  )}
                />
              </FormControl>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="form-group">
              <FormControl fullWidth>
                <Autocomplete
                  disableClearable
                  disablePortal
                  value={districtName}
                  options={cityList}
                  onChange={(e, selectedOptions) =>
                    handleSelectCity(selectedOptions)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Startup District" fullWidth />
                  )}
                />
              </FormControl>
            </div>
          </div>
          {path === "scstadmin" && (
            <div className="col-lg-2">
              <div className="form-group">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">
                    Status
                  </InputLabel>
                  <Select
                    onChange={(e) => handleStatusFiter(e.target.value, "click")}
                    value={statusValue}
                  >
                    <MenuItem value={100}>All</MenuItem>
                    <MenuItem value={28}>Evaluation Pending</MenuItem>
                    <MenuItem value={6}>
                      BDD First Connect Report Review Pending
                    </MenuItem>
                    <MenuItem value={10}>
                      BDD Deep Dive Report Review Pending
                    </MenuItem>
                    <MenuItem value={18}>DD Report Review Pending</MenuItem>
                    <MenuItem value={22}>CP Report Review Pending</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
        </div>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-lg-2">
            <Button
              variant="contained"
              sx={{ marginRight: "10px" }}
              onClick={() => handleClear()}
              data-toggle="collapse"
              data-target="#collapseWidthExample"
            >
              Clear
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApprovalPedningFilter;
