import React, { useContext, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  // Container,
  Dialog,
  FormControl,
  FormHelperText,
  TablePagination,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
import apiService from "../../../api/apiService";
// import { LoadingButton } from "@mui/lab";
// import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
// import downloadExcel from "../../../hooks/downloadExcel";
// import { Link } from "react-router-dom";
import DataContext from "../../../context/DataContext";
// import Reviewupdate from '../../partner-module/startup-page/startup-list-page/Reviewupdate';
// import Reviewupdate from './Reviewupdate';
// import AddCommentIcon from "@mui/icons-material/AddComment";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import Associatedwith from "./Associatedwith";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Controller, useForm } from "react-hook-form";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import notifyService from "../../../api/notifySerivce";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

const SearchinputDiv = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "beige",
  "&:hover": {
    backgroundColor: "beige",
  },
  color: "black",
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "23ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

const Tanseedstartuplist = () => {
  const { icon, checkedIcon, tableIndexValue, setPageLoading, searchInputValue, setSearchInputValue } = useContext(DataContext);
  const [checkedList, setCheckedList] = useState([]);
  const [value1, setValue1] = useState("1");
  const [associatedList, setAssociatedList] = useState(false);
  const [startupListData, setstartupListData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { control, setValue, clearErrors, getValues, reset } = useForm({});

  // useEffect(() => { }, [startupListData])

  useDidMountEffect(() => {
    dropdownValueApi();
    setItemsChecked(false);
    // eslint-disable-next-line
  }, []);
  const [searchName, setSearchName] = useState("");
  const handleSearch = (searchValue) => {
    if (searchValue.length > 2) {
      setSearchInputValue(searchValue);
      setPage(0);
    } else {
      setSearchInputValue("");
      setPage(0);
    }
    setSearchName(searchValue);
  };
  useDidMountEffect(() => {
    (async () => await listApi())();
    // eslint-disable-next-line
  }, [page, rowsPerPage, associatedList, searchInputValue]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // listApi();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page number when changing rows per page
    // listApi();
  };

  const listApi = () => {
    setPageLoading(true);
    setstartupListData([]);
    var val = getValues();
    // const startupCategory = getValues("startupCategory");
    var req = {
      listSize: rowsPerPage,
      pageNumber: page + 1,
      searchString: searchInputValue,
      associatedList: associatedList,
      startupCategory: val.startupCategory ? val.startupCategory : "",
      sectors: val.preferredSectors ? val.preferredSectors : [],
    };

    apiService("admin/tanseed/startups/list", req, "post").then((res) => {
      if (res) {
        setPageLoading(false);
        setCount(res.data.count);
        if (res.data.startups) {
          setstartupListData(res.data.startups);
          debugger;
        }
      }
    });
  };
  const [openView, setOpenView] = useState(false);
  const handleViewOpen = () => {
    setOpenView(true);
  };
  const handleViewClose = () => {
    setOpenView(false);
    listApi();
    setItemsChecked(false);
    setCheckedList([]);
  };
  const [itemsChecked, setItemsChecked] = useState(false);
  const [indexValue, setIndexValue] = useState([]);
  const handleCheckboxClick = (e, index) => {
    const { value, checked } = e.target;
    if (checked) {
      setCheckedList((prev) => [...prev, value * 1]);
      setIndexValue((prev) => [...prev, index]);
    } else {
      setCheckedList(checkedList.filter((item) => item !== value * 1));
      setIndexValue(indexValue.filter((item) => item !== index));
    }
  };

  const selectItem = (e) => {
    const { checked } = e.target;
    const collection = checked
      ? startupListData.map((category) => category.id)
      : [];
    debugger
    setCheckedList(collection);
    setItemsChecked(checked);
    setIndexValue(checked ? startupListData.map((_, index) => index) : []);
  };

  const handleChange = (event, newValue) => {
    setValue1(newValue);
    setCheckedList([]);
    setItemsChecked(false);
    setIndexValue([]);

    if (newValue === "2") {
      setAssociatedList(true);
      setSearchInputValue("");
      setSearchName("");
    } else {
      setAssociatedList(false);
      setSearchInputValue("");
      setSearchName("");

    }
  };
  const [sectorData, setSectorData] = useState([]);
  const [startupCategoryData, setStartupCategoryData] = useState([]);

  const dropdownValueApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=startup_min`, "", "get")
      .then((res) => {
        var val = res.data.dropDownValues;
        setSectorData(val.sectors);
        setStartupCategoryData(val.startupCategory);
      })
      .catch((error) => {
        console.error("Error fetching dropdown values:", error);
        // Handle error if needed
      });
  };
  const [preferredSectors, setPreferredSectors] = useState([]);
  const handleSelectSector = (value) => {
    if (value.length === 0) {
      setPreferredSectors([]);
      setValue("preferredSectors", []);
      return;
    } else {
      setPreferredSectors(value);
      setValue("preferredSectors", value);
      clearErrors("preferredSectors");
    }
  };
  const handleSelectStartupCategory = (value) => {
    // setSectorName(value);
    setValue("startupCategory", value);
    clearErrors("startupCategory");
  };
  const handleFilter = () => {

    console.log("filtersetstartupListData");
    // setstartupListData(filteredData);

    setPage(0);
    listApi();
  };
  const handleClear = () => {
    // setValue("preferredSectors", []);
    setSearchName("");
    setSearchInputValue("");
    setPreferredSectors([]); // Reset preferred sectors
    reset(); // Reset the form values
    setPage(0);

    listApi();
  };

  const handleDeAssociate = (list, indexes) => {
    let startupId = [];
    startupId.push(list.startupId);

    var req = {
      associate: false,
      partnerOrInvestorId: list.partnerOrInvestorId,
      startupIds: startupId, // Use the extracted startupIds
    };

    apiService("admin/tanseed/startups/associate", req, "post").then((res) => {
      // setLoading(false);
      if (res) {
        if (res.data.responseStatus === "Success") {
          listApi();
          notifyService("success", "Success", "");
        }
      }
    });
  };

  // const handleSelectAllChange = (event) => {
  //   const { checked } = event.target;
  //   const updatedCheckedList = checked
  //     ? startupListData.map((startup) => startup.startupId)
  //     : [];
  //   setCheckedList(updatedCheckedList);
  //   setItemsChecked(checked);
  // };
  const handleSelectAllChange = (event) => {
    const { checked } = event.target;
    const updatedCheckedList = checked
      ? startupListData.map((startup) => startup.startupId)
      : [];

    setCheckedList(updatedCheckedList);
    setItemsChecked(checked);
    setIndexValue(checked ? startupListData.map((_, index) => index) : []);
  };
  return (
    <section className="">
      <div className="container-fluid">
        <div className="form_div chart">
          <div className="header_tag ">
            <h5>TANSEED Startups</h5>
            <Button
              variant="outlined"
              type="button"
              data-toggle="collapse"
              data-target="#collapseWidthExample"
              className="ml-3 my-2"
            >
              {" "}
              <FilterAltIcon />
              Filters
            </Button>
          </div>
          <div className="collapse" id="collapseWidthExample">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-3 mt-2">
                <SearchinputDiv
                  sx={{ display: { xs: "none", sm: "block" } }}
                  onChange={(e) => handleSearch(e.target.value)}
                >
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    value={searchName}
                    type="search"
                    placeholder="Search by Startup Name"
                    inputProps={{ "aria-label": "search" }}
                  />
                </SearchinputDiv>
              </div>
              <div className="col-lg-3">
                <div className="form-group mt-2">
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="startupCategory"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Category is required" }}
                      render={({ field }) => (
                        <Autocomplete
                          disableClearable
                          disablePortal
                          {...field}
                          size="small"
                          options={startupCategoryData}
                          onChange={(e, selectedOptions) =>
                            handleSelectStartupCategory(selectedOptions)
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="Category *" />
                          )}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group mt-2">
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="preferredSectors"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Sector is required" }}
                      render={({ field }) => (
                        <Autocomplete
                          disableClearable
                          disablePortal
                          multiple
                          size="small"
                          {...field}
                          limitTags={2}
                          value={preferredSectors}
                          options={sectorData}
                          onChange={(e, selectedOptions) =>
                            handleSelectSector(selectedOptions)
                          }
                          renderInput={(params) => (
                            <TextField {...params} label="Sectors *" />
                          )}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option}
                            </li>
                          )}
                        />
                      )}
                    />
                    <FormHelperText className="fnt-sm c-blue">
                      Select one or more
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
            </div>
            <center>
              <Button
                variant="contained"
                sx={{ marginRight: "10px" }}
                onClick={() => handleClear()}
                data-toggle="collapse"
                data-target="#collapseWidthExample"
              >
                Clear
              </Button>
              <Button variant="contained" onClick={handleFilter}>
                Filter
              </Button>
            </center>
          </div>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value1}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Un-Associated"
                    value="1"
                    onClick={() => setAssociatedList(false)}
                  />
                  <Tab
                    label="Associated"
                    value="2"
                    onClick={() => setAssociatedList(true)}
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div className="table_tag">
                  <div className="table_header_tag d-flex">
                    {checkedList.length > 0 && (
                      <Button
                        className=""
                        variant="outlined"
                        onClick={() => handleViewOpen()}
                      >
                        Associate
                      </Button>
                    )}
                  </div>
                  {startupListData.length ? (
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              {/* <Checkbox
                                color="primary"
                                checked={itemsChecked}
                                onClick={selectItem.bind(this)}
                                onChange={handleSelectAllChange}
                              /> */}
                              <Checkbox
                                color="primary"
                                checked={checkedList.length === startupListData.length} 
                                onClick={selectItem.bind(this)}
                                onChange={handleSelectAllChange}
                              />
                            </TableCell>
                            <TableCell align="center">No</TableCell>
                            <TableCell>Startup Name</TableCell>

                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {startupListData.map((list, index) => (
                            <TableRow key={index}>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  item={list}
                                  value={list.startupId}

                                  checked={checkedList.includes(list.startupId)}
                                  onChange={(e) =>
                                    handleCheckboxClick(e, index)
                                  }
                                />
                              </TableCell>
                              <TableCell align="center">
                                {tableIndexValue(rowsPerPage, page, index)}
                              </TableCell>
                              <TableCell>
                                {list.startupName}

                              </TableCell>

                            </TableRow>
                          ))}
                        </TableBody>

                        <caption>
                          <TablePagination
                            component="div"
                            count={count}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            style={{ float: "left" }}
                          />
                        </caption>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div className="nodata_tag">
                      <img
                        src="/images/Tanfundlogo-black-1.png"
                        className="no_data_logo"
                        alt=""
                      />
                      <h4>
                        A Startups will be assigned as soon as the criteria
                        matches
                      </h4>
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div className="table_tag">

                  {startupListData.length ? (
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead>
                          <TableRow>

                            <TableCell align="center">No</TableCell>
                            <TableCell>Startup Name</TableCell>
                            <TableCell align="center">Rank</TableCell>
                            <TableCell align="center">Score</TableCell>
                            <TableCell align="center">Recommended</TableCell>
                            <TableCell align="center">Remarks </TableCell>

                            <TableCell align="center">
                              Associated with
                            </TableCell>
                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {startupListData.map((list, index) => (
                            <TableRow key={index}>

                              <TableCell align="center">
                                {tableIndexValue(rowsPerPage, page, index)}
                              </TableCell>
                              <TableCell>
                                {list.startupName}

                              </TableCell>
                              <TableCell align="center">
                                {list.rank !== 0 ? list.rank : "NA"}
                              </TableCell>
                              <TableCell align="center">
                                {list.score !== 0 ? list.score : "NA"}
                              </TableCell>
                              <TableCell align="center">
                                {list.selected === 1 && (
                                  <span className="yes_span">Yes</span>
                                )}
                                {list.selected === 2 && (
                                  <span className="no_span">No</span>
                                )}
                                {list.selected === 0 && (
                                  <span className="review_span">
                                    Under Review
                                  </span>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {list.selected === 1 &&
                                  (list.remarks ? list.remarks : <>-</>)}
                                {list.selected === 2 &&
                                  (list.remarks ? list.remarks : <>-</>)}
                                {list.selected === 0 &&
                                  (list.remarks ? (
                                    <span className="c-pointer">
                                      {list.remarks}
                                    </span>
                                  ) : (
                                    <span className="c-pointer">-</span>
                                  ))}
                              </TableCell>

                              <TableCell align="center">
                                {list.investorName ? list.investorName : `-`}
                              </TableCell>
                              <TableCell align="center">

                                <Tooltip title="De-Associate">
                                  <IconButton>
                                    <DeleteIcon
                                      color="error"
                                      onClick={() =>
                                        handleDeAssociate(list, index)
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <caption>
                          <TablePagination
                            component="div"
                            count={count}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            style={{ float: "left" }}
                          />
                        </caption>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div className="nodata_tag">
                      <img
                        src="/images/Tanfundlogo-black-1.png"
                        className="no_data_logo"
                        alt=""
                      />
                      <h4>
                        A Startups will be assigned as soon as the criteria
                        matches
                      </h4>
                    </div>
                  )}
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <Dialog open={openView} maxWidth="md">
        <Associatedwith
          handleViewClose={handleViewClose}
          checkedList={checkedList}
        />
      </Dialog>
    </section>
  );
};
export default Tanseedstartuplist;
