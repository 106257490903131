import React, { useContext, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import apiService from "../../../../api/apiService";
import notifyService from "../../../../api/notifySerivce";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import DataContext from "../../../../context/DataContext";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Viewimage from "../../../../api/Viewimage";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import DeletePop from "../../../common-page/DeletePop";
const Tanseed7Productandmarket = () => {
  const [formData, setFormData] = useState({});
  const {
    navigator,
    handleBacktanseed7,
    VisuallyHiddenInput,
    Paper,
    icon,
    checkedIcon,
    isWhitespace,
    setOpenPop,
  } = useContext(DataContext);
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({ values: formData });
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    fields: productsFields,
    append: productsAppend,
    remove: productsRemove,
  } = useFieldArray({
    control,
    name: "products",
  });
  // const [multipleImages, setMultipleImages] = useState([
  //   {
  //     productImageName: "",
  //   },
  // ]);
  useDidMountEffect(() => {
    // eslint-disable-next-line
    (async () => await getApi())();
    dropdownValueApi();
  }, []);
  const [technologiesList, setTechnologiesList] = useState([]);
  const dropdownValueApi = async () => {
    await apiService(`admin/dropdownvalues/get?key=startup`, "", "get").then(
      (res) => {
        if (res?.data?.dropDownValues) {
          var val = res.data.dropDownValues;
          setTechnologiesList(val.technologyStacks);
        }
      }
    );
  };
  const getApi = async () => {
    // const updateName = multipleImages.filter((index) => index !== index);
    // setMultipleImages(updateName);

    await apiService(`startup/tanseed/productandmarket/get`, "", "get").then(
      (res) => {
        if (res?.data) {
          setFormData({});
          let response = res.data;
          if (response.id !== 0) {
            if (response.editForm === false) {
              setReadyOnlyValue(true);
            }
            if (response.products !== undefined) {
              response.products.forEach((element) => {
                if (element.productImageName) {
                  element.productImageUrl = element.productImageName;
                }
              });
              // setMultipleImages(response.products);
            }
            if (response.technologyStack) {
              if (response.technologyStack.length === 1) {
                if (response.technologyStack[0] === "") {
                  response.technologyStack = undefined;
                }
              }
              if (response.technologyStack !== undefined) {
                setTechnologyStackValue(response.technologyStack);
              }
            }
            response.intellectualProps = response.intellectualProps
              ? "true"
              : "false";
            setFormData(response);
            console.log(productsFields);
          } else {
            setFormData(response);
          }
        }
      }
    );
  };
  const addAdvisoriesDetailsBtn = () => {
    productsAppend({
      id: 0,
      name: "",
      link: "",
      productImage: "",
      productImageName: "",
      productRoadMap: "",
    });
  };
  let logoselecetdFile = "";
  const handleProductUpload = (event, index) => {
    debugger;
    if (event !== null) {
      debugger;
      if (event.target === undefined) {
        logoselecetdFile = event;
      } else {
        logoselecetdFile = event.target.files[0];
      }
      if (logoselecetdFile) {
        debugger;
        const fileSizeLimit = 5 * 1024 * 1024; // 5 MB limit
        if (logoselecetdFile.size > fileSizeLimit) {
          // File size exceeds limit, show error message
          notifyService(
            "danger",
            "File Size Exceeded",
            "Please upload a file smaller than 5 MB."
          );
          return; // Exit function
        }
        var reader = new FileReader();
        var imagetype = logoselecetdFile.type;
        var imagedatatype = imagetype.split("/");
        var img_crt_type = imagedatatype[1];
        if (
          img_crt_type === "jpeg" ||
          img_crt_type === "jpg" ||
          img_crt_type === "png"
        ) {
          var fileValue = logoselecetdFile;
          reader.readAsDataURL(logoselecetdFile);
          reader.onload = () => {
            var logourl1 = reader.result;
            var spl = logourl1.split(",");
            var ImageValue = spl[1];
            var img_name = fileValue.name;
            var getdata = getValues();
            getdata.products[index].productImage = ImageValue;
            getdata.products[index].productImageName = img_name;
            getdata.products[index].productImageUrl = logourl1;
            setFormData(getdata);
            setValue(`products.${index}.productImage`, ImageValue);
            setValue(`products.${index}.productImageName`, img_name);
            setValue(`products.${index}.productImageUrl`, logourl1);
            // const newInputValues = [...multipleImages];
            // newInputValues[index] = {
            //   ...newInputValues[index],
            //   productImageName: logourl1,
            // };
            // setMultipleImages(newInputValues);
            // debugger;
          };
        } else {
          notifyService(
            "danger",
            "File Format Invalided",
            "Please check your file format"
          );
        }
      }
    }
  };
  // delete products
  const [deleteIndex, setDaleteIndex] = useState();
  const handleDeleteProducts = (index) => {
    setOpenPop(true);
    setDaleteIndex(index);
  };
  const handleClosePop = () => {
    setOpenPop(false);
    setDaleteIndex();
  };
  const deleteApi = (index) => {
    deleteProducts(index);
  };
  const deleteProducts = (i) => {
    const id = Number(getValues(`products.${i}.id`));
    if (id !== 0) {
      apiService(`startup/tanseed/products/remove?id=${id}`, "", "get").then(
        (res) => {
          if (res?.data?.responseStatus === "Success") {
            indexBasedremoveProducts(i);
            // getApi();
          }
        }
      );
    } else {
      indexBasedremoveProducts(i);
    }
    // const updateName = multipleImages.filter((item, index) => index !== i);
    // setMultipleImages(updateName);
    // debugger;
  };
  const indexBasedremoveProducts = (index) => {
    productsRemove(index);
    var val = getValues();
    setFormData(val);
  };
  const [technologyStackValue, setTechnologyStackValue] = useState([]);
  const handleSelectTechnologyStack = (value) => {
    setTechnologyStackValue(value);
    setValue("technologyStack", value);
    clearErrors("technologyStack");
  };
  const onSubmit = (data) => {
    if (readyOnlyValue === false) {
      setLoading(true);
      var getform = data;
      if (getform.technologyStack === "") {
        getform.technologyStack = [];
      }
      apiService("startup/tanseed/productandmarket/save", getform, "post").then(
        (res) => {
          setLoading(false);
          if (res?.data?.responseStatus === "Success") {
            notifyService("success", "Success", "Saved successfully !!");
            navigator("/startup/tanseed/business-strategy");
          }
        }
      );
    }
  };
  const [open, setOpen] = useState(false);
  const [viewImage, setViewImage] = useState("");
  const handleClickOpen = (value) => {
    debugger;
    setViewImage(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <section className="container-fluid">
        <div className="form_div">
          <div className="d-flex align-items-center">
            <span className="back_span" onClick={handleBacktanseed7}>
              <i className="fas fa-arrow-alt-circle-left"></i>
            </span>{" "}
            <h6 className="ml-2 mb-0">Product and Market Fit</h6>
          </div>
          <hr />
          <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="d-none">
                <div className="form-group">
                  <Controller
                    name="id"
                    control={control}
                    defaultValue="0"
                    render={({ field }) => <TextField {...field} />}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="about"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "About the Startup is required",
                      validate: {
                        noWhitespace: (value) =>
                          !isWhitespace(value) || "Whitespace not allowed",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="About the Startup *"
                        placeholder="Enter About the Startup"
                        fullWidth
                        inputProps={{ readOnly: readyOnlyValue }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.about && errors.about.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group mt-2">
                  <FormLabel>
                    Do you own patents (Intellectual Properties){" "}
                    <span className="text-danger">*</span>
                  </FormLabel>
                  <Controller
                    name="intellectualProps"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Intellectual Properties is required" }}
                    render={({ field }) => (
                      <RadioGroup row name="position" {...field}>
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          disabled={readyOnlyValue}
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.intellectualProps &&
                      errors.intellectualProps.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="technologyStack"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Technology Stack is required" }}
                    render={({ field }) => (
                      <Autocomplete
                        disableClearable
                        disablePortal
                        multiple
                        {...field}
                        limitTags={4}
                        value={technologyStackValue}
                        options={technologiesList}
                        onChange={(e, selectedOptions) =>
                          handleSelectTechnologyStack(selectedOptions)
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Technology Stack *" />
                        )}
                        disabled={readyOnlyValue}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                      />
                    )}
                  />
                  <FormHelperText className="fnt-sm c-blue">
                    Select one or more
                  </FormHelperText>
                  <FormHelperText className="text-danger">
                    {errors.technologyStack && errors.technologyStack.message}
                  </FormHelperText>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="form-group mt-2">
                  <Controller
                    name="marketAnalysis"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Market Analysis is required",
                      validate: {
                        noWhitespace: (value) =>
                          !isWhitespace(value) || "Whitespace not allowed",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        label="Market Analysis *"
                        placeholder="Enter Market Analysis"
                        fullWidth
                        inputProps={{ readOnly: readyOnlyValue }}
                        {...field}
                      />
                    )}
                  />
                  <FormHelperText className="text-danger">
                    {errors.marketAnalysis && errors.marketAnalysis.message}
                  </FormHelperText>
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="div_header">
                  <div className="d-flex">
                    <h6>Products Details</h6>
                    <Button
                      variant="outlined"
                      disabled={readyOnlyValue}
                      className="ml-auto"
                      onClick={() => addAdvisoriesDetailsBtn()}
                    >
                      Add Products Details
                    </Button>
                  </div>
                </div>
                <div>
                  {productsFields.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="caption table">
                        <TableHead>
                          <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Product Name </TableCell>
                            <TableCell>Link </TableCell>
                            <TableCell>Product Road Map </TableCell>
                            <TableCell className="text-center">
                              Upload Image{" "}
                            </TableCell>
                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {productsFields.map((field, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {index + 1}
                                <FormControl sx={{ display: "none" }}>
                                  <Controller
                                    name={`products.${index}.id`}
                                    control={control}
                                    defaultValue={0}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                      <TextField {...field} />
                                    )}
                                  />
                                </FormControl>
                              </TableCell>

                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`products.${index}.name`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: "Name is required",
                                      validate: {
                                        noWhitespace: (value) =>
                                          !isWhitespace(value) ||
                                          "Whitespace not allowed",
                                      },
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Name *"
                                        placeholder="Enter Name"
                                        fullwidth
                                        {...field}
                                        inputProps={{
                                          readOnly: readyOnlyValue,
                                        }}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.products?.[index]?.name &&
                                      errors.products?.[index]?.name.message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`products.${index}.link`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: false,
                                      validate: {
                                        noWhitespace: (value) =>
                                          value === "" ||
                                          !isWhitespace(value) ||
                                          "Whitespace not allowed",
                                      },
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Link"
                                        placeholder="Enter Link"
                                        fullwidth
                                        {...field}
                                        disabled={readyOnlyValue}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.products?.[index]?.link &&
                                      errors.products?.[index]?.link.message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <FormControl sx={{ width: "100%" }}>
                                  <Controller
                                    name={`products.${index}.productRoadMap`}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: false,
                                      validate: {
                                        noWhitespace: (value) =>
                                          value === "" ||
                                          !isWhitespace(value) ||
                                          "Whitespace not allowed",
                                      },
                                    }}
                                    render={({ field }) => (
                                      <TextField
                                        id="outlined-multiline-static"
                                        label="Product Road Map"
                                        placeholder="Enter Product Road Map"
                                        fullwidth
                                        {...field}
                                        disabled={readyOnlyValue}
                                      />
                                    )}
                                  />
                                  <FormHelperText className="text-danger">
                                    {errors.products?.[index]?.productRoadMap &&
                                      errors.products?.[index]?.productRoadMap
                                        .message}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              <TableCell className="text-center">
                                <>
                                  {getValues(
                                    `products.${index}.productImageUrl`
                                  ) ? (
                                    <>
                                      <Button
                                        component="label"
                                        variant="contained"
                                        {...field}
                                        onChange={(e) =>
                                          handleProductUpload(e, index)
                                        }
                                        href="#file-upload"
                                        disabled={readyOnlyValue}
                                      >
                                        <i className="far fa-check-circle mr-2"></i>{" "}
                                        Uploaded
                                        {readyOnlyValue === false ? (
                                          <VisuallyHiddenInput type="file" />
                                        ) : (
                                          <></>
                                        )}
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          handleClickOpen(
                                            getValues(
                                              `products.${index}.productImageUrl`
                                            )
                                          )
                                        }
                                      >
                                        View Image
                                      </Button>
                                      <div>
                                        <span className="fn-12">
                                          Maximum 5 mb allowed doc (png, jpg,
                                          jpeg){" "}
                                          <span className="text-danger">*</span>
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    <FormControl sx={{ width: "100%" }}>
                                      <Controller
                                        name={`products.${index}.productImage`}
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: false }}
                                        render={({ field }) => (
                                          <Button
                                            component="label"
                                            variant="contained"
                                            {...field}
                                            fullWidth
                                            onChange={(e) =>
                                              handleProductUpload(e, index)
                                            }
                                            startIcon={<CloudUploadIcon />}
                                            href="#file-upload"
                                            disabled={readyOnlyValue}
                                          >
                                            Upload Image
                                            {readyOnlyValue === false ? (
                                              <VisuallyHiddenInput type="file" />
                                            ) : (
                                              <></>
                                            )}
                                          </Button>
                                        )}
                                      />
                                      <div>
                                        <span className="fn-12">
                                          Maximum 5 mb allowed doc (png, jpg,
                                          jpeg){" "}
                                          <span className="text-danger">*</span>
                                        </span>
                                      </div>
                                      <FormHelperText className="text-danger">
                                        {errors.products?.[index]
                                          ?.productImage &&
                                          errors.products?.[index]?.productImage
                                            .message}
                                      </FormHelperText>
                                    </FormControl>
                                  )}
                                </>
                              </TableCell>
                              <TableCell align="center">
                                <DeleteIcon
                                  style={{ fill: "#D32F2F", cursor: "pointer" }}
                                  onClick={() => {
                                    readyOnlyValue === false ? (
                                      handleDeleteProducts(index)
                                    ) : (
                                      <></>
                                    );
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <div className="nodata_tag">
                      <p>Products details is empty</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group text-center">
              <Button
                variant="contained"
                onClick={handleBacktanseed7}
                className="btn btn-cancel submit px-3"
              >
                Cancel
              </Button>
              {readyOnlyValue === false ? (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  className="btn btn-primary submit px-3 ml-3"
                >
                  <span>Save & Proceed Next</span>
                </LoadingButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </div>
      </section>
      <Viewimage viewImage={viewImage} open={open} handleClose={handleClose} />
      <DeletePop
        deleteApi={deleteApi}
        deleteIndex={deleteIndex}
        handleClosePop={handleClosePop}
      />
    </>
  );
};

export default Tanseed7Productandmarket;
