import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText } from '@mui/material'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import apiService from '../../../api/apiService';
import DataContext from '../../../context/DataContext';
import notifyService from '../../../api/notifySerivce';

const TanseedSettings = () => {
    const { setPageLoading, } = useContext(DataContext);
    const [formData, setFormData] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [date, setDate] = useState(null)
    const [tanseedbtnenable, setTanseedbtnenable] = useState("")
    const [openTanseed, setOpenTanseed] = useState(false)

    const { handleSubmit, control, setValue, reset, clearErrors, getValues, formState: { errors } } = useForm(
        { values: formData, }
    );
    useDidMountEffect(() => {
        buttonEnableapi();

    }, []);

    const buttonEnableapi = () => {
        setPageLoading(true);
        apiService('partner/tanseed/version/actions', "", "get")
            .then((res) => {
                setPageLoading(false);
                if (res && res.data) {
                    setTanseedbtnenable(res.data)
                }
            }).catch((err) => {
                console.log(err);
            });
    }

    const getApitanseed = () => {
        setPageLoading(true);
        apiService('partner/tanseed/lastregister/date/get', "", "get")
            .then((res) => {
                setPageLoading(false);
                if (res && res.data) {
                    setFormData(res.data)
                    if (res.data.registrationEndDate) {
                        const tomorrow = dayjs(res.data.registrationEndDate, 'DD-MM-YYYY')
                        res.data.registrationEndDate = tomorrow
                        setDate(tomorrow)
                        debugger
                    }
                }
            }).catch((err) => {
                console.log(err);
            });
    }

    const OpennewTanseed = (val) => {
        if (val === 'extended') {
            getApitanseed()
        } else {
            setDate(null);
        }
        setOpenTanseed(true)
    }
    const OpennewTanseed1 = (val) => {
        reset()
        setFormData({})
        setDate(null)
        setOpenTanseed(true)
    }
    const closenewTanseed = () => {
        reset()
        setFormData({})
        setDate(null)
        setOpenTanseed(false)
    }

    const handleDateFormat = (newValue) => {
        setDate(newValue)
        setValue('registrationEndDate', newValue)
        clearErrors('registrationEndDate');
    }
    const updateDate = () => {
        setPageLoading(true);
        const regDate = getValues('registrationEndDate');
        const formattedDate = regDate ? regDate.format('YYYY-MM-DD') : '';
        var req = {
            registrationEndDate: formattedDate + 'T00:00:00.174Z'
        }
        apiService('partner/tanseed/update/lastregisterdate', req, 'post')
            .then((res) => {
                setPageLoading(false);
                if (res && res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Date Updated successfully !!');
                    setOpenTanseed(false);
                    reset();
                    buttonEnableapi();
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };


    const onSubmit = (data) => {
        setPageLoading(true);
        const upcomingVersion = parseFloat(tanseedbtnenable.currentVersion) + 1.0;
        data.upComingVersion = upcomingVersion;

        debugger
        apiService('partner/tanseed/open/version', data, 'post')
            .then((res) => {
                setPageLoading(false);
                if (res && res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'New Tanseed Version Opened Sucessfully !!');
                    setOpenTanseed(false);
                    reset();
                    buttonEnableapi()
                }
            })
            .catch((err) => {
                console.error("API Error: ", err);
            })
    };

    const closecurrentTanseed = () => {
        handleOpenModal()

    }
    const confirmClose = () => {
        setPageLoading(true);
        apiService('partner/tanseed/close/version', {}, 'post')
            .then((res) => {
                setPageLoading(false);
                if (res && res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Tanseed Version closed Successfully !!')
                    buttonEnableapi()
                    handleCloseModal()
                }


            }).catch((err) => {
                console.log(err);
            });
    }
    const closeRegistration = () => {
        setPageLoading(true);
        apiService('partner/tanseed/close/registration', {}, 'post')
            .then((res) => {
                setPageLoading(false);
                if (res && res.data.responseStatus === "Success") {
                    notifyService('success', 'Success', 'Tanseed Registration closed Successfully !!')
                    buttonEnableapi()
                }


            }).catch((err) => {
                console.log(err);
            });
    }

    const handleOpenModal = (id) => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    return (
        <div>
            <div className="table_header_tag set-res-flex">
                <div className="ml-auto">
                    <div className="d-flex align-item-center">

                        <div className="mr-3">
                            <Button variant="contained" className="tanseed-buttons" onClick={() => closecurrentTanseed()} disabled={!tanseedbtnenable.showCloseVersionButton}>
                                Close Tanseed {tanseedbtnenable.currentVersion}
                            </Button>
                        </div>

                        <div className="mr-3">
                            <Button variant="contained" onClick={() => OpennewTanseed1()} className="tanseed-buttons" disabled={!tanseedbtnenable.showOpenVersionButton}>
                                Open Tanseed {parseFloat(tanseedbtnenable.currentVersion) + 1}.0
                            </Button>
                        </div>

                        <>
                            <div className="mr-3">
                                <Button variant="contained" onClick={() => closeRegistration()} className="tanseed-buttons" disabled={!tanseedbtnenable.showRegistrationUpdateButton}>
                                    Close Registration
                                </Button>
                            </div>
                            <div className="mr-3">
                                <Button variant="contained" onClick={() => OpennewTanseed('extended')} className="tanseed-buttons" disabled={!tanseedbtnenable.showRegistrationUpdateButton}>
                                    Update Registration EndDate
                                </Button>
                            </div>
                        </>

                    </div>
                </div>
            </div>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={openTanseed}
                onClose={closenewTanseed}
            >
                <DialogTitle>Open Tanseed&nbsp;{parseFloat(tanseedbtnenable.currentVersion) + 1}.0</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <Controller
                                name="registrationEndDate"
                                control={control}
                                defaultValue={date} 
                                rules={{ required: 'Date is required' }}
                                render={({ field }) =>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']} fullWidth>
                                            <DatePicker
                                                label="Registration End Date"
                                                format="DD-MM-YYYY"
                                                {...field}
                                                disablePast={true}
                                                sx={{ width: "100%" }}
                                                inputFormat="DD-MM-YYYY"
                                                value={date} 
                                                onChange={(newValue) => handleDateFormat(newValue)} 
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                }
                            />
                            <FormHelperText className='text-danger'>{errors.registrationEndDate && errors.registrationEndDate.message}</FormHelperText>
                        </div>
                        <div className="text-center">
                            <Button variant="contained" onClick={closenewTanseed} sx={{ marginRight: '10px', textTransform: 'none' }}>Close</Button>
                            {tanseedbtnenable.showOpenVersionButton === true ? (<Button variant="outlined" type="submit" sx={{ textTransform: 'none' }}>Submit</Button>) : (<Button variant="outlined" onClick={() => updateDate()}>Update</Button>)}
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                maxWidth={"sm"}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <center>
                        <img src="/images/img/attention.gif" alt="" className="alert-image" />
                    </center>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <h4 className="text-center p-3">Are you sure ?</h4>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="d-flex justify-content-center mb-2">
                    <Button
                        className="remove-api-modal-cancel"
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </Button>
                    <Button className="remove-api-modal-confirm" onClick={() => confirmClose()}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default TanseedSettings
