import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import apiService from "../../../api/apiService";
import Additionalinfo from "../additional-info-page/Additionalinfo";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import Dialog from "@mui/material/Dialog";
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
import Startuphistroy from "../additional-info-page/Startuphistroy";
import { Button, Divider, TablePagination } from "@mui/material";
import DataContext from "../../../context/DataContext";
import ScstBddStartups from "../../scstBdd-page/scstBdd-details/ScstBddStartups";
import { TabContext, TabList } from "@mui/lab";
// import { useEffect } from "react";
// import Resubmit from "../../investor-module/associated-startups-page/associated-details-page/pages/Resubmit";
import StartupAdditionalinfo from "./StartupAdditionalinfo";
import ClarificationAll from "../../scstBdd-page/scstBdd-details/ClarificationAll";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Progresslist = ({ name }) => {
  // const userId = localStorage.getItem("userId");
  const { setPageLoading, navigator } = useContext(DataContext);

  useDidMountEffect(() => {
    if (name === "tanseed") {
      startupdetailsGetApi();
      alldetailsGetApi();
    }
  }, [name]);
  const [readyOnlyValue, setReadyOnlyValue] = useState(false);
  const startupdetailsGetApi = () => {
    apiService(`startup/tanseed/startupdetails/get`, "", "get")
      .then((result) => {
        if (result?.data?.editForm) {
          setReadyOnlyValue(result.data.editForm);
        }
      })
      .catch((err) => {});
  };
  const [tanseedApplicationNo, settanseedApplicationNo] = useState(
    localStorage.getItem("tanseedApplicationNo")
  );
  const alldetailsGetApi = () => {
    apiService(`startup/tanseed/alldetails/get`, "", "get").then((res) => {
      if (res?.data) {
        localStorage.setItem("tanseedApplicationNo", res.data.applicationNo);
        settanseedApplicationNo(res.data.applicationNo);
      }
    });
  };
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  useDidMountEffect(() => {
    progressList();
  }, [name, page, rowsPerPage]);
  const [progressData, setProgressData] = useState([]);
  const [progressStatus, setProgressStatus] = useState("");
  const [partnerId, setPartnerId] = useState();
  const [historyNotifyBDD, setHistoryNotifyBDD] = useState(false);
  const [bddDDClarificationRequested, setbddDDClarificationRequested] =
    useState(false);
  const [lddClarificationRequested, setLddClarificationRequested] =
    useState(false);
  const [fddClarificationRequested, setFddClarificationRequested] =
    useState(false);
  const progressList = () => {
    setPageLoading(true);
    var reqData = {
      listSize: rowsPerPage,
      pageNumber: page + 1,
      fund: name,
    };
    apiService(`startup/partners/list`, reqData, "post").then((res) => {
      setPageLoading(false);
      if (res) {
        setCount(res.data.count);
        if (res.data.startupPartner) {
          if (res.data.startupPartner) {
            setProgressData(res.data.startupPartner);
          }
          if (res.data.startupPartner[0]) {
            setProgressStatus(res.data.startupPartner[0].selected);
          }

          setHistoryNotifyBDD(res.data.bddClarificationRequested);
          setbddDDClarificationRequested(res.data.bddDDClarificationRequested);
          setLddClarificationRequested(res.data.lddClarificationRequested);
          setFddClarificationRequested(res.data.fddClarificationRequested);
          // setPartnerId(res.data.startupPartner.partnerorInvestorId)
          setPartnerId(res.data.startupPartner[0].partnerorInvestorId);
          if (res.data.startupPartner[0].partnerorInvestorId) {
            historyList(res.data.startupPartner[0].partnerorInvestorId);
          }
          // console.log(res.data.startupPartner[0].partnerorInvestorId, 'id')
        } else {
          setProgressData([]);
        }
      }
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [statusValue, setStatusValue] = useState(0);
    const [pageHistory, setPageHistory] = useState(0);
    const [rowsPerPageHistory, setRowsPerPageHistory] = useState(10);
  useDidMountEffect(() => {
    if (partnerId) {
      historyList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusValue,pageHistory,rowsPerPageHistory, partnerId]);

  const handleStatus = (value) => {
    setStatusValue(value);
  };

  const [historyListValue, setHistoryListValue] = useState([]);
  const [countHistory, setCountHistory] = useState(0);


  const [historyNotify, setHistoryNotify] = useState(false);
  const historyList = (id) => {
    setHistoryListValue([]);
    setPageLoading(true);
    var req = {
      listSize: rowsPerPageHistory,
      pageNumber: pageHistory + 1,
      userId: partnerId ? partnerId : id,
      status: statusValue,
    };
    apiService("startup/additionalinfo/history", req, "post").then((res) => {
      setPageLoading(false);
      if (res && res.data) {
        setCountHistory(res.data.count);
        setHistoryNotify(res.data.additionalInfoRequested);
        if (res.data.additionalInfo) {
          setHistoryListValue(res.data.additionalInfo);
        }
      }
    });
  };
  const handleHistoryChangePage = (event, newPage) => {
    setPageHistory(newPage);
  };
  const handleChangeHistoryRowsPerPage = (event) => {
    setRowsPerPageHistory(parseInt(event.target.value, 10));
    setPageHistory(0);
  };

  const [addInfoValue, setAddInfoValue] = React.useState("1");
  const handleChangeInfo = (event, newValue) => {
    setAddInfoValue(newValue);
  };

  const [open, setOpen] = React.useState(false);
  const [partnerorInvestorId, setPartnerorInvestorId] = useState();
  const handleClickOpen = (id) => {
    setOpen(true);
    setPartnerorInvestorId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleTanseed7 = () => {
    navigator("/startup/tanseed/dashboard");
  };

  return (
    <>
      {name === "scst" ? (
        <section className="container-fluid">
          <div className="form_div chart">
            <div className="table_tag mb-4">
              {progressData.length ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">No</TableCell>
                        <TableCell align="center">Application No</TableCell>
                        {name === "scst" ? (
                          <>
                            <TableCell align="center">HUB Name</TableCell>
                            {/* <TableCell align='center'>Comment</TableCell> */}
                          </>
                        ) : (
                          <>
                            <TableCell>Investor</TableCell>
                          </>
                        )}
                        <TableCell align="center">Application Status</TableCell>
                        <TableCell align="center">Submitted Date</TableCell>
                        {/* <TableCell align='center'>Additional Info</TableCell>
                                    <TableCell align='center'>Additional Info History</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {progressData.map((list, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {list.applicationNo}
                          </TableCell>
                          {name === "scst" ? (
                            <>
                              <TableCell align="center">
                                {list.partnerName}
                              </TableCell>
                              {/* <TableCell align='center'>
                                              {(list.partnerComments) ? (list.partnerComments) : (`NA`)}
                                            </TableCell> */}
                            </>
                          ) : (
                            <>
                              <TableCell align="center">
                                {list.partnerName}
                              </TableCell>
                            </>
                          )}
                          <TableCell align="center">{list.status}</TableCell>
                          <TableCell align="center">{list.date}</TableCell>
                          {/* <TableCell align='center'>
                                        <p className="nav-link mb-0"><Additionalinfo status={list.additionalInfo} id={list.partnerorInvestorId} listApi={progressList} /></p>
                                      </TableCell> */}
                          {/* <TableCell align='center'>
                                        <span className='reqSpan' onClick={() => handleClickOpen(list.partnerorInvestorId)}>
                                          View
                                        </span>
                                      </TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                    {/* <caption>
                                  <TablePagination
                                    component="div"
                                    count={count}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    style={{ float: 'left' }}
                                  />
                                </caption> */}
                  </Table>
                </TableContainer>
              ) : (
                <div className="nodata_tag">
                  <img
                    src="/images/Tanfundlogo-black-1.png"
                    className="no_data_logo"
                    alt=""
                  />
                  <p>
                    A partner will be assigned as soon as the criteria matches
                  </p>
                </div>
              )}
            </div>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  {progressStatus >= 0 && (
                    <Tab label="Validation & Evaluation" {...a11yProps(0)} />
                  )}
                  {progressStatus >= 3 &&
                    progressStatus !== 4 &&
                    progressStatus !== 28 && (
                      <Tab
                        label={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            BDD First Connect
                            {historyNotifyBDD === true ? (
                              <span className="ml-2">
                                <img
                                  src="/images/giphy.gif"
                                  alt="gif"
                                  className="gif-bell img-fluid"
                                />
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        }
                        {...a11yProps(1)}
                      />
                    )}
                  {progressStatus >= 8 && progressStatus !== 28 && (
                    <Tab
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          BDD Deep Dive
                          {bddDDClarificationRequested === true ? (
                            <span className="ml-2">
                              <img
                                src="/images/giphy.gif"
                                alt="gif"
                                className="gif-bell img-fluid"
                              />
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      }
                      {...a11yProps(2)}
                    />
                  )}
                  {progressStatus >= 16 && progressStatus !== 28 && (
                    <Tab
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          Legal Due Diligence
                          {lddClarificationRequested === true ? (
                            <span className="ml-2">
                              <img
                                src="/images/giphy.gif"
                                alt="gif"
                                className="gif-bell img-fluid"
                              />
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      }
                      {...a11yProps(3)}
                    />
                  )}
                  {progressStatus >= 16 && progressStatus !== 28 && (
                    <Tab
                      label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          Financial Due Diligence
                          {fddClarificationRequested === true ? (
                            <span className="ml-2">
                              <img
                                src="/images/giphy.gif"
                                alt="gif"
                                className="gif-bell img-fluid"
                              />
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      }
                      {...a11yProps(4)}
                    />
                  )}
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Box sx={{ pt: 3 }}>
                  <section className="container-fluid">
                    <div className="">
                      <div>
                        <div className="div_header">
                          <h6 className="mt-1" style={{ color: "#313131" }}>
                            Additional Information History
                          </h6>
                        </div>
                        <TabContext value={addInfoValue}>
                          <Box
                            sx={{ borderBottom: 0, borderColor: "transparent" }}
                          >
                            <TabList
                              onChange={handleChangeInfo}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                label={
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    Pending
                                    {historyNotify === true ? (
                                      <span className="ml-2">
                                        <img
                                          src="/images/giphy.gif"
                                          alt="gif"
                                          className="gif-bell img-fluid"
                                        />
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                }
                                value="1"
                                onClick={() => handleStatus(0)}
                              />
                              <Tab
                                label="Submitted"
                                value="2"
                                onClick={() => handleStatus(1)}
                              />
                              <Tab
                                label="Verified"
                                value="3"
                                onClick={() => handleStatus(2)}
                              />
                            </TabList>
                          </Box>
                          <Divider />
                          <div className="mt-3">
                            {historyListValue.length > 0 &&
                            historyListValue.length ? (
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="caption table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="center">No</TableCell>
                                      <TableCell>Reason</TableCell>
                                      <TableCell align="center">
                                        Comments
                                      </TableCell>

                                      {statusValue === 0 && (
                                        <TableCell align="center">
                                          Requested Time
                                        </TableCell>
                                      )}
                                      {statusValue === 1 && (
                                        <TableCell align="center">
                                          Submitted Time
                                        </TableCell>
                                      )}
                                      {statusValue === 2 && (
                                        <TableCell align="center">
                                          Time
                                        </TableCell>
                                      )}
                                      <TableCell align="center">
                                        Requested By
                                      </TableCell>
                                      <TableCell align="center">
                                        {statusValue === 1
                                          ? `Attached Document`
                                          : `Upload Document`}
                                      </TableCell>

                                      {/* {
                                        statusValue === 1 && (
                                          <TableCell align='center'>Action</TableCell>
                                        )
                                      } */}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {historyListValue.map((list, index) => (
                                      <TableRow key={index}>
                                        <TableCell align="center">
                                          {pageHistory * rowsPerPageHistory + index + 1}
                                        </TableCell>
                                        <TableCell>
                                          {list.reason}{" "}
                                          {list.reasonAll && (
                                            <ClarificationAll
                                              remarks={list.reasonAll}
                                              lblName="Reason"
                                              btnName="Show All Reason"
                                            />
                                          )}
                                        </TableCell>
                                        <TableCell align="center">
                                          {list.remarks ? list.remarks : `-`}
                                        </TableCell>
                                        <TableCell align="center">
                                          {list.time}
                                        </TableCell>
                                        <TableCell align="center">
                                          {list.by}
                                        </TableCell>
                                        <TableCell align="center">
                                          {/* <span className='viewdetails' onClick={() => { handleClickOpen(list.document) }}>View</span> */}
                                          <p className="nav-link mb-0">
                                            <StartupAdditionalinfo
                                              data={list}
                                              statusValue={statusValue}
                                              partnerId={partnerId}
                                              historyList={historyList}
                                            />
                                          </p>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                  <caption>
                                    <TablePagination
                                      component="div"
                                      count={countHistory}
                                      page={pageHistory}
                                      onPageChange={handleHistoryChangePage}
                                      rowsPerPage={rowsPerPageHistory}
                                      onRowsPerPageChange={
                                        handleChangeHistoryRowsPerPage
                                      }
                                      style={{ float: "left" }}
                                    />
                                  </caption>
                                </Table>
                              </TableContainer>
                            ) : (
                              <div className="nodata_tag">
                                <img
                                  src="/images/Tanfundlogo-black-1.png"
                                  className="no_data_logo"
                                  alt=""
                                />
                                <p>List is empty</p>
                              </div>
                            )}
                          </div>
                        </TabContext>
                      </div>
                    </div>
                    <Dialog
                      maxWidth={"lg"}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <Startuphistroy
                        partnerorInvestorId={partnerorInvestorId}
                        handleClose={handleClose}
                      />
                    </Dialog>
                  </section>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <div className="div_header mt-3">
                  <h6 className="mt-1" style={{ color: "#313131" }}>
                    Clarification History
                  </h6>
                </div>
                <ScstBddStartups
                  partnerId={partnerId}
                  name={name}
                  historyNotifyBDD={historyNotifyBDD}
                  progressList={progressList}
                  round="firstconnect"
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <div className="div_header mt-3">
                  <h6 className="mt-1" style={{ color: "#313131" }}>
                    Clarification History
                  </h6>
                </div>
                <ScstBddStartups
                  partnerId={partnerId}
                  name={name}
                  historyNotifyBDD={bddDDClarificationRequested}
                  progressList={progressList}
                  round="deepdive"
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <div className="div_header mt-3">
                  <h6 className="mt-1" style={{ color: "#313131" }}>
                    Clarification History
                  </h6>
                </div>
                <ScstBddStartups
                  partnerId={partnerId}
                  name={name}
                  historyNotifyBDD={lddClarificationRequested}
                  progressList={progressList}
                  round="ldd"
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <div className="div_header mt-3">
                  <h6 className="mt-1" style={{ color: "#313131" }}>
                    Clarification History
                  </h6>
                </div>
                <ScstBddStartups
                  partnerId={partnerId}
                  name={name}
                  historyNotifyBDD={lddClarificationRequested}
                  progressList={progressList}
                  round="fdd"
                />
              </CustomTabPanel>
            </Box>
          </div>
        </section>
      ) : (
        <section className="container-fluid">
          <div className="form_div">
            <div className="table_tag">
              {progressData.length ? (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell>No</TableCell>
                        {name === "scst" ? (
                          <>
                            <TableCell>HUB Name</TableCell>
                            <TableCell align="center">Comment</TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>Investor</TableCell>
                          </>
                        )}
                        <TableCell align="center">Application Status</TableCell>
                        <TableCell align="center">Additional Info</TableCell>
                        <TableCell align="center">
                          Additional Info History
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {progressData.map((list, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          {name === "scst" ? (
                            <>
                              <TableCell>{list.partnerName}</TableCell>
                              <TableCell align="center">
                                {list.partnerComments
                                  ? list.partnerComments
                                  : `-`}
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{list.partnerName}</TableCell>
                            </>
                          )}
                          <TableCell align="center">{list.status}</TableCell>
                          <TableCell align="center">
                            <p className="nav-link mb-0">
                              <Additionalinfo
                                status={list.additionalInfo}
                                id={list.partnerorInvestorId}
                                listApi={progressList}
                              />
                            </p>
                          </TableCell>
                          <TableCell align="center">
                            <span
                              className="reqSpan"
                              onClick={() =>
                                handleClickOpen(list.partnerorInvestorId)
                              }
                            >
                              View
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <caption>
                      <TablePagination
                        component="div"
                        count={count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        style={{ float: "left" }}
                      />
                    </caption>
                  </Table>
                </TableContainer>
              ) : (
                <div className="nodata_tag">
                  <img
                    src="/images/Tanfundlogo-black-1.png"
                    className="no_data_logo"
                    alt=""
                  />

                  {name === "tanseed" ? (
                    <>
                      {readyOnlyValue ? (
                        <div>
                          {/* <p>Your TANSEED 7.0 Application is under process !</p> */}
                          <Button variant="contained" onClick={handleTanseed7}>
                            Go to Application
                          </Button>
                        </div>
                      ) : (
                        <h3>
                          Your TANSEED Application (
                          <b>{tanseedApplicationNo}</b>) is currently under
                          review.
                        </h3>
                      )}
                    </>
                  ) : (
                    <p>
                      A partner will be assigned as soon as the criteria matches
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          <Dialog
            maxWidth={"lg"}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <Startuphistroy
              partnerorInvestorId={partnerorInvestorId}
              handleClose={handleClose}
            />
          </Dialog>
        </section>
      )}
    </>
  );
};

export default Progresslist;
